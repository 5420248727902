import styled from 'styled-components';
import Button from '../../../../../stories/common/Button';

export const ButtonsWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 12px;
    justify-content: center;
    padding: 10px 0;

    @media ${({ theme }) => theme.breakpoints.sm} {
        position: fixed;
        bottom: 16px;
        left: 0;
        padding: 0 16px;
        width: 100%;
    }
`;

export const FormButton = styled(Button)`
    border-radius: 50px;
    padding: 8px 16px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 13px 24px;
        width: 100%;
    }
`;
