import React, { FC } from 'react';
import BaseWidget from 'src/components/Widgets/BaseWidget';
import sizeWidget from 'src/theme/widget';
import HeadDay from 'src/components/Widgets/FeedWidget/components/HeadDay';
import { getFeedList, getIsFeedLoading } from 'src/store/Feed/selectors';
import { useSelector } from 'react-redux';
import { groupFeedsListByDate } from 'src/components/MerchantOrdersList/helpers';
import EventRow from 'src/components/Widgets/FeedWidget/components/EventRow';
import dayjs from 'dayjs';
import SkeletonFeedList from 'src/components/Widgets/FeedWidget/components/SkeletonFeedList';
import { useIntl } from 'react-intl';
import { FeedList, MoreNavLink, Title } from './styles';
import { BUSINESS_OFFICE_PATHS } from '../../../pages/businessOffice/paths';
import { getCurrentCounterpartyId } from '../../../store/Counterparty/selectors';

interface IFeedWidget {
    isShowMoreButton?: boolean;
}

const FeedWidget: FC<IFeedWidget> = ({ isShowMoreButton = false }) => {
    const intl = useIntl();
    const feedList = useSelector(getFeedList);
    const isLoadingFeed = useSelector(getIsFeedLoading);
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const groupedFeeds = groupFeedsListByDate(feedList);
    const toDay = dayjs(new Date()).format('D MMMM YYYY');

    const renderFeedItems = () => {
        if (isLoadingFeed) {
            return <SkeletonFeedList />;
        }
        return groupedFeeds.map((groupedFeed) => (
            <div key={groupedFeed.group}>
                <HeadDay
                    date={
                        groupedFeed.group === toDay
                            ? `${intl.formatMessage({ defaultMessage: 'Сегодня' })}, ${groupedFeed.group}`
                            : groupedFeed.group
                    }
                />
                {groupedFeed.children.map((feed) => (
                    <EventRow key={feed.id} feed={feed} />
                ))}
            </div>
        ));
    };

    return (
        <BaseWidget size={sizeWidget.xl}>
            <Title variant="title">{intl.formatMessage({ defaultMessage: 'Уведомления' })}</Title>
            <FeedList>{renderFeedItems()}</FeedList>
            {isShowMoreButton && (
                <MoreNavLink to={BUSINESS_OFFICE_PATHS.feeds(counterpartyId)}>
                    {intl.formatMessage({ defaultMessage: 'Ещё' })}
                </MoreNavLink>
            )}
        </BaseWidget>
    );
};

export default FeedWidget;
