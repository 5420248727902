import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.label`
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: #fcfcfc;
    border-bottom: 1px solid ${({ theme }) => theme.colors.base(0.1)};
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &:last-child {
        border: none;
    }
`;

export const Head = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export const Subtitle = styled(Typography)`
    padding: 0 28px;
    color: ${({ theme }) => theme.colors.base(0.5)};
`;

export const Date = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.7)};
`;

export const Title = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.7)};
`;

export const TitleWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 12px;
`;
