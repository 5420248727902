import React, { FC, ReactNode, useState } from 'react';
import { Arrow, Typography } from '@invoicebox/ui';
import * as S from './styles';
import Checkbox from '../../../components/Checkbox';
import { THeadLabel, TRow } from '../types';
import { getColSpanFromHeadLabels } from '../getColSpanFromHeadLabels';

export type TProps = {
    row: TRow;
    headLabels: Array<THeadLabel>;
    isShowBorderBottom: boolean;
    children: ReactNode;
    isSelected: boolean;
    onSelectRow: (id: string) => void;
    isCanOpenDescription: boolean;
    isHaveDescriptionColumn: boolean;
};

export const TableRow: FC<TProps> = ({
    isShowBorderBottom,
    children,
    onSelectRow,
    row,
    headLabels,
    isSelected,
    isCanOpenDescription,
    isHaveDescriptionColumn,
}) => {
    const [isShowDescription, setIsShowDescription] = useState(false);

    const handleChecked = () => {
        onSelectRow(row.id);
    };

    return (
        <>
            <S.TableRowWrapper
                $isShowBorder={isShowBorderBottom && !isShowDescription}
                onClick={isCanOpenDescription ? () => setIsShowDescription((prevState) => !prevState) : undefined}
                $isPointer={isCanOpenDescription}
            >
                <S.CheckboxRowWrapper>
                    <Checkbox onChange={handleChecked} checked={isSelected} />
                </S.CheckboxRowWrapper>
                {headLabels.map((headLabel) => {
                    const tdContent = row[headLabel.name];

                    if (typeof tdContent === 'string') {
                        return (
                            <S.RowCell key={headLabel.name} align={headLabel.align || 'left'}>
                                <Typography variant="bodyMRegular">{tdContent}</Typography>
                            </S.RowCell>
                        );
                    }

                    return (
                        <S.RowCell key={headLabel.name} align={headLabel.align || 'left'}>
                            {tdContent}
                        </S.RowCell>
                    );
                })}
                {isHaveDescriptionColumn && (
                    <S.ArrowIconWrapper>
                        {isCanOpenDescription && <Arrow isOpen={isShowDescription} outterSize={18} />}
                    </S.ArrowIconWrapper>
                )}
            </S.TableRowWrapper>

            {isShowDescription && isCanOpenDescription && (
                <S.TableRowWrapper $isShowBorder={isShowBorderBottom && isShowDescription} $isPointer={false}>
                    <S.DescriptionWrapper colSpan={getColSpanFromHeadLabels(headLabels, isHaveDescriptionColumn)}>
                        <S.Description>{children}</S.Description>
                    </S.DescriptionWrapper>
                </S.TableRowWrapper>
            )}
        </>
    );
};
