import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const ProfileTooltipInner = styled.div`
    display: flex;
    padding: 20px 28px;
    flex-direction: column;
`;

const profileMenuItem = css`
    width: 100%;
    min-width: 158px;
    justify-content: space-between;
    ${({ theme }) => theme.mixins.flexCenter};
`;

export const ProfileMenuLink = styled(Link)`
    ${profileMenuItem};
    text-decoration: none;
    margin-bottom: 16px;
`;

export const ProfileMenuButton = styled.button`
    ${profileMenuItem};
`;

export const HeaderMenuItem = styled.div`
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.white()};
    transition: ${({ theme }) => theme.decorations.transition.base};
    cursor: default;

    &:hover {
        color: ${({ theme }) => theme.colors.yellow()};
    }

    & svg {
        transition: ${({ theme }) => theme.decorations.transition.base};
    }
`;
