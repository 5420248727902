import React, { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import colors from 'src/theme/colors';
import Typography from 'src/components/Typography';
import { ReactComponent as CheckIcon } from './assets/check.svg';

export interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    checked: boolean;
    label?: string;
    disabled?: boolean;
}

interface IStyledCheckboxProps {
    checked: boolean;
    disabled: boolean;
}

const Checkbox: FC<ICheckboxProps> = ({ disabled = false, className, checked = false, ...props }) => (
    <CheckboxLabel checked={checked} disabled={disabled} className={className}>
        <CheckboxContainer>
            <HiddenCheckbox type="checkbox" disabled={disabled} checked={checked} {...props} />
            <StyledCheckbox>
                <CheckboxIcon />
            </StyledCheckbox>
        </CheckboxContainer>
        {props.label && <CheckboxLabelText variant="regular14">{props.label}</CheckboxLabelText>}
    </CheckboxLabel>
);

export default React.memo(Checkbox);

const CheckboxContainer = styled.div`
    display: inline-block;
    vertical-align: middle;
`;

const HiddenCheckbox = styled.input`
    display: none;
`;

const CheckboxIcon = styled(CheckIcon)`
    transition: ${({ theme }) => theme.decorations.transition?.base};
`;

const StyledCheckbox = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    width: 16px;
    height: 16px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius?.min}px;
    transition: ${({ theme }) => theme.decorations.transition?.base};
`;

const border = 'border:';
const borderDefault = `${border} 1px solid `;
const styleBackground = 'background-color: ';

const getStylesCheckbox = (disabled: boolean, checked: boolean) => {
    if (disabled) {
        if (checked) {
            return `${styleBackground}${colors.grey(0.3)} ; ${border}:none`;
        }
        return colors.grey(0.3);
    }
    if (checked) {
        return `${`${styleBackground + colors.yellow()};${border}`}none`;
    }
    return borderDefault + colors.base(0.3);
};

const getHoverStylesCheckbox = (disabled: boolean, checked: boolean) => {
    if (disabled) {
        if (checked) {
            return `${styleBackground}${colors.grey(0.3)} ; ${border}:none`;
        }
        return colors.grey(0.3);
    }
    if (checked) {
        return `${styleBackground} ${colors.pressedYellow()}; ${border}:none`;
    }
    return borderDefault + colors.base(0.5);
};

export const CheckboxLabel = styled.label<IStyledCheckboxProps>`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 10px;
    ${({ theme }) => theme.helpers.getTypography('regular14')};
    user-select: none;
    width: fit-content;
    cursor: pointer;

    ${StyledCheckbox} {
        ${({ checked, disabled }) => getStylesCheckbox(disabled, checked)};

        ${CheckboxIcon} {
            opacity: ${(props) => (props.checked ? 1 : 0)};
        }
    }

    &:hover {
        ${StyledCheckbox} {
            ${({ checked, disabled }) => getHoverStylesCheckbox(disabled, checked)};
        }
    }
`;

export const CheckboxLabelText = styled(Typography)``;
