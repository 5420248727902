import { LENGTH_VAT_NUMBER } from '@invoicebox/ui';
import yup from '../../../utils/validations';
import { FORM_FIELD } from './constants';
import { RUSCountryCode } from '../../../store/Country/types';

export const organizationSchema = yup.object().shape({
    [FORM_FIELD.countryId]: yup.string().required(),
    [FORM_FIELD.vatNumber]: yup.string().required().isValidInn(),
    [FORM_FIELD.taxRegistrationReasonCode]: yup
        .string()
        // @ts-ignore https://github.com/jquense/yup/issues/1529 short: old ver yup
        .when([FORM_FIELD.vatNumber, FORM_FIELD.countryId], (vatNumber, countryId, schema) =>
            vatNumber?.length === 10 && countryId === RUSCountryCode
                ? schema.required('Введите КПП организации').isValidKpp()
                : schema.optional(),
        ),
    [FORM_FIELD.legalEntityTypeId]: yup
        .number()
        .when([FORM_FIELD.countryId], (countryId, schema) =>
            countryId === RUSCountryCode ? schema.required() : schema.optional(),
        ),

    [FORM_FIELD.registrationNumber]: yup
        .string()
        // @ts-ignore https://github.com/jquense/yup/issues/1529 short: old ver yup
        .when([FORM_FIELD.vatNumber, FORM_FIELD.countryId], (vatNumber, countryId, schema) =>
            (vatNumber?.length === LENGTH_VAT_NUMBER.RUS_INDIVIDUAL_ENTREPRENEUR ||
                vatNumber?.length === LENGTH_VAT_NUMBER.RUS_LEGAL_ENTREPRENEUR) &&
            countryId === RUSCountryCode
                ? schema.required().isValidRegistrationNumber()
                : schema.optional(),
        ),
    [FORM_FIELD.name]: yup.string().required(),
    [FORM_FIELD.nameI18n]: yup.string().required(),
    [FORM_FIELD.registrationAddress]: yup.string().required(),
});
