import React, { FC, useCallback } from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { Select, TSelectProps } from '@invoicebox/ui';

type TValueAfterSelect = string | number;

export type TProps = Omit<TSelectProps<TValueAfterSelect>, 'value' | 'onChange'> & {
    name: string;
};

export const RHFSelect: FC<TProps> = ({ name, ...other }) => {
    const { control } = useFormContext();

    const render = useCallback(
        ({ field, fieldState: { error } }: { field: ControllerRenderProps; fieldState: ControllerFieldState }) => (
            <Select
                {...other}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value || ''}
                hasError={!!error}
            />
        ),
        [other],
    );

    return <Controller name={name} control={control} render={render} />;
};
