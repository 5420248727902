import styled from 'styled-components';

export const Wrapper = styled.tr`
    padding: 12px 20px;
    background: #f5f6fa;
    width: 100%;
    text-align: left;
    color: ${({ theme }) => theme.colors.base(0.7)};

    th {
        padding: 12px;
    }

    th:first-of-type {
        padding-left: 20px;
    }

    th:last-of-type {
        padding-right: 20px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        th {
            padding: 12px 6px;
        }
    }
`;
