import { RootState } from 'src/store';

export const getAuth = (state: RootState) => state.auth;
export const getUser = (state: RootState) => getAuth(state).user;
export const getIdentifier = (state: RootState) => getAuth(state).identifier;
export const getIsAuthInitialized = (state: RootState) => getAuth(state).initialized;
export const getIsAuthLoading = (state: RootState) => getAuth(state).loading;

export const getUserName = (state: RootState) => {
    const user = getUser(state);
    return user ? `${user.firstName} ${user.lastName}` : user;
};
export const getUserFirstName = (state: RootState) => getUser(state)?.firstName;
export const getUserLastName = (state: RootState) => getUser(state)?.lastName;
export const getUserId = (state: RootState) => getAuth(state).user?.userId || null;
export const getVerification = (state: RootState) => getAuth(state).verification;
export const getIsVerificationExists = (state: RootState) => getVerification(state) !== null;
export const getVerificationCodeValid = (state: RootState) => getVerification(state)?.verificationCodeIsValid;
export const getLeftAttempts = (state: RootState) => getVerification(state)?.leftAttempts;
export const getExpiredAt = (state: RootState) => getVerification(state)?.expiredAt;
