import React, { FC, ReactNode } from 'react';
import { Typography, useTablet } from '@invoicebox/ui';
import * as S from './styles';
import { IntroduceContainer } from '../IntroduceContainer';
import { Steps } from '../Steps';

export type TProps = {
    title: string;
    subtitle: string;
    countSteps: number;
    activeStep: number;
    children: ReactNode;
};

export const IntroduceStepContainer: FC<TProps> = ({ subtitle, title, countSteps, activeStep, children }) => {
    const isTablet = useTablet();

    return (
        <IntroduceContainer>
            <S.Wrapper>
                {isTablet && <Steps count={countSteps} active={activeStep} />}
                <S.MobileChildrenWrapper>
                    <div>
                        <S.Head>
                            <S.HeadRow>
                                <Typography variant="headline4">{title}</Typography>
                                {!isTablet && <Steps count={countSteps} active={activeStep} />}
                            </S.HeadRow>
                            <S.Subtitle variant="bodyMRegular">{subtitle}</S.Subtitle>
                        </S.Head>
                        {children}
                    </div>
                </S.MobileChildrenWrapper>
            </S.Wrapper>
        </IntroduceContainer>
    );
};
