import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@invoicebox/ui';
import * as S from './styles';

export type TProps = {
    number: number;
    isActive: boolean;
};

export const Step: FC<TProps> = ({ number, isActive }) => {
    const intl = useIntl();

    return (
        <S.Wrapper $isActive={isActive}>
            <S.NumberWrapper>
                <Typography variant="smallMedium">{number}</Typography>
            </S.NumberWrapper>
            <S.Label variant="bodyMRegular">
                {intl.formatMessage({
                    id: 'шаг',
                    defaultMessage: 'шаг',
                })}
            </S.Label>
        </S.Wrapper>
    );
};
