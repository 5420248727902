import React, { FC } from 'react';
import Button from 'src/stories/common/Button';
import { ReactComponent as ArrowIcon } from 'src/assets/icons/arrow-right.svg';
import OrderPaymentList from 'src/components/Widgets/OrderPaymentWidget/components/OrderPaymentList';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import { useIntl } from 'react-intl';
import { Container, Heading, WrapTitle, Title, MoreBtn } from './styles';
import { BUSINESS_OFFICE_PATHS } from '../../../pages/businessOffice/paths';

const OrderPaymentWidget: FC = () => {
    const intl = useIntl();
    const selectedCounterpartyId = useSelector(getCurrentCounterpartyId);

    return (
        <Container>
            <Heading>
                <WrapTitle>
                    <Title variant="title">{intl.formatMessage({ defaultMessage: 'Входящие: ожидает оплаты' })}</Title>
                    <Button type="link" icon={<ArrowIcon />} />
                </WrapTitle>
                <MoreBtn to={BUSINESS_OFFICE_PATHS.payment(selectedCounterpartyId)}>
                    {intl.formatMessage({ defaultMessage: 'Все' })}
                </MoreBtn>
            </Heading>
            <OrderPaymentList />
        </Container>
    );
};

export default OrderPaymentWidget;
