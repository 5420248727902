import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const HelperUploadDocument = styled.div`
    margin: 52px 0 0 60px;
    border: 1px solid ${({ theme }) => theme.colors.orange()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    padding: 17px 27px;
    width: 523px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin: 30px 0 0 0;
        width: 100%;
        padding: 20px;
    }
`;

export const HelperTitle = styled(Typography)`
    margin-bottom: 3px;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-bottom: 16px;
    }
`;

export const CopyButton = styled.button``;

export const HelperFooter = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 7px;
`;

export const HelperDescription = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.5)};
`;
