import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APP_VARIANT_ROUTES, AUTH_TYPE_FLOW_VARIABLE, RoutesBusinessOffice } from './constants';
import BusinessOfficeRoutes from './BusinessOfficeRoutes';
import DocsRoutes from './DocsRoutes';
import Loader from '../components/Loader';
import { useReCaptchaTokenContext } from '../context/ReCaptchaTokenContext';
import { getIsAuthInitialized, getIsAuthLoading, getIsVerificationExists } from '../store/Auth/selectors';
import { fetchUserProfile } from '../store/Auth';
import { useAppDispatch } from '../store';
import AuthRoutes from './AuthRoutes';
import { BUSINESS_OFFICE_PATHS } from './businessOffice/paths';

const AppRoutes = () => {
    const dispatch = useAppDispatch();

    const { token, onRefreshReCaptcha } = useReCaptchaTokenContext();
    const isAuthInitialized = useSelector(getIsAuthInitialized);
    const isAuthLoading = useSelector(getIsAuthLoading);
    const isVerificationExist = useSelector(getIsVerificationExists);

    useEffect(() => {
        if (!isAuthInitialized && !isAuthLoading && token) {
            dispatch(fetchUserProfile(token)).finally(() => onRefreshReCaptcha());
        }
    }, [dispatch, isAuthInitialized, isAuthLoading, onRefreshReCaptcha, token]);

    if (!isAuthInitialized || (!isVerificationExist && isAuthLoading)) {
        return <Loader />;
    }

    return (
        <Routes>
            {/* /!* нужны 3 следующих роута чтобы после регистрации привязываться флоу заполнения данных юзера *!/ */}
            <Route path={`${APP_VARIANT_ROUTES.auth}/:${AUTH_TYPE_FLOW_VARIABLE}`} Component={AuthRoutes} />
            <Route
                path={`${APP_VARIANT_ROUTES.dashboard}${RoutesBusinessOffice.introduce}/:${AUTH_TYPE_FLOW_VARIABLE}`}
                Component={BusinessOfficeRoutes}
            />
            <Route
                path={`${APP_VARIANT_ROUTES.dashboard}${RoutesBusinessOffice.introduce}`}
                Component={BusinessOfficeRoutes}
            />

            <Route path={`${APP_VARIANT_ROUTES.auth}/*`} Component={AuthRoutes} />
            <Route path={`${APP_VARIANT_ROUTES.dashboard}/*`} Component={BusinessOfficeRoutes} />
            <Route path={`${APP_VARIANT_ROUTES.docs}/*`} Component={DocsRoutes} />
            <Route path="*" element={<Navigate to={BUSINESS_OFFICE_PATHS.dashboard()} />} />
        </Routes>
    );
};

export default AppRoutes;
