import React from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumb';
import { useSelector } from 'react-redux';
import { getAllCounterparties, getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import { SettingsWrapper, Title } from 'src/pages/businessOffice/Counterparty/styles';
import SettingsWidget from 'src/components/Widgets/SettingsWidget';
import General from 'src/pages/businessOffice/Counterparty/components/General';
import Contacts from 'src/pages/businessOffice/Counterparty/components/Contacts';
import { useIntl } from 'react-intl';
import { BreadcrumbToDashboard } from '../../../components/BreadcrumbToDashboard';
import { BUSINESS_OFFICE_PATHS } from '../paths';

export const FORM_COUNTERPARTY_GENERAL = 'form-counterparty-general';
export const FORM_COUNTERPARTY_CONTACT = 'form-counterparty-contact';

const Counterparty = () => {
    const intl = useIntl();
    const { counterpartyId } = useParams<{ counterpartyId: string }>();

    const currentCounterpartyId = useSelector(getCurrentCounterpartyId);
    const openedCounterparty = useSelector(getAllCounterparties).find(
        (counterparty) => counterparty[COUNTERPARTY_FIELDS.id] === counterpartyId,
    );

    if (!openedCounterparty) {
        return null;
    }

    return (
        <>
            <Breadcrumb>
                <BreadcrumbToDashboard />
                <Breadcrumb.Item to={BUSINESS_OFFICE_PATHS.profile(currentCounterpartyId)}>
                    {intl.formatMessage({ defaultMessage: 'Профиль пользователя' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item to={BUSINESS_OFFICE_PATHS.organizations(currentCounterpartyId)}>
                    {intl.formatMessage({ defaultMessage: 'Организации' })}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{openedCounterparty[COUNTERPARTY_FIELDS.name]}</Breadcrumb.Item>
            </Breadcrumb>
            <Title variant="bigTitle">{openedCounterparty.name}</Title>
            <SettingsWrapper>
                <SettingsWidget
                    title={intl.formatMessage({ defaultMessage: 'Общие сведения' })}
                    formId={FORM_COUNTERPARTY_GENERAL}
                >
                    <General counterparty={openedCounterparty} />
                </SettingsWidget>
                <SettingsWidget
                    title={intl.formatMessage({ defaultMessage: 'Контактная информация' })}
                    formId={FORM_COUNTERPARTY_CONTACT}
                >
                    <Contacts counterparty={openedCounterparty} />
                </SettingsWidget>
            </SettingsWrapper>
        </>
    );
};

export default Counterparty;
