import React, { useEffect } from 'react';
import {
    AboutRefund,
    AdaptiveStatusIconWrapper,
    ButtonOrder,
    DescriptionRefund,
    ReasonRefund,
    ReasonTitle,
    RefundContent,
    RefundDescriptionSkeleton,
    RefundDetailWrapper,
    RefundHeader,
    RefundInformation,
    RefundTitle,
    SkeletonNumberOrder,
    SkeletonStatus,
    Status,
    StatusIconWrapper,
    StatusValue,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/RefundDetail/styles';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import { fetchOrderRefundsList } from 'src/store/OrderRefunds';
import { useSelector } from 'react-redux';
import { getIsOrderRefundsLoading, getOrderRefundById } from 'src/store/OrderRefunds/selectors';
import { getOrderById } from 'src/store/Order/selectors';
import { IRefundOrder } from 'src/store/OrderRefunds/types';
import Composition from 'src/pages/businessOffice/Shop/ShopTabsTables/RefundDetail/components/Composition';
import ClientData from 'src/pages/businessOffice/Shop/ShopTabsTables/RefundDetail/components/ClientData';
import { ReactComponent as IconReturn } from 'src/assets/icons/return.svg';
import { getRefundStatuses } from 'src/store/OrderRefunds/constants';
import useModal from 'src/hooks/useModal';
import ModalOrderDetail from 'src/pages/businessOffice/Shop/ShopTabsTables/RefundDetail/components/ModalOrderDetail';
import colors from 'src/theme/colors';
import { getRefundIconByStatusAndColor } from 'src/utils/helpers';
import { useIntl } from 'react-intl';

const RefundDetail = () => {
    const intl = useIntl();
    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);
    const { refundId } = useParams() as { refundId: string };
    const dispatch = useAppDispatch();

    const refund: IRefundOrder = useSelector(getOrderRefundById(refundId)) as any;
    const order = useSelector(getOrderById(refund?.parentId));

    const refundStatuses = getRefundStatuses(intl);
    const statusValue = refundStatuses.find((refundStatus) => refundStatus.state === refund?.status)?.label;

    const { icon, color } = getRefundIconByStatusAndColor(refund?.status, colors);

    const isLoadingRefund = useSelector(getIsOrderRefundsLoading) || !refund || !order;

    useEffect(() => {
        if (!order || !refund) {
            dispatch(fetchOrderRefundsList({ id: refundId }));
        }
    }, [dispatch, order, refund, refundId]);

    return (
        <RefundDetailWrapper>
            <RefundTitle variant="bigTitle">
                {intl.formatMessage({ defaultMessage: 'Возврат по заказу' })}
                {isLoadingRefund ? (
                    <SkeletonNumberOrder />
                ) : (
                    <ButtonOrder type="button" onClick={openModal}>
                        #{order?.merchantOrderId}
                    </ButtonOrder>
                )}
            </RefundTitle>
            <RefundInformation>
                <RefundHeader>{intl.formatMessage({ defaultMessage: 'Информация' })}</RefundHeader>
                <RefundContent>
                    <AboutRefund>
                        <Composition basketItems={order?.basketItems} refundItems={refund?.basketItems} />
                        <ClientData customer={order?.customer} />
                    </AboutRefund>
                    <DescriptionRefund>
                        <Status>
                            {intl.formatMessage({ defaultMessage: 'Статус' })}
                            {isLoadingRefund ? (
                                <SkeletonStatus />
                            ) : (
                                <StatusValue color={color}>
                                    <StatusIconWrapper>
                                        <IconReturn />
                                        {icon && <AdaptiveStatusIconWrapper>{icon}</AdaptiveStatusIconWrapper>}
                                    </StatusIconWrapper>
                                    {statusValue}
                                </StatusValue>
                            )}
                        </Status>
                        <ReasonRefund>
                            <ReasonTitle variant="subheading">
                                {intl.formatMessage({ defaultMessage: 'Причины возврата' })}
                            </ReasonTitle>
                            {isLoadingRefund ? <RefundDescriptionSkeleton /> : refund?.description}
                        </ReasonRefund>
                    </DescriptionRefund>
                </RefundContent>
            </RefundInformation>
            {order && <ModalOrderDetail order={order} onClose={onCloseModal} isOpen={isOpenModal} />}
        </RefundDetailWrapper>
    );
};

export default RefundDetail;
