import React, { FC } from 'react';
import {
    MobileButton,
    MobileButtonWrapper,
} from 'src/pages/businessOffice/OrderContainer/components/MobileButtonEditOrSave/styles';
import { useIntl } from 'react-intl';
import { useLoadingSubmit } from '@invoicebox/ui';

interface IMobileButtonEditOrSave {
    isEdit: boolean;
    setIsEditForm: (isEditForm: boolean) => void;
    handleSubmit: (
        event?: Partial<Pick<React.SyntheticEvent<Element, Event>, 'preventDefault' | 'stopPropagation'>> | undefined,
    ) => Promise<any | undefined> | undefined;
}

const MobileButtonEditOrSave: FC<IMobileButtonEditOrSave> = ({ handleSubmit, setIsEditForm, isEdit }) => {
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();
    const intl = useIntl();

    const onClickSave = async () => {
        toggleLoadingSubmit();
        await handleSubmit();
        toggleLoadingSubmit();
        setIsEditForm(false);
    };

    const renderButton = () => {
        if (isEdit) {
            return (
                <MobileButton isLoading={isLoadingSubmit} onClick={onClickSave}>
                    {intl.formatMessage({ defaultMessage: 'Сохранить' })}
                </MobileButton>
            );
        }

        return (
            <MobileButton
                onClick={() => {
                    setIsEditForm(true);
                }}
            >
                {intl.formatMessage({ defaultMessage: 'Редактировать' })}
            </MobileButton>
        );
    };

    return <MobileButtonWrapper>{renderButton()}</MobileButtonWrapper>;
};

export default MobileButtonEditOrSave;
