import { Currency } from '../Currency/entity';
import { TLabelColor } from '../../stories/common/LabelMap';

export enum ORDER_CONTAINER_STATUSES {
    pending = 'pending',
    completed = 'completed',
    expired = 'expired',
    canceled = 'canceled',
}

export const STATUS_LABELS_MAP: Record<ORDER_CONTAINER_STATUSES, string> = {
    [ORDER_CONTAINER_STATUSES.pending]: 'Ожидает оплаты',
    [ORDER_CONTAINER_STATUSES.completed]: 'Оплачен',
    [ORDER_CONTAINER_STATUSES.expired]: 'Оплата просрочена',
    [ORDER_CONTAINER_STATUSES.canceled]: 'Отменён',
};

export const STATUS_COLORS_MAP: Record<ORDER_CONTAINER_STATUSES, TLabelColor> = {
    [ORDER_CONTAINER_STATUSES.pending]: 'warning',
    [ORDER_CONTAINER_STATUSES.completed]: 'success',
    [ORDER_CONTAINER_STATUSES.expired]: 'error',
    [ORDER_CONTAINER_STATUSES.canceled]: 'default',
};

export type TOrderContainer = {
    id: string;
    merchantId: string;
    originalCurrencyId: string;
    originalAmount: number;
    originalVatAmount: number;
    expirationDate: string;
    status: ORDER_CONTAINER_STATUSES | string;
    returnUrl?: string;
    successUrl?: string;
    failUrl?: string;
    paymentUrl: string;
    payerUserId?: string;
    payerCounterpartyId?: string;
    merchantOrderIdVisible?: string;
    createdAt: string;
};

export type TState = TOrderContainer & { currency: Currency };
