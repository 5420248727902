import React, { FC, ReactNode } from 'react';
import LoadingLogo from 'src/stories/common/Button/components/LoadingLogo';
import * as S from './styles';
import { TButtonType, TSize, TStyledButtonType } from './styles';

export type TProps = {
    type?: TButtonType;
    disabled?: boolean;
    icon?: ReactNode;
    onClick?: () => void;
    className?: string;
    htmlType?: 'submit' | 'reset' | 'button';
    fullWidth?: boolean;
    href?: string;
    size?: TSize;
    isLoading?: boolean;
    target?: string;
    children?: ReactNode;
};

const Button: FC<TProps> = ({
    children,
    type = 'primary',
    disabled = false,
    icon,
    onClick,
    className,
    fullWidth = false,
    htmlType = 'button',
    href,
    isLoading = false,
    size = 'small',
    target,
}) => {
    const isLink = !!href;

    return (
        <S.ButtonWrapper
            as={isLink ? 'a' : 'button'}
            disabled={disabled}
            $buttonType={getStyledButtonType(type, !!icon)}
            $iconOnly={!children}
            onClick={isLink || isLoading ? undefined : onClick}
            $hasIcon={!!icon}
            type={htmlType}
            $fullWidth={fullWidth}
            className={className}
            href={href}
            $size={size}
            target={isLink ? target : undefined}
        >
            {isLoading && <LoadingLogo isPrimary={type === 'primary'} />}
            <S.ButtonInner $isLoading={isLoading} $iconOnly={!children}>
                {icon && icon}
                {children}
            </S.ButtonInner>
        </S.ButtonWrapper>
    );
};
export default Button;

const getStyledButtonType = (buttonType: TButtonType, hasIcon: boolean): TStyledButtonType =>
    buttonType === 'link' && hasIcon ? 'linkWithIcon' : buttonType;
