import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    display: inline-block;
`;

export const Content = styled.button`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.large}px;
    background: ${({ theme }) => theme.colors.grey100()};
    padding: 3px 6px 3px 12px;
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 4px;
`;

export const CalendarWrapper = styled.div`
    padding: 18px 30px 30px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 15px;
    }
`;

export const ResetButton = styled.span`
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;
