import { transparentize } from 'polished';

export function createPalette<C>(colors: C) {
    const result = {};

    // @ts-ignore
    Object.keys(colors).forEach((color) => {
        Object.defineProperty(result, color, {
            enumerable: true,
            get: () => (alphaChannel?: number) => {
                // @ts-ignore colors[color]
                if (alphaChannel === undefined) return colors[color];

                // @ts-ignore colors[color]
                return transparentize(Math.round((1 - alphaChannel) * 100) / 100, colors[color]);
            },
        });
    });

    return result as { [key in keyof C]: (alphaChannel?: number) => string };
}
