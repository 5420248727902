import { DefaultTheme } from 'styled-components';
import { kebabCase, mapKeys, mapValues } from 'lodash';
import { pipe } from 'src/utils/helpers';
import typography, { TTypography, TTypographyItem } from 'src/theme/typography';

const getFieldBorderColor = (theme: DefaultTheme, hasError: boolean, inFocus: boolean) => {
    if (hasError) return theme.colors.red();
    if (inFocus) return theme.colors.base();
    return theme.colors.grey(0.3);
};

const getFieldLabelColor = (theme: DefaultTheme, inFocus: boolean) =>
    inFocus ? theme.colors.base() : theme.colors.grey();

// getTypography helpers start =========
const typographyPixelKeys = ['fontSize', 'lineHeight'];

const pixelizeValues = (values: TTypographyItem) =>
    mapValues(values, (v, k) => (typographyPixelKeys.includes(k) ? `${v}px` : v?.toString()));

const camelToKebabKeys = (obj: Object) => mapKeys(obj, (v, k) => kebabCase(k));
// getTypography helpers end =========

const getTypography = (typographyType: keyof TTypography) =>
    pipe(typography[typographyType], pixelizeValues, camelToKebabKeys);

export default {
    getFieldBorderColor,
    getFieldLabelColor,
    getTypography,
};
