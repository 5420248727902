import { IntlShape } from 'react-intl';
import { TFlagKey } from '@invoicebox/ui';
import { RUSCountryCode, TKnownCountryCode, TKnownOrUnknownCountryCode, knownCountryCodes } from './types';
import { Country } from './entity';

const DICTIONARY: Record<
    TKnownCountryCode,
    {
        getVatLabel: (intl: IntlShape) => string;
        getVatPlaceholder: (intl: IntlShape) => string;
        flag: TFlagKey;
    }
> = {
    RUS: {
        getVatLabel: (intl: IntlShape) => intl.formatMessage({ id: 'cp6hot', defaultMessage: 'ИНН' }),
        getVatPlaceholder: (intl: IntlShape) =>
            intl.formatMessage({ id: 'ZgsssN', defaultMessage: 'Укажите ИНН организации' }),
        flag: 'RUS',
    },
    BLR: {
        getVatLabel: (intl: IntlShape) => intl.formatMessage({ id: 'KS6AIe', defaultMessage: 'УНП' }),
        getVatPlaceholder: (intl: IntlShape) => intl.formatMessage({ id: 'qUDvQM', defaultMessage: 'Укажите УНП' }),
        flag: 'BLR',
    },
    KAZ: {
        getVatLabel: (intl: IntlShape) => intl.formatMessage({ id: '/w5YyX', defaultMessage: 'ИНН/БИН' }),
        getVatPlaceholder: (intl: IntlShape) => intl.formatMessage({ id: 'ZiqvkW', defaultMessage: 'Укажите ИНН/БИН' }),
        flag: 'KAZ',
    },
    AZE: {
        getVatLabel: (intl: IntlShape) => intl.formatMessage({ id: 'cp6hot', defaultMessage: 'ИНН' }),
        getVatPlaceholder: (intl: IntlShape) => intl.formatMessage({ id: 'qUDvQM', defaultMessage: 'Укажите ИНН' }),
        flag: 'AZE',
    },
    ARM: {
        getVatLabel: (intl: IntlShape) => intl.formatMessage({ id: 'WfVgCm', defaultMessage: 'УНН' }),
        getVatPlaceholder: (intl: IntlShape) => intl.formatMessage({ id: 'wXbcuy', defaultMessage: 'Укажите УНН' }),
        flag: 'ARM',
    },
    KGZ: {
        getVatLabel: (intl: IntlShape) => intl.formatMessage({ id: 'cp6hot', defaultMessage: 'ИНН' }),
        getVatPlaceholder: (intl: IntlShape) => intl.formatMessage({ id: 'qUDvQM', defaultMessage: 'Укажите ИНН' }),
        flag: 'KGZ',
    },
    MDA: {
        getVatLabel: (intl: IntlShape) => intl.formatMessage({ id: 'Dxl3ct', defaultMessage: 'IDNO' }),
        getVatPlaceholder: (intl: IntlShape) => intl.formatMessage({ id: 'Fizm7Y', defaultMessage: 'Укажите IDNO' }),
        flag: 'MDA',
    },
    TJK: {
        getVatLabel: (intl: IntlShape) => intl.formatMessage({ id: 'O7jFXz', defaultMessage: 'ИНН/ЕИН' }),
        getVatPlaceholder: (intl: IntlShape) => intl.formatMessage({ id: 'sl0T2E', defaultMessage: 'Укажите ИНН/ЕИН' }),
        flag: 'TJK',
    },
    UZB: {
        getVatLabel: (intl: IntlShape) => intl.formatMessage({ id: 'cp6hot', defaultMessage: 'ИНН' }),
        getVatPlaceholder: (intl: IntlShape) => intl.formatMessage({ id: 'qUDvQM', defaultMessage: 'Укажите ИНН' }),
        flag: 'UZB',
    },
};

class CountryLogic {
    getVatLabel(countryCode: TKnownOrUnknownCountryCode, intl: IntlShape): string {
        const item = DICTIONARY[countryCode as TKnownCountryCode];
        return item
            ? item.getVatLabel(intl)
            : intl.formatMessage({
                  id: 'cp6hot',
                  defaultMessage: 'ИНН',
              });
    }

    getFlag(countryCode: TKnownOrUnknownCountryCode): TFlagKey | undefined {
        const item = DICTIONARY[countryCode as TKnownCountryCode];
        return item ? item.flag : undefined;
    }

    getVatPlaceholder(countryCode: TKnownOrUnknownCountryCode, intl: IntlShape): string | undefined {
        const item = DICTIONARY[countryCode as TKnownCountryCode];
        return item ? item.getVatPlaceholder(intl) : undefined;
    }

    getKnownCountryCode(countryCode: TKnownOrUnknownCountryCode): TKnownCountryCode {
        const item = knownCountryCodes.find((code) => code === countryCode);
        return item || RUSCountryCode;
    }

    getIsRusCountryCode(countryCode: string) {
        return countryCode === RUSCountryCode;
    }

    getCountryOptions(countryList: Array<Country>, intl: IntlShape) {
        return countryList.map((country) => ({
            value: country.code,
            label: country.name,
            description: country.vatLabel(intl),
            flag: country.flag(),
        }));
    }
}

export const countryLogic = new CountryLogic();
