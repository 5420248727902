import styled from 'styled-components';
import Typography from 'src/components/Typography';

interface IMerchantItemWrapper {
    isactivemerchant: boolean;
}

export const MerchantItemWrapper = styled.button<IMerchantItemWrapper>`
    width: 100%;
    padding: 30px 24px 30px 49px;
    display: grid;
    align-items: center;
    grid-column-gap: 9px;
    grid-template-columns: 18px 1fr 84px;
    ${({ theme }) => theme.mixins.getTypography('regular12')}
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey()};
    text-decoration: none;
    background: ${({ isactivemerchant, theme }) =>
        isactivemerchant ? theme.colors.lightGrey(0.5) : theme.colors.white()};

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding-left: 27px;
    }
`;

export const MerchantName = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Balance = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey()};
    text-align: right;
`;
