import styled from 'styled-components';

export const Label = styled.div<{
    $color: string;
    $backgroundColor: string;
}>`
    display: inline-block;
    padding: 4px 8px;
    color: ${({ $color }) => $color};
    background-color: ${({ $backgroundColor }) => $backgroundColor};
    border-radius: 6px;
`;
