import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import * as S from './styles';
import { DocumentTypeLabel } from '../DocumentTypeLabel';

export type TProps = {
    billId: string;
    documents: Array<{
        isDone: boolean;
        label: string;
        onDownload?: () => void;
    }>;
};

export const BillSummary: FC<TProps> = ({ billId, documents }) => {
    const intl = useIntl();

    return (
        <S.Wrapper>
            <S.Title>
                <Typography variant="smallMedium">
                    {intl.formatMessage({
                        id: 'Счёт №',
                        defaultMessage: 'Счёт №',
                    })}{' '}
                    {billId}
                </Typography>
            </S.Title>
            {!!documents.length && (
                <S.DocumentsList>
                    {documents.map((document) => (
                        <DocumentTypeLabel key={document.label} {...document} />
                    ))}
                </S.DocumentsList>
            )}
        </S.Wrapper>
    );
};
