import { createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { TaskPlace } from './entity';

export const taskPlaceAdapter = createEntityAdapter<TaskPlace>();

const initialState = taskPlaceAdapter.getInitialState({
    isInitialized: false,
    isLoading: false,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

const taskPlaceSlice = createSlice({
    name: 'taskPlace',
    initialState,
    reducers: {
        setTaskPlace(state, { payload }) {
            taskPlaceAdapter.setAll(state as EntityState<TaskPlace>, payload);
        },
    },
});

export const { setTaskPlace } = taskPlaceSlice.actions;

export default taskPlaceSlice.reducer;
