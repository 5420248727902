import styled from 'styled-components';
import qrBgImage from './assets/qrBgImage.png';
import { TAuthType } from '../AuthTypeSwitcher';

export const Wrapper = styled.div`
    display: flex;

    @media ${({ theme }) => theme.breakpoints.md} {
        height: 100%;
    }
`;

const QR_STACK_HEIGHT: Record<TAuthType, number> = {
    phone: 540,
    login: 560,
};

export const QRStackWrapper = styled.div<{ type: TAuthType }>`
    padding: 16px;
    background: url(${qrBgImage});
    width: 350px;
    height: ${({ type }) => QR_STACK_HEIGHT[type]}px;

    @media ${({ theme }) => theme.breakpoints.md} {
        display: none;
    }
`;

export const AuthContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 24px;
    width: 370px;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        padding: 24px 16px;
        align-items: initial;
    }
`;

export const AuthFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;

    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 32px;
        gap: 16px;
    }
`;

export const NoticeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
`;

export const NoticeLink = styled.a`
    color: ${({ theme }) => theme.colors.link()};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;
