import styled, { css } from 'styled-components';
import Typography from 'src/components/Typography';

const StyleDocumentBlock = css`
    ${({ theme }) => theme.mixins.flexStart};
    grid-column-gap: 13px;
`;

export const ButtonDocument = styled.button`
    ${StyleDocumentBlock}
`;

export const DocumentDropZone = styled.div`
    ${StyleDocumentBlock};
    cursor: pointer;
`;

export const Number = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    ${({ theme }) => theme.mixins.getTypography('subheading')}
    background: ${({ theme }) => theme.colors.lightGrey(0.5)};
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 30px;
        height: 30px;
        ${({ theme }) => theme.mixins.getTypography('regular14')}
    }
`;

export const DocumentContent = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    grid-column-gap: 4px;
    padding-top: 4px;
`;

export const IconContainer = styled.div`
    flex-shrink: 0;
    @media ${({ theme }) => theme.breakpoints.md} {
        display: none;
    }
`;

export const DocumentText = styled.div`
    ${({ theme }) => theme.mixins.flexStart}
    flex-direction: column;
    grid-row-gap: 10px;
`;

export const DocumentTitle = styled.div`
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 4px;
    height: 30px;
    text-align: left;
`;

export const DocumentDescription = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.4)};
`;

export const DocumentUpload = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    grid-column-gap: 6px;
`;

export const NameFile = styled(Typography)`
    color: ${({ theme }) => theme.colors.orange()};
    margin-top: -3px;
    margin-bottom: 5px;
`;

export const ButtonRemoveFile = styled.button`
    transform: scale(0.9);
    margin-top: -3px;
`;
