import styled from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    gap: 8px;
    color: ${({ theme }) => theme.colors.grey700()};
    min-height: 24px;
`;

export const Content = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 8px;
`;

export const DownloadButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;
