import React, { FC } from 'react';
import SettingsWidget from 'src/components/Widgets/SettingsWidget';
import {
    ButtonRemove,
    ClientWrapper,
    NewClientTitle,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/DetailClient/styles';
import ContactInformation from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/ContactInformation';
import { useSelector } from 'react-redux';
import { getClientById } from 'src/store/Client/selectors';
import RelatedOrganizations from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/RelatedOrganizations';
import { CLIENT_FIELDS } from 'src/store/Client/contants';
import { ReactComponent as IconDelete } from 'src/assets/icons/delete.svg';
import { useAppDispatch } from 'src/store';
import { removeClient } from 'src/store/Client';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { BUSINESS_OFFICE_PATHS } from '../../../../../paths';
import { getCurrentCounterpartyId } from '../../../../../../../store/Counterparty/selectors';

export const FORM_CREATE_CLIENT = 'form_create_client';

interface IDetailClient {
    clientId?: number;
}

const DetailClient: FC<IDetailClient> = ({ clientId }) => {
    const intl = useIntl();
    const client = useSelector(getClientById(clientId));
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const contactCounterpartiesIds = (client && client[CLIENT_FIELDS.contactCounterpartyIds]) || [];
    const dispatch = useAppDispatch();
    const { merchantId } = useParams() as { merchantId: string };
    const navigate = useNavigate();

    const onClickRemoveClient = async () => {
        const result = await dispatch(removeClient(Number(clientId)));
        // @ts-ignore
        if (!result?.error && result?.payload) {
            navigate(BUSINESS_OFFICE_PATHS.shop(counterpartyId).clients(merchantId));
        }
    };

    return (
        <ClientWrapper>
            <NewClientTitle variant="bigTitle">
                {intl.formatMessage({ defaultMessage: 'Карточка клиента' })}
            </NewClientTitle>
            <SettingsWidget
                isEditDefault={Boolean(!client)}
                title={intl.formatMessage({ defaultMessage: 'Контактная информация' })}
                formId={FORM_CREATE_CLIENT}
            >
                <ContactInformation client={client} />
            </SettingsWidget>
            {client && (
                <>
                    <RelatedOrganizations client={client} contactCounterpartiesIds={contactCounterpartiesIds} />
                    <ButtonRemove onClick={onClickRemoveClient} icon={<IconDelete />} type="secondary">
                        {intl.formatMessage({ defaultMessage: 'Удалить клиента' })}
                    </ButtonRemove>
                </>
            )}
        </ClientWrapper>
    );
};

export default DetailClient;
