import { useEffect, useState } from 'react';
import { organizationLogic } from '../store/CounterpartyDetail/organizationLogic';
import { getCounterpartyDetailListByVatNumberRequest } from '../store/CounterpartyDetail/http';
import { TOrganization } from '../store/CounterpartyDetail/types';

export const useFetchCounterpartyDetailOptions = (vatNumber: string, countryCode: string) => {
    const [counterpartyDetailList, setCounterpartyDetailList] = useState<Array<TOrganization> | []>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (vatNumber && countryCode && organizationLogic.getIsCanFetchOrganization(vatNumber, countryCode)) {
            setIsLoading(true);
            getCounterpartyDetailListByVatNumberRequest(vatNumber, countryCode)
                .then(({ data }) => {
                    setCounterpartyDetailList(data.data);
                })
                .catch(() => setCounterpartyDetailList([]))
                .finally(() => setIsLoading(false));
        } else {
            setCounterpartyDetailList([]);
        }
    }, [countryCode, vatNumber]);

    const options = counterpartyDetailList?.map((counterpartyDetailItem) => ({
        value: counterpartyDetailItem.vatNumber,
        entity: counterpartyDetailItem,
    }));

    return { options, isLoading };
};
