import { useCallback } from 'react';
import useSearchQuery from './useSearchQuery';

export const FIRST_PAGE = 1;
const DEFAULT_SIZE_PAGE = 5;
export const PAGE = 'page';
const PAGE_SIZE = 'pageSize';

export default function usePagination() {
    const { searchParams, setSearchParams } = useSearchQuery();
    const pageSize = searchParams.get(PAGE_SIZE) || DEFAULT_SIZE_PAGE;
    const page = searchParams.get(PAGE) || FIRST_PAGE;

    const handlePageChange = useCallback(
        (newPage: number) => {
            if (page === newPage) {
                return;
            }

            searchParams.set(PAGE, String(newPage));
            setSearchParams(searchParams);
        },
        [page, searchParams, setSearchParams],
    );

    const handlePageSizeChange = useCallback(
        (newPageSize: number) => {
            searchParams.set(PAGE_SIZE, String(newPageSize));
            setSearchParams(searchParams);
        },
        [searchParams, setSearchParams],
    );

    const onPageResetDefault = useCallback(() => {
        handlePageChange(FIRST_PAGE);
        handlePageSizeChange(DEFAULT_SIZE_PAGE);
    }, [handlePageChange, handlePageSizeChange]);

    return {
        page: Number(page),
        pageSize: Number(pageSize),
        onPageChange: handlePageChange,
        onPageSizeChange: handlePageSizeChange,
        onPageResetDefault,
    };
}
