import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { COUNTERPARTY_ID_QUERY_NAME } from '../../constants';
import { DOCS_PATHS } from '../../paths';
import useSearchQuery from '../../../../hooks/useSearchQuery';

export const useQueryCounterpartyId = () => {
    const navigate = useNavigate();

    const { searchParams } = useSearchQuery();
    const counterpartyId = useMemo(() => searchParams.get(COUNTERPARTY_ID_QUERY_NAME), [searchParams]);

    const handleGoToDashboard = useCallback(() => {
        navigate(DOCS_PATHS.dashboard);
    }, [navigate]);

    if (!counterpartyId) {
        // eslint-disable-next-line no-console
        console.error('not found counterpartyId');
        handleGoToDashboard();
    }

    return counterpartyId as string;
};
