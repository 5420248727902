import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 32px 16px;
        flex: 1;
    }
`;

export const Head = styled.div`
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.base(0.1)};
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media ${({ theme }) => theme.breakpoints.md} {
        border: none;
        margin-bottom: 20px;
        padding-bottom: 0;
    }
`;

export const Subtitle = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey700()};

    @media ${({ theme }) => theme.breakpoints.md} {
        text-align: center;
    }
`;

export const HeadRow = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 16px;
    justify-content: space-between;

    @media ${({ theme }) => theme.breakpoints.md} {
        display: contents;
        text-align: center;
    }
`;

export const MobileChildrenWrapper = styled.div`
    display: contents;

    @media ${({ theme }) => theme.breakpoints.md} {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;
