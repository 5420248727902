import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { PARAM_COUNTERPARTY_ID_NAME, USER_PARAM_NAME } from '../../../pages/constants';
import { getCounterpartyById, getIsCounterpartyInitialized } from '../../../store/Counterparty/selectors';
import { BUSINESS_OFFICE_PATHS } from '../../../pages/businessOffice/paths';
import { useAppDispatch } from '../../../store';
import { setCounterparty } from '../../../store/Counterparty';

export const useInitCounterparty = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { [PARAM_COUNTERPARTY_ID_NAME]: counterpartyId } = useParams() as { [PARAM_COUNTERPARTY_ID_NAME]: string };

    const counterparty = useSelector(getCounterpartyById(counterpartyId));
    const isInitializedCounterparty = useSelector(getIsCounterpartyInitialized);
    const isLoadingContextCounterparty = !counterparty && !isInitializedCounterparty;

    useEffect(() => {
        // when not found counterparty
        if (!counterparty && isInitializedCounterparty && counterpartyId !== USER_PARAM_NAME) {
            navigate(BUSINESS_OFFICE_PATHS.dashboard());
        }
    }, [counterparty, counterpartyId, isInitializedCounterparty, navigate]);

    useEffect(() => {
        // when counterpartyId in url changed
        dispatch(setCounterparty(counterpartyId !== USER_PARAM_NAME ? counterpartyId : null));
    }, [counterpartyId, dispatch]);

    return isLoadingContextCounterparty;
};
