import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: 100%;

    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 32px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const MessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

export const Subtitle = styled(Typography)`
    color: ${({ theme }) => theme.colors.grey700()};
`;

export const Notice = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: ${({ theme }) => theme.colors.base(0.5)};
`;

export const ButtonLoginOtherMethod = styled.button`
    color: ${({ theme }) => theme.colors.link()};
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
`;

export const LeftAttemptsLabel = styled(Typography)`
    color: ${({ theme }) => theme.colors.red()};
`;

export const CodeInputWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
