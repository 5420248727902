export enum FORM_FIELD {
    website = 'website',
    name = 'name',
    mccId = 'mccId',
    description = 'description',
}

export type TCreateMerchantIntroduceFormInner = {
    [FORM_FIELD.website]: string;
    [FORM_FIELD.name]: string;
    [FORM_FIELD.mccId]?: number;
    [FORM_FIELD.description]: string;
};

export type TCreateMerchantIntroduceFormOutter = {
    [FORM_FIELD.website]: string;
    [FORM_FIELD.name]: string;
    [FORM_FIELD.mccId]: number;
    [FORM_FIELD.description]: string;
};
