import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 660px;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        gap: 16px;
    }
`;

export const WrapperVatNumberAndTexRegistrationReasonCode = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;

    @media ${({ theme }) => theme.breakpoints.md} {
        display: contents;
    }
`;

export const RusInputsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 16px;

    @media ${({ theme }) => theme.breakpoints.md} {
        grid-template-columns: 1fr;
        row-gap: 16px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    gap: 12px;
    justify-content: center;

    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
    }
`;

export const Notice = styled(Typography)`
    text-align: center;
    color: ${({ theme }) => theme.colors.grey700()};
`;

export const VatNumberWrapper = styled.div<{ $isFullWidth: boolean }>`
    width: ${({ $isFullWidth }) => ($isFullWidth ? '100%' : `50%`)};
    transition: ${({ theme }) => theme.decorations.transition?.base};
    flex-shrink: 0;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;

export const TaxRegistrationReasonCodeWrapper = styled.div<{ $isHide: boolean }>`
    width: 50%;
    display: ${({ $isHide }) => ($isHide ? 'none' : 'block')};
    transition: ${({ theme }) => theme.decorations.transition?.base};

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;
