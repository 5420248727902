import React, { FC, useState } from 'react';
import { Arrow, Scrollbar, useOutsideClick } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import * as S from './styles';
import { ReactComponent as PluIcon } from './assets/plus.svg';
import { useMobile } from '../../../hooks/useMedia';
import { AdaptiveDropdownDrawer } from '../../common/AdaptiveDropdownDrawer';

export type TProps = {
    value?: string;
    options: Array<{ label: string; value: string }>;
    onClickAddOrganization: () => void;
    onSelectOrganization: (id: string) => void;
};

export const OrganizationSelect: FC<TProps> = ({ options, value, onClickAddOrganization, onSelectOrganization }) => {
    const intl = useIntl();
    const theme = useTheme();
    const isMobile = useMobile();

    const [isOpen, setIsOpen] = useState(false);

    const generateArrowPalette = {
        open: theme.colors.base(),
        close: theme.colors.base(),
    };

    const handleDropdownToggle = () => setIsOpen((flag) => !flag);
    const handleDropdownClose = () => setIsOpen(false);
    const wrapperRef = useOutsideClick(handleDropdownClose);

    const selectedLabel = options.find((option) => option.value === value)?.label;

    const handleAddOrganization = () => {
        handleDropdownClose();
        onClickAddOrganization();
    };

    const handleSelectOrganization = (id: string) => {
        onSelectOrganization(id);
        handleDropdownClose();
    };

    return (
        <S.Wrapper ref={isMobile ? undefined : wrapperRef}>
            <S.SelectedOption type="button" onClick={handleDropdownToggle} $isOpen={isOpen}>
                <S.Label variant={isMobile ? 'buttonM' : 'smallMedium'}>{selectedLabel}</S.Label>
                <Arrow isOpen={isOpen} outterSize={isMobile ? 20 : 18} palette={generateArrowPalette} />
            </S.SelectedOption>
            <AdaptiveDropdownDrawer
                onClose={handleDropdownClose}
                isOpen={isOpen}
                dropdownProps={{
                    width: '254px',
                    positionLeft: '0px',
                    positionRight: 'auto',
                }}
            >
                <Scrollbar maxHeight={192}>
                    <S.OptionsWrapper>
                        {options.map((option) => {
                            const isSelected = value === option.value;
                            return (
                                <S.Option
                                    key={option.value}
                                    $isSelected={isSelected}
                                    onClick={() => handleSelectOrganization(option.value)}
                                >
                                    <S.OptionLabel variant="bodyMRegular">
                                        {option.label}{' '}
                                        {isSelected
                                            ? intl.formatMessage({
                                                  id: '(выбрано)',
                                                  defaultMessage: '(выбрано)',
                                              })
                                            : ''}
                                    </S.OptionLabel>
                                </S.Option>
                            );
                        })}
                        <S.ButtonAddOrganization onClick={handleAddOrganization}>
                            <S.OptionLabel variant="bodyMRegular">
                                {intl.formatMessage({
                                    id: 'Добавить организацию',
                                    defaultMessage: 'Добавить организацию',
                                })}
                            </S.OptionLabel>
                            <PluIcon />
                        </S.ButtonAddOrganization>
                    </S.OptionsWrapper>
                </Scrollbar>
            </AdaptiveDropdownDrawer>
        </S.Wrapper>
    );
};
