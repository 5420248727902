import React, { FC, ReactNode } from 'react';
import { InvoiceboxLogo } from '@invoicebox/ui';
import { useSelector } from 'react-redux';
import * as S from './styles';
import { useMobile } from '../../../hooks/useMedia';
import { BUSINESS_OFFICE_PATHS } from '../../../pages/businessOffice/paths';
import { getCurrentCounterpartyId } from '../../../store/Counterparty/selectors';

export type TProps = {
    userActions: ReactNode;
    menuLinks?: Array<{ href: string; label: string }>;
};

export const Header: FC<TProps> = ({ menuLinks, userActions }) => {
    const isMobile = useMobile();
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    return (
        <S.Wrapper>
            <S.Content>
                <S.LogoButton to={BUSINESS_OFFICE_PATHS.dashboard(counterpartyId)}>
                    <InvoiceboxLogo height={26} />
                </S.LogoButton>
                {!!menuLinks?.length && !isMobile && (
                    <S.MenuLinks>
                        {menuLinks.map((menuItem) => (
                            <S.MenuItem key={menuItem.label} to={menuItem.href}>
                                {menuItem.label}
                            </S.MenuItem>
                        ))}
                    </S.MenuLinks>
                )}
                {userActions}
            </S.Content>
        </S.Wrapper>
    );
};
