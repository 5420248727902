import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.white()};
    padding: 8px 0;
    width: 370px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 100%;
    }
`;

export const Header = styled.div`
    padding: 8px 20px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
`;

export const OrderContainerCountLabel = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.5)};
`;

export const DatesInterval = styled(Typography)`
    padding: 4px 12px;
    background-color: ${({ theme }) => theme.colors.wrongGrey100()};
    border-radius: 20px;
    color: ${({ theme }) => theme.colors.base()};
`;

export const Title = styled(Typography)`
    padding: 12px 20px;
    background: #f5f6fa;
    color: ${({ theme }) => theme.colors.base()};
`;
