import React, { FC, useCallback, useEffect, useState } from 'react';
import { IFilter, IFilterStatus } from 'src/store/Order/types';
import OrdersMerchantList from 'src/components/MerchantOrdersList/components/OrdersMerchantList/index';
import PaginationStack from 'src/components/FilterOrders/components/Pagination';
import { fetchOrdersList } from 'src/store/Order';
import { useSelector } from 'react-redux';
import { getIsOrdersLoading, getOrdersList, getOrdersMetaData } from 'src/store/Order/selectors';
import { useAppDispatch } from 'src/store';
import { useParams } from 'react-router-dom';
import { getAllStatusLabel, getOrderStatuses } from 'src/store/Order/constants';
import { COUNT_ITEMS_TO_PAGE, createdFinishField, createdStartField, statusField } from 'src/utils/constants';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import Calendar from '../Calendar';
import { FilterButton, RowForFilter, HeaderLeft, Container } from '../../styles';

type TShopRouteParams = {
    merchantId: string;
};
interface IBaseWidgetProps {
    title: string;
}

const FilterOrders: FC<IBaseWidgetProps> = ({ title }) => {
    const dispatch = useAppDispatch();
    const intl = useIntl();
    const { merchantId } = useParams() as TShopRouteParams;
    const metaData = useSelector(getOrdersMetaData);
    const isLoading = useSelector(getIsOrdersLoading);
    const ordersList = useSelector(getOrdersList);
    const [filter, setFilter] = useState<IFilter>();
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const [pagesButtonsCount, setPagesButtonsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const onToggle = () => {
        setIsOpenCalendar(!isOpenCalendar);
    };

    const handlerAcceptDate = useCallback(
        (dates: Date[]) => {
            const dateStart = dayjs.utc(dates[0]).format();
            const dateEnd = dayjs.utc(dates[1]).format();
            if (!filter) {
                setFilter({ [createdStartField]: String(dateStart), [createdFinishField]: String(dateEnd) });
                setCurrentPage(1);
                // проверка, если в фильтре уже лежат эти значения для избежания лишнего запроса с той же выборки
            } else if (dateStart !== filter[createdStartField] || dateEnd !== filter[createdFinishField]) {
                setFilter({ ...filter, [createdStartField]: String(dateStart), [createdFinishField]: String(dateEnd) });
                setCurrentPage(1);
            }
        },
        [filter],
    );
    const handlerAllStatus = () => {
        setCurrentPage(1);
        // @ts-ignore
        const filterWithoutStatus = Object.fromEntries(
            // @ts-ignore
            Object.entries(filter).filter((item) => item[0] !== statusField),
        );
        setFilter(filterWithoutStatus);
    };
    const handlerWidgetStatus = (status: IFilterStatus) => {
        setCurrentPage(1);
        setFilter({ ...filter, [statusField]: status.state });
    };
    const handleChange = (value: number) => {
        setCurrentPage(value);
    };
    useEffect(() => {
        dispatch(fetchOrdersList({ merchantId, _page: currentPage, ...filter }));
    }, [dispatch, merchantId, currentPage, filter]);

    useEffect(() => {
        if (metaData.totalCount > COUNT_ITEMS_TO_PAGE) {
            setPagesButtonsCount(Math.ceil(metaData.totalCount / COUNT_ITEMS_TO_PAGE));
        } else if (metaData.totalCount <= COUNT_ITEMS_TO_PAGE) {
            setPagesButtonsCount(1);
        }
    }, [metaData.totalCount]);

    return (
        <>
            <RowForFilter>
                <HeaderLeft>{title}</HeaderLeft>
                <Container>
                    <FilterButton $isActive={!(filter && filter[statusField])} onClick={handlerAllStatus}>
                        {getAllStatusLabel(intl)}
                    </FilterButton>
                    {getOrderStatuses(intl).map((status) => (
                        <FilterButton
                            $isActive={(filter && filter[statusField] === status.state) || false}
                            key={status.label}
                            onClick={() => handlerWidgetStatus(status)}
                        >
                            {status.label}
                        </FilterButton>
                    ))}
                </Container>
                <Calendar isOpenCalendar={isOpenCalendar} onToggle={onToggle} handlerAcceptDate={handlerAcceptDate} />
            </RowForFilter>
            <OrdersMerchantList isLoading={isLoading} orders={ordersList} />
            <PaginationStack pagesButtonsCount={pagesButtonsCount} pageNumber={currentPage} onChange={handleChange} />
        </>
    );
};
export default FilterOrders;
