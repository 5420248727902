import React, { FC, ReactNode } from 'react';
import * as S from './styles';
import { ReactComponent as CheckIcon } from './assets/check.svg';

export type TStep = { title: ReactNode; subtitle: ReactNode; isActive: boolean };

export type TProps = {
    steps: Array<TStep>;
};

export const Stepper: FC<TProps> = ({ steps }) => (
    <S.Wrapper>
        {steps.map((step, index, array) => (
            // eslint-disable-next-line react/no-array-index-key
            <S.Step key={index} $isActive={step.isActive}>
                {!(array.length - 1 === index) && <S.DashedLine />}
                <S.CircleWrapper>{step.isActive && <CheckIcon />}</S.CircleWrapper>
                <S.Content>
                    <S.TitleWrapper>{step.title}</S.TitleWrapper>
                    <S.SubtitleWrapper>{step.subtitle}</S.SubtitleWrapper>
                </S.Content>
            </S.Step>
        ))}
    </S.Wrapper>
);
