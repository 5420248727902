import React, { FC, useState } from 'react';
import { Arrow, Typography } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import * as S from './styles';
import { APP_VARIANT_ROUTES, RoutesBusinessOffice } from '../../../pages/constants';
import { nameToInitialsFormatter } from '../../../utils/formatters';
import { useMobile } from '../../../hooks/useMedia';
import { ReactComponent as UserIcon } from './assets/avatar.svg';
import { ReactComponent as BurgerIcon } from './assets/burger.svg';
import { BurgerMenu } from '../BurgerMenu';
import { ProfileMenuItem } from '../../../components/ProfileMenuItem';

export type TProps = {
    userName: string | null;
    menuLinks?: Array<{ href: string; label: string }>;
    onLogout: () => void;
};

export const DocsHeaderAction: FC<TProps> = ({ userName, menuLinks, onLogout }) => {
    const intl = useIntl();
    const isMobile = useMobile();
    const theme = useTheme();

    const [isOpenBurger, setIsOpenBurger] = useState(false);

    const toggleBurger = () => setIsOpenBurger((prev) => !prev);
    const closeBurger = () => setIsOpenBurger(false);

    const arrowPalette = {
        close: theme.colors.base(),
    };

    const desktopUserButton = (
        <S.UserWrapper>
            <S.UserName variant="buttonM">
                {userName || intl.formatMessage({ id: 'Войти', defaultMessage: 'Войти' })}
            </S.UserName>
            <S.UserArrowWrapper>
                <Arrow palette={arrowPalette} isOpen={false} outterSize={20} defaultRotate={userName ? 0 : 270} />
            </S.UserArrowWrapper>
        </S.UserWrapper>
    );

    const mobileUserButton = (
        <S.MobileUserWrapper>
            {userName ? (
                <S.UserShortNameWrapper>
                    <Typography variant="captionRegular">{nameToInitialsFormatter(userName)}</Typography>
                </S.UserShortNameWrapper>
            ) : (
                <UserIcon />
            )}
        </S.MobileUserWrapper>
    );
    const userButton = isMobile ? mobileUserButton : desktopUserButton;

    return (
        <>
            <S.MobileActionsWrapper>
                {userName ? (
                    <ProfileMenuItem profileLink={RoutesBusinessOffice.personal} logout={onLogout}>
                        {userButton}
                    </ProfileMenuItem>
                ) : (
                    <S.LoginButton href={APP_VARIANT_ROUTES.auth}>{userButton}</S.LoginButton>
                )}
                {!!menuLinks?.length && isMobile && (
                    <>
                        <S.MobileActionsDivider />
                        <S.BurgerButton type="button" onClick={toggleBurger}>
                            <BurgerIcon />
                        </S.BurgerButton>
                    </>
                )}
            </S.MobileActionsWrapper>
            {menuLinks && <BurgerMenu isOpen={isOpenBurger} onClose={closeBurger} menuLinks={menuLinks} />}
        </>
    );
};
