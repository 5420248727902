import React, { FC, useEffect } from 'react';
import {
    Fields,
    MerchantOrderNumber,
    OrderCustomer,
    OrderWrapper,
    RefundButton,
    RefundTotalTitle,
    RefundTotalWrapper,
    StatusIcon,
    TotalAfterRefund,
    WrapOrderIcon,
} from 'src/pages/businessOffice/Refund/components/RefundTotal/styles';
import RowValue from 'src/pages/businessOffice/Refund/components/RowValue';
import { ReactComponent as OrdersIcon } from 'src/assets/icons/order.svg';
import { ORDER_FIELDS, TOrder, TOrderItem } from 'src/store/Order/types';
import { formatCurrencyToShow, getTotalAmountAndTotalVat, toValidCurrencyFloat } from 'src/utils/helpers';
import dayjs from 'dayjs';
import { DD_MM_YYYY_DATE_FORMAT } from 'src/utils/constants';
import { IDebitInvoice } from 'src/store/DebitInvoice/types';
import { isEmpty } from 'lodash';
import { useFieldArray } from 'react-final-form-arrays';
import Skeleton from 'src/components/Skeleton';
import { FormApi } from 'final-form';
import { ICreateRefundForm } from 'src/store/OrderRefunds/types';
import { useIntl } from 'react-intl';
import { useLoadingSubmit } from '@invoicebox/ui';

interface IRefundTotal {
    onSubmit: () => void;
    order: TOrder;
    debitInvoice?: IDebitInvoice;
    isValid: boolean;
    form: FormApi<ICreateRefundForm, Partial<ICreateRefundForm>>;
}

const RefundTotal: FC<IRefundTotal> = ({ form, isValid, debitInvoice, onSubmit, order }) => {
    const intl = useIntl();
    const isShowDebitInvoiceCustomer = !isEmpty(debitInvoice?.customer);
    const { fields } = useFieldArray<TOrderItem>(ORDER_FIELDS.basketItems);
    const isFullValid = fields.value?.length > 0 && isValid;
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();

    const totalOrderFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Сумма' }),
            value: order?.amount || order?.amount === 0 ? formatCurrencyToShow(order?.amount) : undefined,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'НДС' }),
            value: order?.vatAmount || order?.vatAmount === 0 ? formatCurrencyToShow(order?.vatAmount) : undefined,
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Оформлен' }),
            value: order?.createdAt && dayjs(order?.createdAt).format(DD_MM_YYYY_DATE_FORMAT),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Оплачен' }),
            value: order?.expirationDate && dayjs(order?.expirationDate).format(DD_MM_YYYY_DATE_FORMAT),
        },
    ];

    const { totalAmount, totalVat } = getTotalAmountAndTotalVat(fields?.value);
    const validAmount = toValidCurrencyFloat(totalAmount);
    const validVatAmount = toValidCurrencyFloat(totalVat);

    const totalRefundFields = [
        {
            label: intl.formatMessage({ defaultMessage: 'Сум. возврата' }),
            value: formatCurrencyToShow(validAmount),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'НДС' }),
            value: formatCurrencyToShow(validVatAmount),
        },
    ];

    useEffect(() => {
        form.mutators.setAmountAndVatAmount(validAmount, validVatAmount);
    }, [form.mutators, totalAmount, validAmount, validVatAmount]);

    const onClickCreateRefund = async () => {
        toggleLoadingSubmit();
        await onSubmit();
        toggleLoadingSubmit();
    };

    return (
        <RefundTotalWrapper>
            <RefundTotalTitle variant="subheading">
                {intl.formatMessage({ defaultMessage: 'Возврат по заказу' })}
            </RefundTotalTitle>
            <OrderWrapper>
                <WrapOrderIcon>
                    <OrdersIcon />
                    <StatusIcon />
                </WrapOrderIcon>
                <OrderCustomer variant="regular14">
                    {(isShowDebitInvoiceCustomer ? debitInvoice?.customer?.name : order?.customer?.name) || (
                        <Skeleton width={80} />
                    )}
                </OrderCustomer>
                <MerchantOrderNumber variant="regular12">
                    {order?.merchantOrderId ? `№ ${order?.merchantOrderId}` : <Skeleton width={80} />}
                </MerchantOrderNumber>
            </OrderWrapper>
            <Fields>
                {totalOrderFields.map((orderField) => (
                    <RowValue key={orderField.label} label={orderField.label} value={orderField.value} />
                ))}
            </Fields>
            <Fields>
                {totalRefundFields.map((refundField) => (
                    <RowValue key={refundField.label} label={refundField.label} value={refundField.value} />
                ))}
            </Fields>
            <TotalAfterRefund
                label={intl.formatMessage({ defaultMessage: 'Итого сумма после возврата' })}
                value={
                    order?.amount ? formatCurrencyToShow(toValidCurrencyFloat(order.amount - totalAmount)) : undefined
                }
            />
            <RefundButton isLoading={isLoadingSubmit} disabled={!isFullValid} onClick={onClickCreateRefund}>
                {intl.formatMessage({ defaultMessage: 'Произвести возврат' })}
            </RefundButton>
        </RefundTotalWrapper>
    );
};

export default RefundTotal;
