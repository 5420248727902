import styled from 'styled-components';

export const DocumentReceiveMethodsWrapper = styled.div`
    flex-shrink: 0;

    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 100%;
    }
`;

export const ContentWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    gap: 30px;
    justify-content: space-between;

    @media ${({ theme }) => theme.breakpoints.ld} {
        flex-direction: column;
        gap: 24px;
    }
`;

export const StepButtonWrapper = styled.div`
    position: fixed;
    bottom: 16px;
    padding: 0 16px;
    width: 100%;
`;

export const MobileStepWrapper = styled.div`
    background: ${({ theme }) => theme.colors.white()};
    border-radius: 14px;
    padding-bottom: 80px;
    flex: 1;
`;
