import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import * as S from './styles';
import { ReactComponent as SuccessIcon } from './assets/success-icon.svg';

export type TProps = {
    iconColor: string;
    title: string;
    descriptions: Array<string>;
};

export const DocumentSentSuccessfully: FC<TProps> = ({ iconColor, descriptions, title }) => (
    <S.Wrapper $iconColor={iconColor}>
        <S.IconWrapper>
            <SuccessIcon />
        </S.IconWrapper>
        <S.Content>
            <Typography variant="headline4">{title}</Typography>
            <S.DescriptionWrapper>
                {descriptions.map((descriptionItem) => (
                    <Typography key={descriptionItem} variant="bodyMRegular">
                        {descriptionItem}
                    </Typography>
                ))}
            </S.DescriptionWrapper>
        </S.Content>
    </S.Wrapper>
);
