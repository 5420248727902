import { useEffect, useState } from 'react';
import { NewCounterparty } from '../../../../store/NewCounterparty/entity';
import { getCounterpartyDetailListByVatNumberRequest } from '../../../../store/CounterpartyDetail/http';
import { TOrganization } from '../../../../store/CounterpartyDetail/types';
import { RUSCountryCode } from '../../../../store/Country/types';

export const useFetchCounterparty = (vatNumber: string) => {
    const [counterparties, setCounterparties] = useState<Array<TOrganization>>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (NewCounterparty.couldIdentifyByVat(vatNumber)) {
            setIsLoading(true);
            getCounterpartyDetailListByVatNumberRequest(vatNumber, RUSCountryCode)
                .then(({ data }) => setCounterparties(data.data))
                .finally(() => setIsLoading(false));
        }
    }, [vatNumber]);

    const options = counterparties.map((counterparty) => ({ value: counterparty.vatNumber, entity: counterparty }));

    return { options, isLoading };
};
