import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import FormProvider from '../../../components/FormProvider';
import { RHFTextInput } from '../../formFields/RHFTextInput';
import { loginAuthSchema } from './validation';
import Button from '../../common/Button';
import * as S from './styles';
import { FORM_FIELD, TAuthByLoginForm } from './constants';
import { supportInvoiceBoxLink } from '../../../utils/constants';

export type TProps = {
    onSubmit: (formValues: TAuthByLoginForm) => Promise<void>;
};

export const AuthByLogin: FC<TProps> = ({ onSubmit }) => {
    const intl = useIntl();

    const defaultValues = {
        identifier: '',
        password: '',
    };

    const methods = useForm<TAuthByLoginForm>({
        defaultValues,
        resolver: yupResolver(loginAuthSchema),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: { isValid, isSubmitting },
    } = methods;

    const submit = (formValues: TAuthByLoginForm) => onSubmit(formValues);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
            <S.ContentWrapper>
                <S.InputsWrapper>
                    <RHFTextInput
                        label={intl.formatMessage({
                            id: 'Логин',
                            defaultMessage: 'Логин',
                        })}
                        name={FORM_FIELD.identifier}
                        size="M"
                        autoFocus
                    />
                    <RHFTextInput
                        label={intl.formatMessage({
                            id: 'Пароль',
                            defaultMessage: 'Пароль',
                        })}
                        name={FORM_FIELD.password}
                        type="password"
                        size="M"
                    />
                </S.InputsWrapper>

                <Button size="large" disabled={!isValid} htmlType="submit" fullWidth isLoading={isSubmitting}>
                    {intl.formatMessage({
                        id: 'Продолжить',
                        defaultMessage: 'Продолжить',
                    })}
                </Button>

                <S.LinksContainer>
                    <Button target="_blank" type="link" href={supportInvoiceBoxLink}>
                        {intl.formatMessage({
                            id: 'Справка',
                            defaultMessage: 'Справка',
                        })}
                    </Button>
                </S.LinksContainer>
            </S.ContentWrapper>
        </FormProvider>
    );
};
