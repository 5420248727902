import styled from 'styled-components';

export const Wrapper = styled.div`
    border-radius: 20px;
    padding-top: 8px;
    background-color: ${({ theme }) => theme.colors.white()};
    width: 100%;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding-top: 4px;
        border-radius: 14px;
    }
`;
