import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import * as S from './styles';
import Checkbox from '../../../components/Checkbox';
import { THeadLabel } from '../types';

export type TProps = {
    headLabels: Array<THeadLabel>;
    isSelectedAllRows: boolean;
    onSelectAllRows: () => void;
    isHaveDescriptionColumn: boolean;
};

export const ColumnsHeader: FC<TProps> = ({
    headLabels,
    onSelectAllRows,
    isSelectedAllRows,
    isHaveDescriptionColumn,
}) => {
    const handleSelectAllRows = () => onSelectAllRows();

    return (
        <thead>
            <S.Wrapper>
                <th>
                    <Checkbox checked={isSelectedAllRows} onChange={handleSelectAllRows} />
                </th>
                {headLabels.map((headLabel) => (
                    <th key={headLabel.name} align={headLabel.align || 'left'}>
                        {typeof headLabel.label === 'string' ? (
                            <Typography variant="bodyMRegular">{headLabel.label}</Typography>
                        ) : (
                            headLabel.label
                        )}
                    </th>
                ))}
                {isHaveDescriptionColumn && <th> </th>}
            </S.Wrapper>
        </thead>
    );
};
