import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { CodeInput, Typography, useLoadingSubmit } from '@invoicebox/ui';
import { AuthStepContainer } from '../AuthStepContainer';
import * as S from './styles';
import { ReactComponent as LockIcon } from './assets/lock.svg';
import Button from '../../common/Button';
import { SMS_CODE_LENGTH } from '../../../utils/constants';
import { getNoun } from '../../../utils/helpers';

export type TProps = {
    onBack: () => void;
    onInitialScreenMove: () => void;
    onResendCode: () => Promise<any>;
    onSubmit: (codeValue: string) => Promise<any>;
    phone: string;
    isCodeError: boolean;
    isDisabled: boolean;
    timer: number;
    leftAttempts: number;
};

export const EnterSmsCodeScreen: FC<TProps> = ({
    onBack,
    phone,
    onInitialScreenMove,
    onResendCode,
    isCodeError,
    onSubmit,
    isDisabled,
    timer,
    leftAttempts,
}) => {
    const intl = useIntl();

    const [code, setCode] = useState('');
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();

    const isCanAgainSendCode = timer === 0;

    const handleResendCode = () => {
        onResendCode().then(() => {
            setCode('');
        });
    };

    const handleCodeChange = async (value: string) => {
        setCode(value);

        if (value.length === SMS_CODE_LENGTH) {
            toggleLoadingSubmit();
            const result = await onSubmit(value);

            if (result?.error) {
                setCode('');
            }
            toggleLoadingSubmit();
        }
    };

    return (
        <AuthStepContainer onBack={onBack} height={540}>
            <S.Wrapper>
                <S.ContentWrapper>
                    <S.IconWrapper>
                        <LockIcon />
                    </S.IconWrapper>
                    <S.MessageWrapper>
                        <Typography variant="headline3">
                            {intl.formatMessage({
                                id: 'Введите код из смс',
                                defaultMessage: 'Введите код из смс',
                            })}
                        </Typography>
                        <S.Subtitle variant="bodyMRegular">
                            {intl.formatMessage(
                                {
                                    id: 'Пожалуйста, введите код из СМС сообщения, отправленный на номер N',
                                    defaultMessage:
                                        'Пожалуйста, введите код из СМС сообщения, отправленный на номер {number}',
                                },
                                {
                                    number: phone,
                                },
                            )}
                        </S.Subtitle>
                    </S.MessageWrapper>
                    <S.CodeInputWrapper>
                        <CodeInput
                            codeLength={SMS_CODE_LENGTH}
                            value={code}
                            onChange={handleCodeChange}
                            hasError={isCodeError}
                            isLarge={false}
                            disabled={isDisabled}
                            autoFocus
                        />
                    </S.CodeInputWrapper>

                    <S.ButtonsWrapper>
                        <Button
                            size="large"
                            fullWidth
                            disabled={code.length !== SMS_CODE_LENGTH || isDisabled}
                            isLoading={isLoadingSubmit}
                        >
                            {intl.formatMessage({
                                id: 'Продолжить',
                                defaultMessage: 'Продолжить',
                            })}
                        </Button>
                        {isCanAgainSendCode && (
                            <Button type="link" onClick={handleResendCode}>
                                {intl.formatMessage({
                                    id: 'Отправить код повторно',
                                    defaultMessage: 'Отправить код повторно',
                                })}
                            </Button>
                        )}
                    </S.ButtonsWrapper>
                    {!isCanAgainSendCode && (
                        <S.Notice>
                            {!isCodeError && (
                                <Typography variant="captionRegular">
                                    {intl.formatMessage({
                                        id: 'Не получили код?',
                                        defaultMessage: 'Не получили код?',
                                    })}
                                </Typography>
                            )}

                            <Typography variant="smallMedium">
                                {intl.formatMessage(
                                    {
                                        id: 'Отправить повторно через N сек',
                                        defaultMessage: 'Отправить повторно через {seconds} сек',
                                    },
                                    {
                                        seconds: timer,
                                    },
                                )}
                            </Typography>

                            {isCodeError && (
                                <S.LeftAttemptsLabel variant="captionRegular">
                                    {leftAttempts > 0
                                        ? `${intl.formatMessage({
                                              id: 'Код неверный (осталось',
                                              defaultMessage: 'Код неверный (осталось',
                                          })} ${leftAttempts} ${getNoun(
                                              leftAttempts,
                                              intl.formatMessage({
                                                  id: 'попытка',
                                                  defaultMessage: 'попытка',
                                              }),
                                              intl.formatMessage({
                                                  id: 'попытки',
                                                  defaultMessage: 'попытки',
                                              }),
                                              intl.formatMessage({
                                                  id: 'попыток',
                                                  defaultMessage: 'попыток',
                                              }),
                                          )})`
                                        : intl.formatMessage({
                                              id: 'Запросите новый код',
                                              defaultMessage: 'Запросите новый код',
                                          })}
                                </S.LeftAttemptsLabel>
                            )}
                        </S.Notice>
                    )}
                </S.ContentWrapper>
                <S.ButtonLoginOtherMethod type="button" onClick={onInitialScreenMove}>
                    <Typography variant="bodyMRegular">
                        {intl.formatMessage({
                            id: 'Войти другим способом',
                            defaultMessage: 'Войти другим способом',
                        })}
                    </Typography>
                </S.ButtonLoginOtherMethod>
            </S.Wrapper>
        </AuthStepContainer>
    );
};
