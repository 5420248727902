import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'src/store';
import { fetchOrderRefundsList } from 'src/store/OrderRefunds';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    getOrderRefundIsLoading,
    getOrderRefundMetaData,
    getOrderRefundsListWithCustomer,
} from 'src/store/OrderRefunds/selectors';
import { IFilter, IFilterStatus, TOrder, typeOrder } from 'src/store/Order/types';
import dayjs from 'dayjs';
import { COUNT_ITEMS_TO_PAGE, createdFinishField, createdStartField, statusField } from 'src/utils/constants';
import { Container, FilterButton, HeaderLeft, RowForFilter } from 'src/components/FilterOrders/styles';
import { getAllStatusLabel } from 'src/store/Order/constants';
import Calendar from 'src/components/FilterOrders/components/Calendar';
import OrdersMerchantList from 'src/components/MerchantOrdersList/components/OrdersMerchantList';
import { getRefundStatuses } from 'src/store/OrderRefunds/constants';
import PaginationStack from 'src/components/FilterOrders/components/Pagination';
import { RefundsWrapper } from 'src/pages/businessOffice/Shop/ShopTabsTables/Refunds/components/RefundsTable/styles';
import { useIntl } from 'react-intl';
import { TShopRouteParams } from '../../../../index';

const RefundsTable = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const { merchantId } = useParams() as TShopRouteParams;

    const refundOrderList: TOrder[] = useSelector(getOrderRefundsListWithCustomer) as any;
    const metaData = useSelector(getOrderRefundMetaData);
    const isLoading = useSelector(getOrderRefundIsLoading);

    const [filter, setFilter] = useState<IFilter>();
    const [isOpenCalendar, setIsOpenCalendar] = useState(false);
    const [pagesButtonsCount, setPagesButtonsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const refundStatuses = getRefundStatuses(intl);

    const onToggle = () => {
        setIsOpenCalendar(!isOpenCalendar);
    };

    const handlerAcceptDate = useCallback(
        (dates: Date[]) => {
            const dateStart = dayjs.utc(dates[0]).format();
            const dateEnd = dayjs.utc(dates[1]).format();
            if (!filter) {
                setFilter({ [createdStartField]: String(dateStart), [createdFinishField]: String(dateEnd) });
                setCurrentPage(1);
                // проверка, если в фильтре уже лежат эти значения для избежания лишнего запроса с той же выборки
            } else if (dateStart !== filter[createdStartField] || dateEnd !== filter[createdFinishField]) {
                setFilter({ ...filter, [createdStartField]: String(dateStart), [createdFinishField]: String(dateEnd) });
                setCurrentPage(1);
            }
        },
        [filter],
    );
    const handlerAllStatus = () => {
        setCurrentPage(1);
        // @ts-ignore
        const filterWithoutStatus = Object.fromEntries(
            // @ts-ignore
            Object.entries(filter).filter((item) => item[0] !== statusField),
        );
        setFilter(filterWithoutStatus);
    };
    const handlerWidgetStatus = (status: IFilterStatus) => {
        setCurrentPage(1);
        setFilter({ ...filter, [statusField]: status.state });
    };
    const handleChange = (value: number) => {
        setCurrentPage(value);
    };
    useEffect(() => {
        dispatch(fetchOrderRefundsList({ merchantId, _page: currentPage, ...filter }));
    }, [dispatch, merchantId, currentPage, filter]);

    useEffect(() => {
        if (metaData.totalCount > COUNT_ITEMS_TO_PAGE) {
            setPagesButtonsCount(Math.ceil(metaData.totalCount / COUNT_ITEMS_TO_PAGE));
        } else if (metaData.totalCount <= COUNT_ITEMS_TO_PAGE) {
            setPagesButtonsCount(1);
        }
    }, [metaData.totalCount]);

    return (
        <RefundsWrapper>
            <RowForFilter>
                <HeaderLeft>{intl.formatMessage({ defaultMessage: 'Возвраты' })}</HeaderLeft>
                <Container>
                    <FilterButton $isActive={!(filter && filter[statusField])} onClick={handlerAllStatus}>
                        {getAllStatusLabel(intl)}
                    </FilterButton>
                    {refundStatuses.map((status) => (
                        <FilterButton
                            $isActive={(filter && filter[statusField] === status.state) || false}
                            key={status.label}
                            onClick={() => handlerWidgetStatus(status)}
                        >
                            {status.label}
                        </FilterButton>
                    ))}
                </Container>
                <Calendar isOpenCalendar={isOpenCalendar} onToggle={onToggle} handlerAcceptDate={handlerAcceptDate} />
            </RowForFilter>
            <OrdersMerchantList isLoading={isLoading} orders={refundOrderList} orderType={typeOrder.ORDER_REFUND} />
            <PaginationStack pagesButtonsCount={pagesButtonsCount} pageNumber={currentPage} onChange={handleChange} />
        </RefundsWrapper>
    );
};

export default RefundsTable;
