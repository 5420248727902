import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 16px 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: ${({ theme }) => theme.colors.white()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.large}px;
    width: 370px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 100%;
        padding: 16px 20px 20px;
        gap: 20px;
    }
`;

export const DocumentReceiveMethodsListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-decoration: none;
`;

export const ButtonDocumentReceiveMethod = styled.button`
    text-align: left;

    &:hover {
        opacity: 0.8;
    }

    &:disabled {
        opacity: 0.5;
        cursor: default;
    }
`;
