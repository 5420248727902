import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { IntroduceContainer } from '../IntroduceContainer';
import * as S from './styles';
import { ReactComponent as FlagIcon } from './assets/flag.svg';

export type TProps = {
    organizationName: string;
    onClose: () => void;
};

export const IntroduceBuyerSuccess: FC<TProps> = ({ organizationName, onClose }) => {
    const intl = useIntl();

    return (
        <IntroduceContainer>
            <S.Wrapper>
                <FlagIcon />
                <S.Description variant="bodyMRegular">
                    {intl.formatMessage(
                        {
                            id: 'Администратору организации {name} был направлен запрос. После подтверждения запроса, вам будет предоставлен доступ',
                            defaultMessage:
                                'Администратору организации {name} был направлен запрос. После подтверждения запроса, вам будет предоставлен доступ',
                        },
                        {
                            name: organizationName,
                        },
                    )}
                </S.Description>
                <S.CloseButton onClick={onClose} type="secondary" size="large">
                    {intl.formatMessage({
                        id: 'Закрыть',
                        defaultMessage: 'Закрыть',
                    })}
                </S.CloseButton>
            </S.Wrapper>
        </IntroduceContainer>
    );
};
