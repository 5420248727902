import React, { FocusEvent, forwardRef, useCallback } from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { Autocomplete, TAutocompleteProps } from '@invoicebox/ui';

export type TProps = Omit<TAutocompleteProps, 'value'> & { name: string } & Pick<
        Partial<TAutocompleteProps>,
        'onChange'
    >;

export const RHFAutocomplete = forwardRef<HTMLInputElement, TProps>(
    ({ name, onChange, onFocus, onBlur, ...other }, ref) => {
        const { control } = useFormContext();

        const render = useCallback(
            ({ field, fieldState: { error } }: { field: ControllerRenderProps; fieldState: ControllerFieldState }) => {
                const handleChange = (value: string, option?: { entity?: any; value: string }) => {
                    field.onChange(value);
                    if (onChange) {
                        onChange(value, option);
                    }
                };

                const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
                    field.onChange(event);
                    if (onBlur) {
                        onBlur(event);
                    }
                };

                return (
                    <Autocomplete
                        ref={ref}
                        {...other}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={field.value || ''}
                        hasError={!!error}
                        onFocus={onFocus}
                    />
                );
            },
            [onBlur, onChange, onFocus, other, ref],
        );

        return <Controller name={name} control={control} render={render} />;
    },
);
