import styled from 'styled-components';

export const InputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const LinksContainer = styled.div`
    padding: 2px 12px;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: center;
    gap: 24px;
`;
