import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { TOption } from '@invoicebox/ui/dist/components/form/Select';
import { yupResolver } from '@hookform/resolvers/yup';
import { LENGTH_VAT_NUMBER } from '@invoicebox/ui';
import FormProvider from '../../../components/FormProvider';
import * as S from './styles';
import {
    RHFVatNumberAutocomplete,
    TProps as TRHFVatNumberAutocompleteProps,
} from '../../formFields/widgets/RHFVatNumberAutocomplete';
import { FORM_FIELD, TCreateCounterpartyIntroduceForm } from './constants';
import { organizationLogic } from '../../../store/CounterpartyDetail/organizationLogic';
import Button from '../../common/Button';
import { useFetchCounterpartyDetailOptions } from '../../../hooks/useFetchCounterpartyDetailOptions';
import { countryLogic } from '../../../store/Country/logic';
import { TOrganization } from '../../../store/CounterpartyDetail/types';
import { RHFTextInput } from '../../formFields/RHFTextInput';
import { RHFSelect } from '../../formFields/RHFSelect';
import { TLegalEntity } from '../../../store/Dictionaries/types';
import { organizationSchema } from './validation';
import { INDIVIDUAL_OGRNIP, ORGANISATION_OGRN, TAX_REGISTRATION_REASON_CODE_LENGTH } from '../../../utils/constants';
import { RUSCountryCode } from '../../../store/Country/types';
import { TCounterpartyCreate } from '../../../store/Counterparty/types';
import { useTablet } from '../../../hooks/useMedia';

const emptyValues = {
    [FORM_FIELD.countryId]: RUSCountryCode,
    [FORM_FIELD.vatNumber]: '',
    [FORM_FIELD.taxRegistrationReasonCode]: '',
    [FORM_FIELD.legalEntityTypeId]: undefined,
    [FORM_FIELD.registrationNumber]: '',
    [FORM_FIELD.name]: '',
    [FORM_FIELD.nameI18n]: '',
    [FORM_FIELD.registrationAddress]: '',
};

export type TProps = {
    onSubmit: (value: TCounterpartyCreate) => Promise<void>;
    onSkip: () => void;
    countryOptions: Pick<TRHFVatNumberAutocompleteProps['countrySelectProps'], 'options'>['options'];
    legalEntityTypeOptions: Array<TOption<TLegalEntity['id']>>;
    defaultValues: Partial<TOrganization> & { countryId: string };
};

export const OrganizationForm: FC<TProps> = ({
    onSubmit,
    onSkip,
    countryOptions,
    defaultValues,
    legalEntityTypeOptions,
}) => {
    const intl = useIntl();
    const isTablet = useTablet();

    const methods = useForm<TCreateCounterpartyIntroduceForm>({
        defaultValues: {
            ...emptyValues,
            ...defaultValues,
        },
        mode: 'all',
        resolver: yupResolver(organizationSchema),
    });

    const {
        handleSubmit,
        formState: { isValid, isSubmitting },
        watch,
        setValue,
        trigger,
    } = methods;

    useEffect(() => {
        // 2 is vatNumber and countryId
        if (Object.values(defaultValues).length > 2) {
            trigger();
        }
    }, [defaultValues, trigger]);

    const formVatNumber = watch(FORM_FIELD.vatNumber);
    const formCountryId = watch(FORM_FIELD.countryId);
    const isRUSCountry = formCountryId === RUSCountryCode;

    const { options, isLoading } = useFetchCounterpartyDetailOptions(formVatNumber, formCountryId);

    const handleVatNumberChange = (newValue: string, option?: { entity?: any }) => {
        if (newValue.length !== LENGTH_VAT_NUMBER.RUS_LEGAL_ENTREPRENEUR) {
            setValue(FORM_FIELD.taxRegistrationReasonCode, undefined);
        }

        if (option) {
            const organization = option.entity as TOrganization;
            setValue(FORM_FIELD.name, organization.name || '', { shouldValidate: true });
            setValue(FORM_FIELD.registrationAddress, organization.registrationAddress || '', { shouldValidate: true });
            setValue(FORM_FIELD.taxRegistrationReasonCode, organization.taxRegistrationReasonCode, {
                shouldValidate: true,
            });
        }
    };

    const handleCountryChange = (value: string) => {
        if (value !== RUSCountryCode) {
            setValue(FORM_FIELD.legalEntityTypeId, undefined);
            setValue(FORM_FIELD.taxRegistrationReasonCode, '');
            setValue(FORM_FIELD.registrationNumber, '');
        }
    };

    const submit = (formValues: TCreateCounterpartyIntroduceForm) => onSubmit(formValues);

    const isHideTaxRegistrationReasonCode = !(
        isRUSCountry && formVatNumber.length === LENGTH_VAT_NUMBER.RUS_LEGAL_ENTREPRENEUR
    );

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
            <S.Wrapper>
                <S.WrapperVatNumberAndTexRegistrationReasonCode>
                    <S.VatNumberWrapper $isFullWidth={isHideTaxRegistrationReasonCode}>
                        <RHFVatNumberAutocomplete
                            name={FORM_FIELD.vatNumber}
                            options={options}
                            isLoadingOptions={isLoading}
                            onVatNumberChange={handleVatNumberChange}
                            countrySelectProps={{
                                name: FORM_FIELD.countryId,
                                options: countryOptions,
                            }}
                            minValidVatNumberLength={organizationLogic.getMinLengthVatNumber(formCountryId) || 0}
                            label={countryLogic.getVatLabel(formCountryId, intl)}
                            onCountryChange={handleCountryChange}
                        />
                    </S.VatNumberWrapper>
                    <S.TaxRegistrationReasonCodeWrapper $isHide={isHideTaxRegistrationReasonCode}>
                        <RHFTextInput
                            name={FORM_FIELD.taxRegistrationReasonCode}
                            label={intl.formatMessage({ id: 'КПП', defaultMessage: 'КПП' })}
                            isOnlyNumbers
                            maxLength={TAX_REGISTRATION_REASON_CODE_LENGTH}
                        />
                    </S.TaxRegistrationReasonCodeWrapper>
                </S.WrapperVatNumberAndTexRegistrationReasonCode>

                {isRUSCountry && (
                    <S.RusInputsWrapper>
                        <RHFSelect
                            label={intl.formatMessage({ id: 'ОПФ', defaultMessage: 'ОПФ' })}
                            options={legalEntityTypeOptions}
                            name={FORM_FIELD.legalEntityTypeId}
                        />
                        <RHFTextInput
                            name={FORM_FIELD.registrationNumber}
                            label={
                                formVatNumber.length === LENGTH_VAT_NUMBER.RUS_INDIVIDUAL_ENTREPRENEUR
                                    ? intl.formatMessage({ id: 'ОГРНИП', defaultMessage: 'ОГРНИП' })
                                    : intl.formatMessage({ id: 'ОГРН', defaultMessage: 'ОГРН' })
                            }
                            isOnlyNumbers
                            maxLength={
                                formVatNumber.length === LENGTH_VAT_NUMBER.RUS_INDIVIDUAL_ENTREPRENEUR
                                    ? INDIVIDUAL_OGRNIP
                                    : ORGANISATION_OGRN
                            }
                        />
                    </S.RusInputsWrapper>
                )}
                <RHFTextInput
                    name={FORM_FIELD.name}
                    label={intl.formatMessage({
                        id: 'Наименование контрагента',
                        defaultMessage: 'Наименование контрагента',
                    })}
                />
                <RHFTextInput
                    name={FORM_FIELD.nameI18n}
                    label={intl.formatMessage({
                        id: 'Наименование контрагента на оригинальном языке',
                        defaultMessage: 'Наименование контрагента на оригинальном языке',
                    })}
                />
                <RHFTextInput
                    name={FORM_FIELD.registrationAddress}
                    label={intl.formatMessage({ id: 'Юридический адрес', defaultMessage: 'Юридический адрес' })}
                />
                <S.Notice variant="captionRegular">
                    {intl.formatMessage({
                        id: 'Я подтверждаю полноту и корректность указанных сведений',
                        defaultMessage: 'Я подтверждаю полноту и корректность указанных сведений',
                    })}
                </S.Notice>

                <S.Buttons>
                    <Button size="large" type="secondary" onClick={onSkip} fullWidth={isTablet}>
                        {intl.formatMessage({ id: 'Пропустить', defaultMessage: 'Пропустить' })}
                    </Button>
                    <Button
                        htmlType="submit"
                        size="large"
                        disabled={!isValid}
                        fullWidth={isTablet}
                        isLoading={isSubmitting}
                    >
                        {intl.formatMessage({ id: 'Подтвердить', defaultMessage: 'Подтвердить' })}
                    </Button>
                </S.Buttons>
            </S.Wrapper>
        </FormProvider>
    );
};
