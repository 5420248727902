import styled from 'styled-components';

export const CountryFieldWrapper = styled.div`
    padding-left: 20px;
    width: 100%;
`;

export const OrganizationAutocompleteSkeletonWrapper = styled.div`
    padding: 6px 12px;

    &:first-child {
        padding-top: 12px;
    }

    &:last-child {
        padding-bottom: 12px;
    }
`;
