import React, { FC } from 'react';
import Button from 'src/stories/common/Button';
import { useIntl } from 'react-intl';
import { ButtonsContainer } from './styles';

interface IButtonsNextPrev {
    onClickNext?: () => void;
    onClickPrev?: (() => void) | null;
    isActiveNextButton?: boolean;
    titleNext?: string;
    titlePrev?: string;
    isLoading?: boolean;
}

const ButtonsNextPrev: FC<IButtonsNextPrev> = ({
    titleNext,
    titlePrev,
    isActiveNextButton = true,
    onClickNext,
    onClickPrev,
    isLoading = false,
}) => {
    const intl = useIntl();

    return (
        <ButtonsContainer>
            {onClickPrev && (
                <Button onClick={onClickPrev} type="secondary">
                    {titlePrev || intl.formatMessage({ defaultMessage: 'Отменить' })}
                </Button>
            )}
            {onClickNext && (
                <Button isLoading={isLoading} disabled={!isActiveNextButton} onClick={onClickNext}>
                    {titleNext || intl.formatMessage({ defaultMessage: 'Далее' })}
                </Button>
            )}
        </ButtonsContainer>
    );
};

export default ButtonsNextPrev;
