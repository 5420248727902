import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@invoicebox/ui';
import * as S from './styles';

export type TAuthType = 'phone' | 'login';

export type TProps = {
    value: TAuthType;
    onChange: (value: TAuthType) => void;
};

export const AuthTypeSwitcher: FC<TProps> = ({ value, onChange }) => {
    const intl = useIntl();

    const arrAuthTypes = [
        {
            label: intl.formatMessage({
                id: 'По номеру телефона',
                defaultMessage: 'По номеру телефона',
            }),
            value: 'phone' as const,
        },
        {
            label: intl.formatMessage({
                id: 'По логину',
                defaultMessage: 'По логину',
            }),
            value: 'login' as const,
        },
    ];

    return (
        <S.Wrapper>
            {arrAuthTypes.map((authType) => (
                <S.SwitchButton
                    key={authType.value}
                    $isActive={value === authType.value}
                    onClick={() => onChange(authType.value)}
                >
                    <Typography variant="bodyMRegular">{authType.label}</Typography>
                </S.SwitchButton>
            ))}
        </S.Wrapper>
    );
};
