import styled from 'styled-components';

export const Wrapper = styled.div`
    border-radius: 12px;
    background: ${({ theme }) => theme.colors.base(0.04)};
    ${({ theme }) => theme.mixins.flexCenter};
    display: inline-flex;
    gap: 12px;
    padding: 4px;
`;

export const SwitchButton = styled.button<{ $isActive: boolean }>`
    padding: 4px 24px;
    border-radius: 6px;
    background-color: ${({ $isActive, theme }) => ($isActive ? theme.colors.white() : 'transparent')};
    color: ${({ $isActive, theme }) => ($isActive ? theme.colors.base() : theme.colors.base(0.7))};
`;
