import React from 'react';
import {
    ButtonConnectClaim,
    Description,
    Info,
    MissingClaimWrapper,
    NavLinkToClaim,
    Title,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/Refunds/components/MissingClaim/styles';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getOpenClaimModeratingByMerchantId } from 'src/store/Claims/selectors';
import { getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import { useIntl } from 'react-intl';
import { TShopRouteParams } from '../../../../index';
import { BUSINESS_OFFICE_PATHS } from '../../../../../paths';

const MissingClaim = () => {
    const intl = useIntl();
    const { merchantId } = useParams() as TShopRouteParams;

    const counterpartyId = useSelector(getCurrentCounterpartyId);
    const claimModerating = useSelector(getOpenClaimModeratingByMerchantId(merchantId));

    return (
        <MissingClaimWrapper>
            <Info>
                <Title variant="headline">
                    {intl.formatMessage({
                        defaultMessage: 'Доступно магазинам с подключённой услугой - приём платежей',
                    })}
                </Title>
                <Description variant="regular14">
                    {intl.formatMessage({
                        defaultMessage:
                            'Для того чтобы осуществлять возвраты, Вам необходимо подключить к данному магазину услугу Приём платежей.',
                    })}
                </Description>
            </Info>
            {!claimModerating && (
                <NavLinkToClaim to={BUSINESS_OFFICE_PATHS.claimByMerchant(merchantId, counterpartyId)}>
                    <ButtonConnectClaim>
                        {intl.formatMessage({ defaultMessage: 'Подключить услугу' })}
                    </ButtonConnectClaim>
                </NavLinkToClaim>
            )}
        </MissingClaimWrapper>
    );
};

export default MissingClaim;
