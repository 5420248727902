import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { DocumentSentSuccessfully } from '../../DocumentSentSuccessfully';
import { TTaskFlowCodeWithoutDownload } from '../../../../store/TaskFlow/types';
import { OrderContainer } from '../../../../store/OrderContainer/entity';

export type TProps = {
    variant: TTaskFlowCodeWithoutDownload;
    orderContainers: Array<OrderContainer>;
};

export const DocumentSentSuccessfullyWidget: FC<TProps> = ({ variant, orderContainers }) => {
    const intl = useIntl();
    const theme = useTheme();

    const interval = OrderContainer.intervalBetweenExtremesCreatedAt(orderContainers);

    const orderContainerInterval =
        orderContainers.length > 1
            ? intl.formatMessage(
                  {
                      id: 'период Х',
                      defaultMessage: 'период {intervalValue}',
                  },
                  {
                      intervalValue: interval,
                  },
              )
            : orderContainers[0].formattedCreatedAtToDate();

    const orderContainerNumber =
        orderContainers.length > 1
            ? intl.formatMessage(
                  {
                      id: 'X счетам',
                      defaultMessage: '{count} счетам',
                  },
                  { count: orderContainers.length },
              )
            : intl.formatMessage(
                  {
                      id: 'счёту №123',
                      defaultMessage: 'счету №{merchantOrderIdVisible}',
                  },
                  {
                      merchantOrderIdVisible: orderContainers[0].merchantOrderIdVisible,
                  },
              );

    const PROPS_BY_VARIANT: Record<
        TTaskFlowCodeWithoutDownload,
        { title: string; descriptions: Array<string>; iconColor: string }
    > = {
        mail: {
            title: intl.formatMessage({
                id: 'Запрос на отправку документов сформирован',
                defaultMessage: 'Запрос на отправку документов сформирован',
            }),
            descriptions: [
                intl.formatMessage(
                    {
                        id: `Выбранные документы по счёту №X за Y успешно подготовлены для отправки по почте.`,
                        defaultMessage:
                            'Выбранные документы по {orderContainerNumberValue} за {interval} успешно подготовлены для отправки по почте.',
                    },
                    {
                        orderContainerNumberValue: orderContainerNumber,
                        interval: orderContainerInterval,
                    },
                ),
                intl.formatMessage({
                    id: 'В ближайшее время на указанный вами адрес электронной почты будет направлена дополнительная информация.',
                    defaultMessage:
                        'В ближайшее время на указанный вами адрес электронной почты будет направлена дополнительная информация.',
                }),
            ],
            iconColor: theme.colors.green(),
        },
        edm_non_formalized: {
            title: intl.formatMessage({
                id: 'Запрос отправлен',
                defaultMessage: 'Запрос отправлен',
            }),
            descriptions: [
                intl.formatMessage(
                    {
                        id: 'Выбранные документы по счёту №X за Y успешно подготовлены для отправки по ЭДО.',
                        defaultMessage:
                            'Выбранные документы по {orderContainerNumberValue} за {interval} успешно подготовлены для отправки по ЭДО.',
                    },
                    {
                        orderContainerNumberValue: orderContainerNumber,
                        interval: orderContainerInterval,
                    },
                ),
                intl.formatMessage({
                    id: 'В ближайшее время на указанный вами адрес электронной почты будет направлена дополнительная информация.',
                    defaultMessage:
                        'В ближайшее время на указанный вами адрес электронной почты будет направлена дополнительная информация.',
                }),
            ],
            iconColor: '#4490B0',
        },
        email: {
            title: intl.formatMessage({
                id: 'Документы отправлены',
                defaultMessage: 'Документы отправлены',
            }),
            descriptions: [
                intl.formatMessage({
                    id: 'Документы отправлены на указанные адеса электронной почты',
                    defaultMessage: 'Документы отправлены на указанные адеса электронной почты',
                }),
            ],
            iconColor: '#4490B0',
        },
    };

    return <DocumentSentSuccessfully {...PROPS_BY_VARIANT[variant]} />;
};
