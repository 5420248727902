import { RootState } from '../index';
import { orderContainerAdapter } from './index';

const getOrderContainer = (state: RootState) => state.orderContainer;

const { selectAll } = orderContainerAdapter.getSelectors(getOrderContainer);

export const getOrderContainerList = (state: RootState) => selectAll(state);
export const getOrderContainersByIds = (ids?: Array<string>) => (state: RootState) =>
    getOrderContainerList(state).filter((stateItem) => ids?.includes(stateItem.id));
export const getIsLoadingOrderContainerList = (state: RootState) => getOrderContainer(state).isLoading;
export const getIsInitializedOrderContainerList = (state: RootState) => getOrderContainer(state).isInitialized;
export const getOrderContainerMetaData = (state: RootState) => getOrderContainer(state).metaData;
