import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const ConnectionPaymentWrapper = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    padding: 36px 30px 50px 30px;
    margin-top: 120px;
    background: ${({ theme }) => theme.colors.white()};
    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 36px 20px 40px 20px;
    }
    @media ${({ theme }) => theme.breakpoints.md} {
        position: relative;
        overflow: hidden;
    }
`;

export const Header = styled.div`
    ${({ theme }) => theme.mixins.flexCenter}
    justify-content: space-between;
    margin-bottom: 52px;
`;

export const Title = styled(Typography)`
    @media ${({ theme }) => theme.breakpoints.md} {
        ${({ theme }) => theme.mixins.getTypography('title')}
    }
`;

export const Steps = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.4)};
    width: 80px;
`;
