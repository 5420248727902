import React, { FC } from 'react';
import { Divider } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as S from './styles';
import { Header } from '../../../../../stories/docsTable/Header';
import { OrganizationSelect } from '../../../../../stories/docsTable/OrganizationSelect';
import { SubHeader } from '../../../../../stories/docsTable/SubHeader';
import { Table } from '../../../../../stories/docsTable/Table';
import { DateRangePicker } from '../../../../../stories/docsTable/DateRangePicker';
import { Footer } from '../../../../../stories/docsTable/Footer';
import { SwitcherCountRowsOnPage } from '../../../../../stories/docsTable/SwitcherCountRowsOnPage';
import { useData } from './hooks/useData';
import { ICounterparty } from '../../../../../store/Counterparty/types';
import { Document } from '../../../../../store/NDocument/entity';
import { DocumentTypesRow } from '../../../../../stories/docs/widgets/DocumentTypesRow';
import { DocumentGeneratorSetting } from '../../../../../store/DocumentGeneratorSetting/entity';
import { getDocumentList } from '../../../../../store/NDocument/selectors';
import { useMobile } from '../../../../../hooks/useMedia';
import { useHeadLabel } from './hooks/useHeadLabel';

type TProps = {
    addCounterparty: () => void;
    selectedRowIds?: Array<string>;
    onSelectedRowIdsChange: (value: Array<string>) => void;
    counterparties: Array<ICounterparty>;
    selectedCounterparty?: ICounterparty;
    setSelectedCounterpartyId: (id: string) => void;
    documentGeneratorSettingList: Array<DocumentGeneratorSetting>;
};

const OrderContainerTable: FC<TProps> = ({
    addCounterparty,
    selectedRowIds,
    onSelectedRowIdsChange,
    selectedCounterparty,
    counterparties,
    setSelectedCounterpartyId,
    documentGeneratorSettingList,
}) => {
    const intl = useIntl();
    const documents = useSelector(getDocumentList);
    const isMobile = useMobile();

    const headLabels = useHeadLabel();

    const handleSelectedRowsReset = () => onSelectedRowIdsChange([]);

    const {
        orderContainerList,
        isLoading,
        isInitialized,
        metaData,
        goToNextPage,
        goToPrevPage,
        handlePageSizeChange,
        handleCreatedAtFilterChange,
        createdAtFilter,
        pageSizeOptions,
        pageSize,
    } = useData(selectedCounterparty);

    const counterpartyOptions = counterparties.map((counterpartyItem) => ({
        label: counterpartyItem.name,
        value: counterpartyItem.id,
    }));

    const handleSelectOrganization = (id: string) => {
        const findOrganization = counterparties.find((counterpartyItem) => counterpartyItem.id === id);

        if (selectedCounterparty?.id !== id) {
            onSelectedRowIdsChange([]);
            handleCreatedAtFilterChange(null);
        }

        if (findOrganization) {
            setSelectedCounterpartyId(findOrganization.id);
        }
    };

    const rows = orderContainerList.map((orderContainerItem) => {
        const documentsByOrderContainer = Document.matchByOrderContainerId(orderContainerItem.id, documents);

        return {
            ...orderContainerItem,
            id: orderContainerItem.id,
            merchantOrderIdVisible: orderContainerItem.merchantOrderIdVisible,
            originalAmount: orderContainerItem.formattedOriginalAmount(),
            createdAt: `${intl.formatMessage({
                id: 'от',
                defaultMessage: 'от',
            })} ${orderContainerItem.formattedCreatedAtToDate()}`,
            status: orderContainerItem.labelMapStatus(),
            description: documentsByOrderContainer.length ? (
                <DocumentTypesRow
                    documents={documentsByOrderContainer}
                    documentGeneratorSettingList={documentGeneratorSettingList}
                />
            ) : undefined,
        };
    });

    return (
        <S.Wrapper>
            <Header
                vatNumber={selectedCounterparty?.vatNumber}
                taxRegistrationReasonCode={selectedCounterparty?.taxRegistrationReasonCode}
            >
                <OrganizationSelect
                    onClickAddOrganization={addCounterparty}
                    onSelectOrganization={handleSelectOrganization}
                    value={selectedCounterparty?.id || ''}
                    options={counterpartyOptions}
                />
            </Header>
            <Divider height={isMobile ? 4 : 1} />
            <SubHeader
                countLineSelected={selectedRowIds?.length || 0}
                onReset={selectedRowIds?.length ? handleSelectedRowsReset : undefined}
            >
                <DateRangePicker value={createdAtFilter} onChange={handleCreatedAtFilterChange} />
            </SubHeader>
            <Table
                isLoading={isLoading}
                headLabels={headLabels}
                rows={rows}
                selectedRowIds={selectedRowIds}
                onSelectRows={onSelectedRowIdsChange}
                isInitialized={isInitialized}
            >
                <Footer metaData={metaData} onNextPage={goToNextPage} onPrevPage={goToPrevPage}>
                    <SwitcherCountRowsOnPage
                        options={pageSizeOptions}
                        value={pageSize}
                        onChange={handlePageSizeChange}
                    />
                </Footer>
            </Table>
        </S.Wrapper>
    );
};

export default OrderContainerTable;
