import React, { FC, useState } from 'react';
import {
    ArrowOpenWrapper,
    ButtonClaimValues,
    ClaimModeratingWrapper,
    ClaimsValues,
    IconTariffWrapper,
    ValueStatus,
    ValueTariff,
} from 'src/components/Widgets/ClaimModeratingWidget/styles';
import ClaimColumn from 'src/components/Widgets/ClaimModeratingWidget/components/ClaimColumn';
import { ReactComponent as IconTariff } from 'src/assets/icons/tariff.svg';
import { ReactComponent as ArrowDownIcon } from 'src/assets/icons/arrow-down.svg';
import { IClaim } from 'src/store/Claims/types';
import { ClaimFields, ClaimStatuses, getClaimStatusesLabelsMap } from 'src/store/Claims/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import ClaimFieldsReadOnly from 'src/components/Widgets/ClaimModeratingWidget/components/ClaimFieldsReadOnly';
import { getContractById } from 'src/store/Contracts/selectors';
import { useIntl } from 'react-intl';
import { BUSINESS_OFFICE_PATHS } from '../../../pages/businessOffice/paths';

interface IClaimModeratingWidget {
    claim: IClaim;
}

const ClaimModeratingWidget: FC<IClaimModeratingWidget> = ({ claim }) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const { merchantId } = useParams() as { merchantId: string };
    const currentCounterpartyId = useSelector(getCurrentCounterpartyId);

    const contract = useSelector(getContractById(claim[ClaimFields.merchantContractId]));
    const isCanOpened =
        claim[ClaimFields.status] === ClaimStatuses.moderating || claim[ClaimFields.status] === ClaimStatuses.canceled;

    const claimData = [
        {
            label: intl.formatMessage({ defaultMessage: 'Тариф' }),
            value: (
                <ValueTariff>
                    <IconTariffWrapper>
                        <IconTariff />
                    </IconTariffWrapper>
                    {contract?.title}
                </ValueTariff>
            ),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Статус' }),
            value: (
                <ValueStatus variant="regular14">
                    {getClaimStatusesLabelsMap(intl)[claim[ClaimFields.status]]}
                </ValueStatus>
            ),
        },
    ];

    const onClickButtonValues = () => {
        if (isCanOpened) {
            setIsOpen(!isOpen);
        } else {
            navigate(BUSINESS_OFFICE_PATHS.claimByMerchant(merchantId, currentCounterpartyId));
        }
    };

    return (
        <ClaimModeratingWrapper>
            <ButtonClaimValues onClick={onClickButtonValues} type="button" isOpen={isOpen}>
                <ClaimsValues>
                    {claimData.map((claimField) => (
                        <ClaimColumn key={claimField.label} label={claimField.label} value={claimField.value} />
                    ))}
                </ClaimsValues>
                {isCanOpened && (
                    <ArrowOpenWrapper isOpen={isOpen}>
                        <ArrowDownIcon />
                    </ArrowOpenWrapper>
                )}
            </ButtonClaimValues>
            {isOpen && <ClaimFieldsReadOnly claim={claim} />}
        </ClaimModeratingWrapper>
    );
};

export default ClaimModeratingWidget;
