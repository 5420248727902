import styled from 'styled-components';

export const TabsWrapper = styled.div<{
    $isSkipFirstTab: boolean;
}>`
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey(0.3)};
    display: grid;
    grid-template-columns: repeat(${({ $isSkipFirstTab }) => ($isSkipFirstTab ? '5' : '6')}, auto);
    @media ${({ theme }) => theme.breakpoints.md} {
        grid-column-gap: 54px;
        position: relative;
        width: 100%;
        overflow-x: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }
`;

export const Tab = styled.button<{
    $isActive: boolean;
}>`
    ${({ theme }) => theme.mixins.getTypography('regular14')}
    color: ${({ theme, $isActive }) => ($isActive ? theme.colors.base() : theme.colors.grey())};
    text-align: center;
    border-bottom: ${({ theme, $isActive }) => ($isActive ? `2px solid ${theme.colors.base()}` : `none`)};
    padding-bottom: 14px;
    @media ${({ theme }) => theme.breakpoints.md} {
        white-space: nowrap;
    }
`;
