import dayjs from 'dayjs';
import { assert } from '../../utils/assert';
import { TTask } from './types';
import { TASKFLOW_CODES } from '../TaskFlow/types';
import { DD_MM_YYYY_DATE_FORMAT } from '../../utils/constants';

export class Task {
    readonly id: number;
    private readonly userId?: string;
    private readonly currentPlaceCode: string;
    private readonly taskflowCode: TASKFLOW_CODES;
    readonly data: any;
    private readonly callbackUrl?: string;
    private readonly completed: boolean;
    private readonly availableTransitions: Array<unknown>;
    readonly places: Array<string>;
    private readonly deadline?: string;
    private readonly attempt: number;
    private readonly nextRunDate: string;
    private readonly lock: boolean;
    private readonly createdAt: string;
    private readonly updatedAt: string;
    private readonly sourceUrl?: string;

    private constructor(state: TTask) {
        const {
            id,
            callbackUrl,
            userId,
            places,
            taskflowCode,
            currentPlaceCode,
            sourceUrl,
            attempt,
            completed,
            createdAt,
            lock,
            deadline,
            nextRunDate,
            updatedAt,
            data,
            availableTransitions,
        } = state;

        this.id = id;
        this.callbackUrl = callbackUrl;
        this.userId = userId;
        this.places = places;
        this.taskflowCode = taskflowCode;
        this.currentPlaceCode = currentPlaceCode;
        this.sourceUrl = sourceUrl;
        this.attempt = attempt;
        this.completed = completed;
        this.createdAt = createdAt;
        this.lock = lock;
        this.deadline = deadline;
        this.nextRunDate = nextRunDate;
        this.updatedAt = updatedAt;
        this.data = data;
        this.availableTransitions = availableTransitions;
    }

    countDocuments() {
        return this.data?.invoiceIds?.length || 0;
    }

    createdDateShowFormatted() {
        return dayjs(this.createdAt).format(DD_MM_YYYY_DATE_FORMAT);
    }

    static fromResponse(response: unknown): Task {
        assert(typeof response === 'object' && response !== null);

        assert('id' in response);
        const { id } = response;
        assert(typeof id === 'number');
        assert(id >= 0);

        let userId;
        if ('userId' in response) {
            userId = response.userId;
            assert(typeof userId === 'string');
            assert(!!userId.length);
        }

        assert('currentPlaceCode' in response);
        const { currentPlaceCode } = response;
        assert(typeof currentPlaceCode === 'string');
        assert(!!currentPlaceCode.length);

        assert('taskflowCode' in response);
        const { taskflowCode } = response;
        assert(typeof taskflowCode === 'string');
        assert(!!taskflowCode.length);
        const taskFlowCodeTypified = taskflowCode as TASKFLOW_CODES;

        let callbackUrl;
        if ('callbackUrl' in response) {
            callbackUrl = response.callbackUrl;
            assert(typeof callbackUrl === 'string');
            assert(!!callbackUrl.length);
        }

        assert('completed' in response);
        const { completed } = response;
        assert(typeof completed === 'boolean');

        assert('places' in response);
        const { places } = response;
        assert(Array.isArray(places));
        assert(!!places.length);
        places.forEach((placeItem) => assert(typeof placeItem === 'string'));

        let deadline;
        if ('deadline' in response) {
            deadline = response.deadline;
            assert(typeof deadline === 'string');
            assert(!!deadline.length);
        }

        assert('attempt' in response);
        const { attempt } = response;
        assert(typeof attempt === 'number');
        assert(attempt >= 0);

        assert('nextRunDate' in response);
        const { nextRunDate } = response;
        assert(typeof nextRunDate === 'string');
        assert(!!nextRunDate.length);

        assert('lock' in response);
        const { lock } = response;
        assert(typeof lock === 'boolean');

        assert('createdAt' in response);
        const { createdAt } = response;
        assert(typeof createdAt === 'string');
        assert(!!createdAt.length);

        assert('updatedAt' in response);
        const { updatedAt } = response;
        assert(typeof updatedAt === 'string');
        assert(!!updatedAt.length);

        let sourceUrl;
        if ('sourceUrl' in response) {
            sourceUrl = response.sourceUrl;
            assert(typeof sourceUrl === 'string');
            assert(!!sourceUrl.length);
        }

        assert('availableTransitions' in response);
        const { availableTransitions } = response;
        assert(Array.isArray(availableTransitions));

        assert('data' in response);
        const { data } = response;

        const checkedResponse = {
            id,
            callbackUrl,
            userId,
            places,
            taskflowCode: taskFlowCodeTypified,
            currentPlaceCode,
            sourceUrl,
            attempt,
            completed,
            createdAt,
            lock,
            deadline,
            nextRunDate,
            updatedAt,
            availableTransitions,
            data,
        } satisfies TTask;

        return new Task(checkedResponse);
    }

    static fromMock(): Task {
        return new Task({
            id: 112773,
            currentPlaceCode: 'recipientResponseConfirmationReceived',
            taskflowCode: TASKFLOW_CODES.edm_non_formalized,
            data: {
                fileId: '01903505-4e46-7bae-2525-e34a0bf55bad',
                version: 'utd820_05_01_02_hyphen',
                entityId: 'c0ecf929-25cd-4c91-a47d-77e89390eaac',
                fileName:
                    'ON_NSCHFDOPPR_2BM-7810774608-781001001-201911081248009281371_2BM-9722004963-772201001-202108040132004477310_20240619_D03B7366-38D0-C533-E100-0000C0A8306E.xml',
                function: 'ДОП',
                fromBoxId: 'bd61f3d01d0d48719f888a3cc53bd8e9@diadoc.ru',
                messageId: '88ddc5d6-8da8-4d68-93e7-9dcab72c8412',
                typeNamedId: 'UniversalTransferDocument',
                documentDate: '19.06.2024',
                documentNumber: 'ПП0624-0523',
                counterpartyCode: 'ets',
                invoiceIds: [
                    'fe049603-99e4-4395-999f-cc2c01d4fc06',
                    'ee955e2c-4214-4174-9a1c-237ffa048b44',
                    '80e404d0-44eb-48e8-bf01-9133014407dc',
                ],
            },
            callbackUrl: 'https://ws.invoicebox.ru/ws/internal/printerapi.u?function=onNewDocument',
            completed: false,
            availableTransitions: [
                {
                    name: 'get_outer_docflow_in_processing_after_recipient_confirmation',
                    requiredFields: [],
                },
                {
                    name: 'get_revocation_request_after_confirmation_of_receipt_signature',
                    requiredFields: [],
                },
            ],
            places: [
                'waitingForRecipientSignatureDocumentReceived',
                'senderTitleAttachmentDownloaded',
                'recipientTitleGeneratedAndSent',
                'recipientResponseSignatureReceived',
                'recipientResponseConfirmationReceived',
                'outerDocflowInProcessingReceived',
                'outerDocflowSuccessProcessedReceived',
                'outerDocflowProcessingErrorReceived',
                'revocationRequestReceived',
                'revocationRequestAcceptedReceived',
                'error',
            ],
            attempt: 0,
            nextRunDate: '2024-06-20T09:30:36+00:00',
            lock: false,
            createdAt: '2024-06-20T09:30:36+00:00',
            updatedAt: '2024-06-20T09:41:19+00:00',
        });
    }
}
