import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { TaskRow, TProps as TTaskRowProps } from '../../TaskRow';
import { Task } from '../../../../store/Task/entity';
import { getNoun } from '../../../../utils/helpers';

export type TProps = Pick<TTaskRowProps, 'checked'> & {
    task: Task;
    onClick: (task: Task) => void;
};

export const TaskRowWidget: FC<TProps> = ({ checked, task, onClick }) => {
    const intl = useIntl();

    const countDocuments = task.countDocuments();
    const countDocumentsLabel = `${countDocuments} ${getNoun(
        countDocuments,
        intl.formatMessage({ id: 'документ', defaultMessage: 'документ' }),
        intl.formatMessage({ id: 'документа', defaultMessage: 'документа' }),
        intl.formatMessage({ id: 'документов', defaultMessage: 'документов' }),
    )}`;

    const handleClick = () => {
        onClick(task);
    };

    return (
        <TaskRow
            title={task.id.toString()}
            date={task.createdDateShowFormatted()}
            countDocument={countDocumentsLabel}
            checked={checked}
            onClick={handleClick}
        />
    );
};
