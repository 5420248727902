import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const ContainerSettingsWidget = styled.div`
    width: 100%;
    background: ${({ theme }) => theme.colors.white()};
    box-shadow: ${({ theme }) => theme.decorations.boxShadow.base};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
`;

export const HeaderSettingsWidget = styled.div`
    background-color: ${({ theme }) => theme.colors.lightGrey(0.5)};
    ${({ theme }) => theme.mixins.flexCenter}
    padding: 28px 25px 28px 29px;
    justify-content: space-between;
`;

export const EditSaveButton = styled.button`
    display: flex;
    grid-gap: 9px;
`;

export const Saving = styled(Typography)``;

export const ContentSettingsWidget = styled.div`
    padding-top: 20px;
`;
