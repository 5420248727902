import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { toast } from '@invoicebox/ui';
import { Task } from './entity';
import { createTaskRequest, getTasksRequest, TCreateTaskParams } from './http';
import { setTaskPlace } from '../TaskPlace';
import { TaskPlace } from '../TaskPlace/entity';
import { TTaskPlace } from '../TaskPlace/types';

export const taskAdapter = createEntityAdapter<Task>();

const initialState = taskAdapter.getInitialState({
    isInitialized: false,
    isLoading: false,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

export const createTask = createAsyncThunk('task/create', async (params: TCreateTaskParams) => {
    const { data } = await createTaskRequest(params);

    return data;
});

export const fetchTasks = createAsyncThunk('task/fetchTasks', async (arg, thunkAPI) => {
    const { data } = await getTasksRequest();

    const taskPlaceList = data.extendedData.find((extendItem) => extendItem.entity === 'task-place')?.data;

    if (taskPlaceList) {
        thunkAPI.dispatch(
            setTaskPlace(taskPlaceList.map((taskPlaceItem: TTaskPlace) => TaskPlace.fromResponse(taskPlaceItem))),
        );
    }

    return data;
});

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createTask.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createTask.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            taskAdapter.addOne(state as EntityState<Task>, Task.fromResponse(payload.data));
        });
        builder.addCase(createTask.rejected, (state) => {
            state.isLoading = false;
            toast.error('Произошла ошибка');
        });

        builder.addCase(fetchTasks.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchTasks.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isInitialized = true;
            taskAdapter.setAll(
                state as EntityState<Task>,
                payload.data.map((taskFromResponse) => Task.fromResponse(taskFromResponse)),
            );
        });
        builder.addCase(fetchTasks.rejected, (state) => {
            state.isLoading = false;
            state.isInitialized = true;
            toast.error('Произошла ошибка');
        });
    },
});

export default taskSlice.reducer;
