import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';
import decorImage from './bg.png';

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    background: url(${decorImage});
    height: 756px;
    z-index: 0;

    &:after {
        content: '';
        position: absolute;
        z-index: 0;
        display: block;
        width: 51%;
        height: 100%;
        background-color: ${({ theme }) => theme.colors.white()};
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
        height: auto;
        padding-top: 360px;

        &:after {
            display: none;
        }
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        padding-top: 160px;
    }
`;

export const AuthPageContainer = styled.div`
    position: relative;
    z-index: 2;
    width: 1170px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;

export const Content = styled.div`
    background-color: ${({ theme }) => theme.colors.white()};
    width: 591px;
    padding: 114px 0 50px 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${({ theme }) => theme.breakpoints.xl} {
        padding-right: 38px;
        padding-left: 94px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        padding: 24px 24px 27px 24px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 24px 16px;
    }
`;

export const Note = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.4)};

    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 28px;
    }
`;

export const Title = styled.p`
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    color: ${({ theme }) => theme.colors.base()};
    margin-bottom: 16px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        font-size: 24px;
        line-height: 28px;
    }
`;
