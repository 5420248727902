import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { MerchantForm } from '../../../../../stories/introduce/MerchantForm';
import { useAppDispatch } from '../../../../../store';
import { IntroduceStepContainer } from '../../../../../stories/introduce/IntroduceStepContainer';
import { TMcc } from '../../../../../store/Dictionaries/types';
import useDictionary from '../../../../../hooks/useDictionarty';
import { DICTIONARY_TYPES } from '../../../../../store/Dictionaries/constants';
import { getOptionsFromArray } from '../../../../../utils/helpers/getOptionsFromArray';
import { createMerchantIntroduce } from '../../../../../store/Merchant';
import { TCreateMerchantIntroduceFormOutter } from '../../../../../stories/introduce/MerchantForm/constants';

type TProps = {
    onSkip: () => void;
    goToNextStep: () => void;
    counterpartyId?: string;
};

const CreateMerchantScreen: FC<TProps> = ({ onSkip, goToNextStep, counterpartyId }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const mccList: Array<TMcc> = useDictionary(DICTIONARY_TYPES.mcc)?.data as any;

    const mccOptions = getOptionsFromArray(mccList, 'id', 'name');

    const handleSubmit = (formValues: TCreateMerchantIntroduceFormOutter) => {
        if (counterpartyId) {
            return dispatch(createMerchantIntroduce({ ...formValues, counterpartyId })).then(() => {
                goToNextStep();
            });
        }
    };

    return (
        <IntroduceStepContainer
            title={intl.formatMessage({
                id: 'Сведения о магазине',
                defaultMessage: 'Сведения о магазине',
            })}
            subtitle={intl.formatMessage({
                id: 'Пожалуйста, укажите информацию о точке продаж',
                defaultMessage: 'Пожалуйста, укажите информацию о точке продаж',
            })}
            countSteps={3}
            activeStep={3}
        >
            <MerchantForm mccOptions={mccOptions} onSkip={onSkip} onSubmit={handleSubmit} />
        </IntroduceStepContainer>
    );
};

export default CreateMerchantScreen;
