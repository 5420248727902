import React, { FC } from 'react';
import { BillSummary } from '../../BillSummary';
import { OrderContainer } from '../../../../store/OrderContainer/entity';
import { DocumentGeneratorSetting } from '../../../../store/DocumentGeneratorSetting/entity';
import { Document } from '../../../../store/NDocument/entity';

export type TProps = {
    orderContainer: OrderContainer;
    documents: Array<Document>;
    documentGeneratorSettingList: Array<DocumentGeneratorSetting>;
};

export const BillSummaryWidget: FC<TProps> = ({ orderContainer, documents, documentGeneratorSettingList }) => {
    const formattedDocuments = documents.map((documentItem) => ({
        isDone: true,
        onDownload: documentItem.url ? () => documentItem.download() : undefined,
        label: DocumentGeneratorSetting.matchById(documentItem.type, documentGeneratorSettingList)?.title || '',
    }));

    return (
        <BillSummary
            billId={orderContainer.merchantOrderIdVisible || orderContainer.id}
            documents={formattedDocuments}
        />
    );
};
