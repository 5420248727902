import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`;

export const NoteWrapper = styled.div`
    display: flex;
    gap: 8px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const WrongLabel = styled(Typography)`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.red()};
`;

export const NewCodeCounterLabel = styled(Typography)`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.base(0.5)};
`;

export const SendCodeAgainLabel = styled(Typography)`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.base()};
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
`;
