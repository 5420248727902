import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { OrganizationAutocompleteItem, useMobile } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import * as S from './styles';
import { MAX_LENGTH_VAT_NUMBER } from '../../../../utils/constants';
import { RHFAutocomplete, TProps as TRHFAutocompleteProps } from '../../RHFAutocomplete';
import { TOrganization } from '../../../../store/CounterpartyDetail/types';
import { RHFCountry, TProps as TRHFCountryProps } from '../../RHFCountry';
import { organizationLogic } from '../../../../store/CounterpartyDetail/organizationLogic';
import { vatAutocompleteLoadingComponent } from './components/OrganizationSkeleton';
import useModal from '../../../../hooks/useModal';
import VatNumberAutocompleteOptionsDrawer from './components/VatNumberAutocompleteOptionsDrawer';

export type TProps = Pick<TRHFAutocompleteProps, 'label' | 'placeholder' | 'name'> & {
    options: Array<{ value: string; entity: TOrganization }>;
    isLoadingOptions: boolean;
    onVatNumberChange: (newValue: string, option?: { entity?: any }) => void;
    countrySelectProps: Pick<TRHFCountryProps, 'options' | 'name'>;
    minValidVatNumberLength: number;
    onCountryChange?: (value: string) => void;
};

export const RHFVatNumberAutocomplete: FC<TProps> = ({
    placeholder,
    label,
    options,
    onVatNumberChange,
    isLoadingOptions,
    countrySelectProps,
    name,
    minValidVatNumberLength,
    onCountryChange,
}) => {
    const intl = useIntl();
    const isMobile = useMobile();
    const innerAutocompleteInputRef = useRef<HTMLInputElement | null>(null);
    const {
        isOpen: isOpenOrganizationsDrawer,
        open: openOrganizationsDrawer,
        close: closeOrganizationsDrawer,
    } = useModal(false);
    const [inFocusVatInput, setInFocusVatInput] = useState(false);

    const handleVatNumberFocus = () => setInFocusVatInput(true);
    const handleVatNumberBlur = () => setInFocusVatInput(false);

    useEffect(() => {
        if (!!options.length && inFocusVatInput && isMobile) {
            openOrganizationsDrawer();
            innerAutocompleteInputRef.current?.blur();
        }
    }, [inFocusVatInput, isMobile, openOrganizationsDrawer, options.length]);

    const handleVatNumberChange = useCallback(
        (newValue: string, option?: { entity?: any }) => {
            const vatNumber = newValue.replace(/[^\d]/g, '').slice(0, MAX_LENGTH_VAT_NUMBER);
            onVatNumberChange(vatNumber, option);
        },
        [onVatNumberChange],
    );

    const renderVatAutocompleteItem = ({ entity }: { entity?: any }) => {
        const organization = entity as TOrganization;
        const tags = organizationLogic.getTagsFromOrganization(organization);
        const taxRegistrationReasonCode = organizationLogic.getIsLegalEntrepreneurVatNumberLength(
            organization.vatNumber,
        )
            ? organization.taxRegistrationReasonCode
            : undefined;

        return (
            <OrganizationAutocompleteItem
                name={organization.name || ''}
                vatNumber={organization.vatNumber}
                taxRegistrationReasonCode={taxRegistrationReasonCode}
                taxRegistrationReasonCodeLabel={intl.formatMessage({
                    id: 'КПП:',
                    defaultMessage: 'КПП:',
                })}
                vatNumberLabel={intl.formatMessage({
                    id: 'ИНН:',
                    defaultMessage: 'ИНН:',
                })}
                registrationAddress={organization.registrationAddress || ''}
                tags={tags}
            />
        );
    };

    return (
        <>
            <RHFAutocomplete
                ref={innerAutocompleteInputRef}
                label={label}
                placeholder={placeholder}
                name={name}
                onChange={handleVatNumberChange}
                options={options}
                renderOption={renderVatAutocompleteItem}
                inputPaddingLeft={78}
                isLoading={isLoadingOptions}
                optionsLoader={vatAutocompleteLoadingComponent}
                inputMaxLength={MAX_LENGTH_VAT_NUMBER}
                isInputOnlyNumbers
                onFocus={handleVatNumberFocus}
                onBlur={handleVatNumberBlur}
            >
                <S.CountryFieldWrapper>
                    <RHFCountry
                        options={countrySelectProps.options}
                        name={countrySelectProps.name}
                        positionVertical={16}
                        positionLeft="-20px"
                        positionRight="0"
                        minWidth={isMobile ? '100%' : '350px'}
                        placeholder={intl.formatMessage({
                            id: 'Введите страну регистрации организации/ИП',
                            defaultMessage: 'Введите страну регистрации организации/ИП',
                        })}
                        selectedLabel={intl.formatMessage({
                            id: '(выбрано)',
                            defaultMessage: '(выбрано)',
                        })}
                        onChange={onCountryChange}
                    />
                </S.CountryFieldWrapper>
            </RHFAutocomplete>
            <VatNumberAutocompleteOptionsDrawer
                isOpen={isOpenOrganizationsDrawer}
                onClose={closeOrganizationsDrawer}
                renderOption={renderVatAutocompleteItem}
                options={options}
                placeholder={placeholder}
                onChange={handleVatNumberChange}
                isLoadingOptions={isLoadingOptions}
                minValidVatNumberLength={minValidVatNumberLength}
                name={name}
            />
        </>
    );
};
