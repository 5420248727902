import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const UserWrapper = styled.div`
    padding: 16px;
`;

export const FullName = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Subtitle = styled(Typography)`
    color: #919eab;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.grey500(0.5)};
    margin: 1.5px 0;
`;

export const OrganizationList = styled.div`
    padding: 8px 0;
`;

export const OrganizationWrapper = styled.button<{ $isActive: boolean }>`
    padding: 8px 16px;
    ${({ theme }) => theme.mixins.flexStart}
    gap: 8px;
    width: 100%;
    text-align: left;
    transition: ${({ theme }) => theme.decorations.transition.base};
    position: relative;

    ${({ $isActive }) =>
        $isActive &&
        `&:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        display: block;
        height: 20px;
        width: 1.5px;
        background: #2baa5d;
    }`}

    &:hover {
        background-color: ${({ theme }) => theme.colors.wrongGrey100()};
    }
`;

export const OrganizationAvatarWrapper = styled.div`
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.grey200()};
    flex-shrink: 0;
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;

export const OrganizationData = styled.div`
    padding-left: 4px;
    overflow: hidden;
`;

export const OrganizationName = styled(Typography)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const OrganizationVatNumber = styled(Typography)`
    color: #919eab;
`;

export const OrganizationLabel = styled(Typography)`
    border-radius: 4px;
    background: #00b8d929;
    color: #006c9c;
    padding: 0 4px;
`;

export const ActionButton = styled.button`
    padding: 8px 20px;
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 8px;
    width: 100%;
    transition: ${({ theme }) => theme.decorations.transition.base};

    &:hover {
        background-color: ${({ theme }) => theme.colors.wrongGrey100()};
    }
`;

export const OrganizationTags = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 8px;
    margin-right: 0;
    margin-left: auto;
    flex-shrink: 0;
`;
