import styled from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 24px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        margin-bottom: 20px;
    }

    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-bottom: 0;
        padding: 8px 16px 12px 8px;
    }
`;

export const TitleWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 12px;
`;

export const ArrowBackButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    &:hover {
        opacity: 0.5;
    }
`;

export const ButtonCheckDocumentsStatus = styled.button`
    padding: 6px 12px 6px 16px;
    border-radius: 50px;
    border: 1px solid ${({ theme }) => theme.colors.grey900()};
    color: ${({ theme }) => theme.colors.base()};
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 6px;

    &:hover {
        opacity: 0.8;
    }
`;

export const ArrowWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;
