import React, { FC } from 'react';
import { TOrder } from 'src/store/Order/types';
import { currencyFormatter, dateFormatter } from 'src/utils/formatters';
import colors from 'src/theme/colors';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getIconByStatusAndColor } from '../../../helpers';
import {
    Wrap,
    OrderNumber,
    WrapStatusIcon,
    WrapOrderIcon,
    OrderIcon,
    OrderData,
    OrderContainerLink,
    PriceDate,
} from './styles';
import { BUSINESS_OFFICE_PATHS } from '../../../../../pages/businessOffice/paths';
import { getCurrentCounterpartyId } from '../../../../../store/Counterparty/selectors';

interface IOrdersListProps {
    orders: Array<TOrder>;
}

const OrdersList: FC<IOrdersListProps> = ({ orders }) => {
    const intl = useIntl();
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {orders?.map((order) => {
                const { icon } = getIconByStatusAndColor(order.status, colors);
                return (
                    <OrderContainerLink
                        key={order.id}
                        to={BUSINESS_OFFICE_PATHS.orderContainer(order.orderContainerId, counterpartyId)}
                        state={{
                            isOrderContainer: false,
                        }}
                    >
                        <Wrap>
                            <WrapOrderIcon>
                                <OrderIcon />
                                {icon && <WrapStatusIcon>{icon}</WrapStatusIcon>}
                            </WrapOrderIcon>
                            <OrderNumber variant="regular14">
                                {intl.formatMessage({ defaultMessage: 'Заказ' })} № {order.merchantOrderId}
                            </OrderNumber>
                        </Wrap>
                        <PriceDate>
                            <OrderData variant="regular14">{currencyFormatter(order.amount)}</OrderData>
                            <OrderData variant="regular14">{dateFormatter(order.createdAt)}</OrderData>
                        </PriceDate>
                    </OrderContainerLink>
                );
            })}
        </>
    );
};

export default OrdersList;
