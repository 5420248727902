import http, { ICommonResponse } from 'src/utils/http';
import {
    ICounterparty,
    ICounterpartyBankDetail,
    ICounterpartyBankDetailPayload,
    TCounterpartyCreate,
    TCounterpartyRequestFields,
} from './types';

export const createCounterpartyRequest = (data: TCounterpartyCreate) =>
    http.post<ICommonResponse<ICounterparty>>('billing/api/counterparty/counterparty', data);

export const getCounterpartiesListRequest = () =>
    http.get<ICommonResponse<Array<ICounterparty>>>('filter/api/counterparty/counterparty');

export const updateCounterpartyRequest = async (body: TCounterpartyRequestFields) =>
    http.put<ICommonResponse<ICounterparty>>(`billing/api/counterparty/counterparty/${body.id}`, body);

export const getCounterpartyBankDetailList = () =>
    http.get<ICommonResponse<Array<ICounterpartyBankDetail>>>('/filter/api/counterparty/bank-detail');

export const createBankDetailsRequest = (payload: ICounterpartyBankDetailPayload) =>
    http.post<ICommonResponse<ICounterpartyBankDetail>>('/billing/api/counterparty/bank-detail', payload);
