import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import { TOption } from '@invoicebox/ui/dist/components/form/Select';
import FormProvider from '../../../components/FormProvider';
import * as S from './styles';
import { RHFTextInput } from '../../formFields/RHFTextInput';
import { FORM_FIELD, TCreateMerchantIntroduceFormInner, TCreateMerchantIntroduceFormOutter } from './constants';
import Button from '../../common/Button';
import { useTablet } from '../../../hooks/useMedia';
import { TMcc } from '../../../store/Dictionaries/types';
import { RHFSelect } from '../../formFields/RHFSelect';
import { MERCHANT_SCHEMA } from './validation';

const DEFAULT_VALUES = {
    [FORM_FIELD.website]: '',
    [FORM_FIELD.name]: '',
    [FORM_FIELD.mccId]: undefined,
    [FORM_FIELD.description]: '',
};

export type TProps = {
    onSubmit: (formValues: TCreateMerchantIntroduceFormOutter) => Promise<void> | undefined;
    onSkip: () => void;
    mccOptions: Array<TOption<TMcc['id']>>;
};

export const MerchantForm: FC<TProps> = ({ onSubmit, onSkip, mccOptions }) => {
    const intl = useIntl();
    const isTablet = useTablet();

    const methods = useForm<TCreateMerchantIntroduceFormInner>({
        defaultValues: DEFAULT_VALUES,
        mode: 'all',
        resolver: yupResolver(MERCHANT_SCHEMA),
    });

    const {
        handleSubmit,
        formState: { isValid, isSubmitting },
    } = methods;

    const submit = (formValues: TCreateMerchantIntroduceFormInner) =>
        onSubmit({ ...formValues, [FORM_FIELD.mccId]: formValues.mccId as number });

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
            <S.Wrapper>
                <S.RowFields>
                    <RHFTextInput
                        name={FORM_FIELD.website}
                        label={intl.formatMessage({ id: 'Сайт магазина', defaultMessage: 'Сайт магазина' })}
                    />
                    <RHFTextInput
                        name={FORM_FIELD.name}
                        label={intl.formatMessage({
                            id: 'Наименование магазина',
                            defaultMessage: 'Наименование магазина',
                        })}
                    />
                </S.RowFields>
                <RHFSelect
                    name={FORM_FIELD.mccId}
                    label={intl.formatMessage({ id: 'Категория', defaultMessage: 'Категория' })}
                    options={mccOptions}
                />
                <RHFTextInput
                    name={FORM_FIELD.description}
                    label={intl.formatMessage({ id: 'Описание', defaultMessage: 'Описание' })}
                />
                <S.Notice variant="captionRegular">
                    {intl.formatMessage({
                        id: 'Опишите вид деятельности, какие товары или услуги вы планируете продавать',
                        defaultMessage: 'Опишите вид деятельности, какие товары или услуги вы планируете продавать',
                    })}
                </S.Notice>
                <S.Buttons>
                    <Button size="large" type="secondary" onClick={onSkip} fullWidth={isTablet}>
                        {intl.formatMessage({ id: 'Пропустить', defaultMessage: 'Пропустить' })}
                    </Button>
                    <Button
                        htmlType="submit"
                        size="large"
                        disabled={!isValid}
                        fullWidth={isTablet}
                        isLoading={isSubmitting}
                    >
                        {intl.formatMessage({ id: 'Продолжить', defaultMessage: 'Продолжить' })}
                    </Button>
                </S.Buttons>
            </S.Wrapper>
        </FormProvider>
    );
};
