import styled from 'styled-components';

export const Wrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 16px;

    @media ${({ theme }) => theme.breakpoints.md} {
        justify-content: center;
    }
`;
