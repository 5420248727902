import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from '../../../components/FormProvider';
import * as S from '../AuthByLogin/styles';
import Button from '../../common/Button';
import { phoneAuthSchema } from './validation';
import { RHFPhoneInput } from '../../formFields/RHFPhoneInput';
import { FORM_FIELD, TAuthPhoneForm } from './constants';

export type TProps = {
    onSubmit: (phone: string) => Promise<void>;
};

export const AuthByPhone: FC<TProps> = ({ onSubmit }) => {
    const intl = useIntl();

    const defaultValues = {
        phone: '',
    };

    const methods = useForm<TAuthPhoneForm>({
        defaultValues,
        resolver: yupResolver(phoneAuthSchema),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: { isValid, isSubmitting },
    } = methods;

    const submit = (formValues: TAuthPhoneForm) => onSubmit(formValues.phone);

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
            <S.ContentWrapper>
                <S.InputsWrapper>
                    <RHFPhoneInput
                        label={intl.formatMessage({
                            id: 'Номер телефона',
                            defaultMessage: 'Номер телефона',
                        })}
                        name={FORM_FIELD.phone}
                        size="M"
                        pureInputProps={{ autoFocus: true }}
                    />
                </S.InputsWrapper>

                <Button size="large" disabled={!isValid} htmlType="submit" fullWidth isLoading={isSubmitting}>
                    {intl.formatMessage({
                        id: 'Отправить код',
                        defaultMessage: 'Отправить код',
                    })}
                </Button>
            </S.ContentWrapper>
        </FormProvider>
    );
};
