import styled from 'styled-components';

export const Wrapper = styled.button`
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: ${({ theme }) => theme.colors.base(0.7)};
`;

export const ArrowWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    padding: 2px;
`;

export const Options = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Option = styled.button`
    padding: 8px;
`;
