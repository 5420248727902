import React, { FC, ReactNode, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Wrapper } from './styles';
import { TAuthTypeFlow } from '../../constants';
import FindOrganizationScreen from './components/FindOrganizationScreen';
import { TOrganization } from '../../../store/CounterpartyDetail/types';
import CreateUserNameScreen from './components/CreateUserNameScreen';
import { RUSCountryCode } from '../../../store/Country/types';
import CreateCounterpartyScreen from './components/CreateCounterpartyScreen';
import { IntroduceBuyerSuccess } from '../../../stories/introduce/IntroduceBuyerSuccess';
import CreateMerchantScreen from './components/CreateMerchantScreen';
import { ICounterparty } from '../../../store/Counterparty/types';
import { BUSINESS_OFFICE_PATHS } from '../paths';

const Introduce: FC = () => {
    const navigate = useNavigate();
    const { authTypeFlow } = useParams() as { authTypeFlow?: TAuthTypeFlow };

    const authTypeFlowName = authTypeFlow || 'default';

    const [step, setStep] = useState(0);
    const [organizationInitialValue, setOrganizationInitialValue] = useState<Partial<TOrganization>>();
    const [countryId, setCountryId] = useState<string>(RUSCountryCode);
    const [counterparty, setCounterparty] = useState<ICounterparty>();

    const skipIntroduce = () => {
        navigate(BUSINESS_OFFICE_PATHS.dashboard());
    };

    const goToNextStep = () => {
        if (INTRODUCE_STEPS[authTypeFlowName][step + 1]) {
            setStep(step + 1);
        } else {
            skipIntroduce();
        }
    };

    const createUserNameStep = <CreateUserNameScreen goToNextStep={goToNextStep} />;
    const findOrganizationStep = (
        <FindOrganizationScreen
            goToNextStep={goToNextStep}
            setOrganizationInitialValue={setOrganizationInitialValue}
            setCountryId={setCountryId}
            onSkip={skipIntroduce}
        />
    );
    const getCounterpartyFormScreen = (countSteps: number) => (
        <CreateCounterpartyScreen
            defaultValues={{
                countryId,
                ...organizationInitialValue,
            }}
            onSkip={skipIntroduce}
            goToNextStep={goToNextStep}
            onCounterpartySet={setCounterparty}
            countSteps={countSteps}
        />
    );

    const INTRODUCE_STEPS: Record<TAuthTypeFlow | 'default', Array<ReactNode>> = {
        default: [createUserNameStep],
        buyer: [
            createUserNameStep,
            findOrganizationStep,
            getCounterpartyFormScreen(2),
            <IntroduceBuyerSuccess organizationName={counterparty?.name || ''} onClose={skipIntroduce} />,
        ],
        merchant: [
            createUserNameStep,
            findOrganizationStep,
            getCounterpartyFormScreen(3),
            <CreateMerchantScreen
                onSkip={skipIntroduce}
                goToNextStep={goToNextStep}
                counterpartyId={counterparty?.id}
            />,
        ],
    };

    return <Wrapper>{INTRODUCE_STEPS[authTypeFlowName][step]}</Wrapper>;
};

export default Introduce;
