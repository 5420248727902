import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Label = styled(Typography)``;

export const NumberWrapper = styled.div`
    padding: 4px 8px;

    ${({ theme }) => theme.mixins.flexCenterCenter};
    border-radius: 6px;
`;

export const Wrapper = styled.div<{ $isActive: boolean }>`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 6px;

    ${NumberWrapper} {
        background-color: ${({ $isActive }) => ($isActive ? '#6590fd' : '#6590FD29')};
        color: ${({ $isActive, theme }) => ($isActive ? theme.colors.white() : '#6590fd')};
    }

    ${Label} {
        color: ${({ $isActive, theme }) => theme.colors.base($isActive ? 1 : 0.5)};
    }
`;
