import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import { INVOICE_IDS_FIELD_NAME } from '../../../../../store/TaskFlow/types';
import FormProvider from '../../../../../components/FormProvider';
import { FieldsBySchema } from '../../../../../stories/docs/FieldsBySchema';
import * as S from './styles';
import { TaskFlow } from '../../../../../store/TaskFlow/entity';
import { getReceiveDocumentSchema } from './validation';
import { useAppDispatch } from '../../../../../store';
import { createTask } from '../../../../../store/Task';

type TProps = {
    taskFlow: TaskFlow;
    orderContainerIds: Array<string>;
    onBack: () => void;
    counterpartyId: string;
    afterSubmit: () => void;
};

const FormReceiveDocuments: FC<TProps> = ({ taskFlow, orderContainerIds, onBack, counterpartyId, afterSubmit }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const defaultValues = {
        [INVOICE_IDS_FIELD_NAME]: [orderContainerIds],
    };

    const methods = useForm({
        defaultValues,
        resolver: yupResolver(getReceiveDocumentSchema(taskFlow.requiredFields)),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: { isValid, isSubmitting },
    } = methods;

    const submit = (formValues: { [key: string]: any }) =>
        dispatch(
            createTask({
                counterpartyId,
                taskflowCode: taskFlow.taskflowCode,
                data: formValues,
            }),
        )
            .unwrap()
            .then((data) => {
                if (data.data.id) {
                    afterSubmit();
                }
            });

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
            <S.InputsWrapper>
                <FieldsBySchema fields={taskFlow.requiredFields} ignoreFields={[INVOICE_IDS_FIELD_NAME]} />
            </S.InputsWrapper>
            <S.Buttons>
                <S.FormButton htmlType="button" type="secondary" onClick={onBack}>
                    {intl.formatMessage({
                        id: 'e3dEpO',
                        defaultMessage: 'Назад',
                    })}
                </S.FormButton>
                <S.FormButton htmlType="submit" disabled={!isValid} isLoading={isSubmitting}>
                    {intl.formatMessage({
                        id: 'Подтвердить и отправить',
                        defaultMessage: 'Подтвердить и отправить',
                    })}
                </S.FormButton>
            </S.Buttons>
        </FormProvider>
    );
};

export default FormReceiveDocuments;
