import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { IMerchant, IMerchantRequestFields, TIntroduceCreateMerchant } from 'src/store/Merchant/types';
import { thunkHandler } from 'src/utils/helpers';
import {
    createMerchantRequest,
    getMerchantsListRequest,
    setFileMerchantRequest,
    updateMerchantRequest,
} from 'src/store/Merchant/http';
import createAsyncThunkWithErrorHandler from 'src/utils/helpers/createAsyncThunkWithErrorHandler';
import { TCommonListThunkResponse } from 'src/store/OrderRefunds/types';
import { ICommonListRequest } from 'src/utils/http';
import { TOrder } from 'src/store/Order/types';
import { setContracts } from 'src/store/Contracts';

export const createMerchant = createAsyncThunkWithErrorHandler(
    'merchant/merchantCreate',
    async (payload: IMerchantRequestFields) => {
        const data: { data: any } = await createMerchantRequest(payload).then((res) => res.data);
        return data;
    },
);

export const createMerchantIntroduce = createAsyncThunkWithErrorHandler(
    'merchant/createMerchantIntroduce',
    async (payload: TIntroduceCreateMerchant) => {
        const data: { data: any } = await createMerchantRequest(payload).then((res) => res.data);
        return data;
    },
);

export const updateMerchant = createAsyncThunkWithErrorHandler('merchant/merchantUpdate', async (params: IMerchant) => {
    const {
        data: { data },
    } = await updateMerchantRequest(params);
    return data;
});

export const setFileMerchant = createAsyncThunkWithErrorHandler(
    'merchant/merchantSetFile',
    async (data: any, thunkAPI) => thunkHandler(setFileMerchantRequest(data), thunkAPI),
);

export const fetchMerchantsList = createAsyncThunkWithErrorHandler<
    TCommonListThunkResponse<IMerchant>,
    ICommonListRequest<IMerchant>
    // eslint-disable-next-line default-param-last
>('merchant/getAll', async (params = {}, thunkAPI) => {
    const {
        data: { data, extendedData, metaData },
    } = await getMerchantsListRequest({ ...params, _extend: ['merchant-contract'] });
    const contracts = extendedData[0].data as TOrder[];
    thunkAPI.dispatch(setContracts(contracts));
    return { data, metaData };
});

interface IInitialState {
    loading: boolean;
    selectedId: string | null;
}

export const merchantAdapter = createEntityAdapter<IMerchant>();

const initialState = merchantAdapter.getInitialState<IInitialState>({
    loading: false,
    selectedId: null,
});

const Merchant = createSlice({
    name: 'merchant',
    initialState,
    reducers: {
        setSelectedMerchantId(state, action) {
            state.selectedId = action.payload;
        },
        clearMerchants(state) {
            merchantAdapter.removeAll(state);
        },
    },
    extraReducers: (builder) => {
        // createMerchant
        builder.addCase(createMerchantIntroduce.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createMerchantIntroduce.fulfilled, (state, { payload }) => {
            merchantAdapter.addOne(state, payload.data);
            state.loading = false;
        });
        builder.addCase(createMerchantIntroduce.rejected, (state) => {
            state.loading = false;
        });
        // createMerchant
        builder.addCase(createMerchant.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createMerchant.fulfilled, (state, { payload }) => {
            merchantAdapter.addOne(state, payload.data);
            state.loading = false;
        });
        builder.addCase(createMerchant.rejected, (state) => {
            state.loading = false;
        });
        // updateMerchant
        builder.addCase(updateMerchant.fulfilled, (state, action) => {
            merchantAdapter.upsertOne(state, action.meta.arg);
        });
        builder.addCase(fetchMerchantsList.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchMerchantsList.fulfilled, (state, { payload: { data } }) => {
            state.loading = false;
            merchantAdapter.setAll(state, data);
        });
        builder.addCase(fetchMerchantsList.rejected, (state) => {
            state.loading = false;
        });
    },
});

export const { clearMerchants, setSelectedMerchantId } = Merchant.actions;

export default Merchant.reducer;
