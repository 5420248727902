import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { DocsAuthRouter, DocsNotAuthRouter } from './routes';
import { getUser, getUserName } from '../store/Auth/selectors';
import { Footer } from '../stories/common/Footer';
import { Header } from '../stories/common/Header';
import { DocsHeaderAction } from '../stories/common/DocsHeaderAction';
import { logout } from '../store/Auth';
import { useAppDispatch } from '../store';
import { DOCS_PATHS } from './docs/paths';
import { useMobile } from '../hooks/useMedia';

const DocsRoutes = () => {
    const intl = useIntl();
    const dispatch = useAppDispatch();
    const isMobile = useMobile();

    const user = useSelector(getUser);
    const userName = useSelector(getUserName);

    const isAuth = !!user;

    const logoutHandler = async () => {
        dispatch(logout());
    };

    const headerMenuItems = useMemo(
        () => [
            {
                label: intl.formatMessage({ id: 'Документы', defaultMessage: 'Документы' }),
                href: DOCS_PATHS.dashboard,
            },
        ],
        [intl],
    );

    const menuLinks = useMemo(() => (isAuth ? headerMenuItems : undefined), [headerMenuItems, isAuth]);

    return (
        <Wrapper $isAuth={isAuth}>
            <HeaderWithContent>
                <Header
                    userActions={
                        <DocsHeaderAction
                            onLogout={logoutHandler}
                            menuLinks={menuLinks}
                            userName={isAuth ? userName || 'Без имени' : null}
                        />
                    }
                    menuLinks={menuLinks}
                />
                {isAuth ? <DocsAuthRouter /> : <DocsNotAuthRouter />}
            </HeaderWithContent>
            {!(isMobile && isAuth) && <Footer />}
        </Wrapper>
    );
};

const Wrapper = styled.div<{ $isAuth: boolean }>`
    background-color: ${({ $isAuth, theme }) => ($isAuth ? '#f5f6fa' : theme.colors.white())};
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

export const HeaderWithContent = styled.div`
    @media ${({ theme }) => theme.breakpoints.sm} {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
`;

export default DocsRoutes;
