import React, { FC, ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@invoicebox/ui';
import { AuthTypeSwitcher, TAuthType } from '../AuthTypeSwitcher';
import { AuthByPhone } from '../AuthByPhone';
import { AuthByLogin } from '../AuthByLogin';
import * as S from './styles';
import { offerInvoiceBoxLink, privacyInvoiceBoxLink } from '../../../utils/constants';
import { ReactComponent as InvoiceboxIdIcon } from './assets/invoicebox-id.svg';
import { TAuthByLoginForm } from '../AuthByLogin/constants';

type TProps = {
    onByLoginSubmit: (formValues: TAuthByLoginForm) => Promise<any>;
    onByPhoneSubmit: (phone: string) => Promise<void>;
};

export const InitialScreen: FC<TProps> = ({ onByLoginSubmit, onByPhoneSubmit }) => {
    const intl = useIntl();
    const [authType, setAuthType] = useState<TAuthType>('phone');

    const FORM_MAP: Record<TAuthType, ReactNode> = {
        phone: <AuthByPhone onSubmit={onByPhoneSubmit} />,
        login: <AuthByLogin onSubmit={onByLoginSubmit} />,
    };

    return (
        <S.Wrapper>
            <S.QRStackWrapper type={authType} />
            <S.AuthContentWrapper>
                <div>
                    <InvoiceboxIdIcon />
                    <S.AuthFormWrapper>
                        <Typography variant="headline3">
                            {intl.formatMessage({
                                id: 'Вход в «Инвойсбокс»',
                                defaultMessage: 'Вход в «Инвойсбокс»',
                            })}
                        </Typography>
                        <AuthTypeSwitcher value={authType} onChange={setAuthType} />
                        {FORM_MAP[authType]}
                    </S.AuthFormWrapper>
                </div>

                <S.NoticeWrapper>
                    <Typography variant="captionRegular">
                        {intl.formatMessage({
                            id: 'Продолжая, вы соглашаетесь с условиями',
                            defaultMessage: 'Продолжая, вы соглашаетесь с условиями',
                        })}{' '}
                        <S.NoticeLink target="_blank" href={offerInvoiceBoxLink}>
                            {intl.formatMessage({
                                id: 'Оферты',
                                defaultMessage: 'Оферты',
                            })}
                        </S.NoticeLink>{' '}
                        {intl.formatMessage({
                            id: 'и',
                            defaultMessage: 'и',
                        })}{' '}
                        <S.NoticeLink target="_blank" href={privacyInvoiceBoxLink}>
                            {intl.formatMessage({
                                id: 'Политикой обработки персональных данных',
                                defaultMessage: 'Политикой обработки персональных данных',
                            })}
                        </S.NoticeLink>
                    </Typography>
                    <Typography variant="captionRegular">
                        <S.NoticeLink target="_blank" href={privacyInvoiceBoxLink}>
                            {intl.formatMessage({
                                id: 'Передаваемые данные',
                                defaultMessage: 'Передаваемые данные',
                            })}
                        </S.NoticeLink>
                    </Typography>
                </S.NoticeWrapper>
            </S.AuthContentWrapper>
        </S.Wrapper>
    );
};
