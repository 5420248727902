import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { SettingsForm } from 'src/pages/businessOffice/Shop/ShopTabsTables/Settings/styles';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';
import { IMcc, IMerchant } from 'src/store/Merchant/types';

import FormField, { FormFieldTypeEnum } from 'src/components/FormField';
import { useAppDispatch } from 'src/store';
import { updateMerchant } from 'src/store/Merchant';
import { isEqualObjects } from 'src/utils/helpers';
import { removePlusAndGapsToPhone } from 'src/utils/formatters';
import { useIntl } from 'react-intl';

interface IGeneralForm {
    formId: string;
    setIsEditSettings: (arg: boolean) => void;
    merchant: IMerchant;
    mccList: IMcc[];
    toggleSaving: () => void;
}

const GeneralForm: FC<IGeneralForm> = ({ toggleSaving, mccList, formId, setIsEditSettings, merchant }) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const onSubmit = async (formMerchant: IMerchant) => {
        const beforeSubmitMerchant = {
            ...formMerchant,
            [MERCHANT_FIELDS.phone]: formMerchant[MERCHANT_FIELDS.phone]
                ? removePlusAndGapsToPhone(formMerchant[MERCHANT_FIELDS.phone])
                : '',
        };

        if (!isEqualObjects(beforeSubmitMerchant, merchant)) {
            toggleSaving();
            const data = await dispatch(updateMerchant(beforeSubmitMerchant));
            // @ts-ignore
            if (!data?.error && data?.payload) {
                setIsEditSettings(false);
            }
            toggleSaving();
        } else {
            setIsEditSettings(false);
        }
    };

    return (
        <Form onSubmit={onSubmit} initialValues={merchant}>
            {({ handleSubmit }) => (
                <SettingsForm onSubmit={handleSubmit} id={formId}>
                    <FormField
                        name={MERCHANT_FIELDS.name}
                        label={intl.formatMessage({ defaultMessage: 'Наименование' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите название' })}
                    />
                    <FormField
                        name={MERCHANT_FIELDS.description}
                        label={intl.formatMessage({ defaultMessage: 'Описание' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите описание' })}
                    />
                    <FormField
                        name={MERCHANT_FIELDS.website}
                        label={intl.formatMessage({ defaultMessage: 'Адрес сайта' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите адрес сайта' })}
                    />
                    <FormField
                        autoFocus
                        type="tel"
                        label={intl.formatMessage({ defaultMessage: 'Телефон' })}
                        name="phone"
                        mask="+7 999 999 99 99"
                        placeholder="+7 XXX XXX XX XX"
                    />
                    <FormField
                        name={MERCHANT_FIELDS.email}
                        label="Email"
                        placeholder={intl.formatMessage({ defaultMessage: 'Электронная почта' })}
                    />
                    <FormField
                        fieldType={FormFieldTypeEnum.select}
                        options={mccList.map(({ id, name }) => ({ label: name, value: id }))}
                        name={MERCHANT_FIELDS.mccId}
                        label={intl.formatMessage({ defaultMessage: 'Категория' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Выберите' })}
                    />
                </SettingsForm>
            )}
        </Form>
    );
};

export default GeneralForm;
