import React, { FC } from 'react';
import { isEmpty, map } from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { getProcessableOrderStatuses } from 'src/store/Order/constants';
import { currencyFormatter, dateFormatter } from 'src/utils/formatters';
import colors from 'src/theme/colors';
import { getIconByStatusAndColor } from 'src/components/Widgets/helpers';
import { TOrder, typeOrder } from 'src/store/Order/types';
import { useMobile } from 'src/hooks/useMedia';
import { useSelector } from 'react-redux';
import { getCurrentMerchantId } from 'src/store/Merchant/selectors';
import { getRefundIconByStatusAndColor } from 'src/utils/helpers';
import SkeletonOrderList from 'src/components/MerchantOrdersList/components/SkeletonOrderList/index';
import { useIntl } from 'react-intl';
import { groupOrdersListByDate } from '../../helpers';
import {
    Amount,
    Empty,
    InnerContent,
    InnerNumber,
    InnerText,
    InnerTextLeft,
    MobileDescriptionWrapper,
    OrderIcon,
    RefundIcon,
    Status,
    TableRow,
    TitleDate,
    WrapDate,
    WrapOrderIcon,
    WrapStatusIcon,
} from './styles';
import { getCurrentCounterpartyId } from '../../../../store/Counterparty/selectors';
import { BUSINESS_OFFICE_PATHS } from '../../../../pages/businessOffice/paths';

dayjs.locale('ru');

interface IOrdersMerchantList {
    orders: TOrder[];
    orderType?: typeOrder;
    isLoading?: boolean;
}

const OrdersMerchantList: FC<IOrdersMerchantList> = ({ isLoading = false, orders, orderType = typeOrder.ORDER }) => {
    const intl = useIntl();

    const labelEmptyItems = {
        [typeOrder.ORDER]: intl.formatMessage({ defaultMessage: 'Нет заказов' }),
        [typeOrder.ORDER_CONTAINER]: intl.formatMessage({ defaultMessage: 'Нет счетов' }),
        [typeOrder.ORDER_REFUND]: intl.formatMessage({ defaultMessage: 'Нет возвратов' }),
    };

    const titleTypeOrder = {
        [typeOrder.ORDER]: intl.formatMessage({ defaultMessage: 'Заказ' }),
        [typeOrder.ORDER_CONTAINER]: intl.formatMessage({ defaultMessage: 'Счет' }),
        [typeOrder.ORDER_REFUND]: intl.formatMessage({ defaultMessage: 'Возврат' }),
    };

    const groupOrdersList = groupOrdersListByDate(orders);
    const today = dayjs(new Date()).format('D MMMM YYYY');
    const orderTitle = titleTypeOrder[orderType];
    const isMobile = useMobile();

    const merchantId = useSelector(getCurrentMerchantId);
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const getStatusColorAndIcon =
        orderType === typeOrder.ORDER_REFUND ? getRefundIconByStatusAndColor : getIconByStatusAndColor;
    const MainIcon = orderType === typeOrder.ORDER_REFUND ? RefundIcon : OrderIcon;

    if (!isLoading) {
        return (
            <>
                {!isEmpty(groupOrdersList) && (
                    <InnerContent>
                        {map(groupOrdersList, ({ group, children }) => {
                            const orderDate = group;
                            const orderToday = `${intl.formatMessage({ defaultMessage: 'Сегодня' })}, ${today
                                .split(' ')
                                .slice(0, 2)
                                .join(' ')}`;

                            return (
                                <div key={group}>
                                    <WrapDate>
                                        <TitleDate variant="regular12">
                                            {orderDate === today ? orderToday : orderDate}
                                        </TitleDate>
                                    </WrapDate>
                                    {map(
                                        children,
                                        ({
                                            id,
                                            merchantOrderId,
                                            orderContainerId,
                                            status,
                                            createdAt,
                                            amount,
                                            customer,
                                        }) => {
                                            const { icon, color } = getStatusColorAndIcon(status, colors);

                                            const path =
                                                orderType === typeOrder.ORDER_REFUND
                                                    ? BUSINESS_OFFICE_PATHS.shop(counterpartyId).refundDetail(
                                                          merchantId || '',
                                                          id,
                                                      )
                                                    : BUSINESS_OFFICE_PATHS.orderContainer(
                                                          orderContainerId,
                                                          counterpartyId,
                                                      );
                                            const merchantOrderIdTitle =
                                                orderType !== typeOrder.ORDER_REFUND ? `№ ${merchantOrderId}` : '';
                                            const title = !isMobile ? orderTitle : '';
                                            const date =
                                                !isMobile || orderType === typeOrder.ORDER_REFUND
                                                    ? `${intl.formatMessage({ defaultMessage: 'от' })} ${dateFormatter(
                                                          createdAt,
                                                      )}`
                                                    : '';
                                            const info = `${title} ${merchantOrderIdTitle} ${date}`;

                                            return (
                                                <TableRow
                                                    key={id}
                                                    to={path}
                                                    state={{
                                                        isOrderContainer: orderType !== typeOrder.ORDER,
                                                    }}
                                                >
                                                    <InnerTextLeft>
                                                        <WrapOrderIcon>
                                                            <MainIcon />
                                                            {icon && <WrapStatusIcon>{icon}</WrapStatusIcon>}
                                                        </WrapOrderIcon>
                                                        <InnerText variant="regular14">
                                                            {customer?.name
                                                                ? customer.name
                                                                : intl.formatMessage({
                                                                      defaultMessage: 'Без плательщика',
                                                                  })}
                                                        </InnerText>
                                                    </InnerTextLeft>
                                                    <Amount variant="regular14">{currencyFormatter(amount)}</Amount>
                                                    <MobileDescriptionWrapper>
                                                        <InnerNumber variant="regular14">{info}</InnerNumber>
                                                        <Status variant="regular14" color={color}>
                                                            {getProcessableOrderStatuses(intl)[status]}
                                                        </Status>
                                                    </MobileDescriptionWrapper>
                                                </TableRow>
                                            );
                                        },
                                    )}
                                </div>
                            );
                        })}
                    </InnerContent>
                )}
                {isEmpty(groupOrdersList) && <Empty variant="subheading">{labelEmptyItems[orderType]}</Empty>}
            </>
        );
    }

    return <SkeletonOrderList />;
};

export default OrdersMerchantList;
