import React, { FC } from 'react';
import {
    ModalDescription,
    ModalNote,
    ModalSuccessRefundWrapper,
    ModalTitle,
    RefundsNavLink,
} from 'src/pages/businessOffice/Refund/components/ModalSuccessRefund/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { BUSINESS_OFFICE_PATHS } from '../../../paths';
import { getCurrentCounterpartyId } from '../../../../../store/Counterparty/selectors';

interface IModalSuccessRefund {
    onClose: () => void;
    isOpen: boolean;
    merchantId: string;
}

const ModalSuccessRefund: FC<IModalSuccessRefund> = ({ merchantId, onClose, isOpen }) => {
    const intl = useIntl();
    const { orderContainerId } = useParams() as { orderContainerId: string };
    const navigate = useNavigate();
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const onCloseModal = () => {
        onClose();
        navigate(BUSINESS_OFFICE_PATHS.orderContainer(orderContainerId, counterpartyId));
    };

    return (
        <ModalSuccessRefundWrapper closeModal={onCloseModal} isOpen={isOpen}>
            <ModalTitle variant="title">{intl.formatMessage({ defaultMessage: 'Возврат оформлен!' })}</ModalTitle>
            <ModalDescription variant="regular14">
                {intl.formatMessage({ defaultMessage: 'В ближайшее время клиент получит SMS уведомление' })}
            </ModalDescription>
            <ModalNote variant="regular12">
                {intl.formatMessage({ defaultMessage: 'Повторно найти возврат можно в' })}{' '}
                <RefundsNavLink to={BUSINESS_OFFICE_PATHS.shop(counterpartyId).refunds(merchantId)}>
                    {intl.formatMessage({ defaultMessage: 'списке возвратов' })}
                </RefundsNavLink>
            </ModalNote>
        </ModalSuccessRefundWrapper>
    );
};

export default ModalSuccessRefund;
