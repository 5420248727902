export enum FORM_FIELD {
    vatNumber = 'vatNumber',
    countryId = 'countryId',
    taxRegistrationReasonCode = 'taxRegistrationReasonCode',
    legalEntityTypeId = 'legalEntityTypeId',
    registrationNumber = 'registrationNumber',
    name = 'name',
    nameI18n = 'nameI18n',
    registrationAddress = 'registrationAddress',
}

export type TCreateCounterpartyIntroduceForm = {
    [FORM_FIELD.vatNumber]: string;
    [FORM_FIELD.countryId]: string;
    [FORM_FIELD.taxRegistrationReasonCode]?: string;
    [FORM_FIELD.legalEntityTypeId]?: number;
    [FORM_FIELD.registrationNumber]: string;
    [FORM_FIELD.name]: string;
    [FORM_FIELD.nameI18n]: string;
    [FORM_FIELD.registrationAddress]: string;
};
