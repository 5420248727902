import React, { FC, InputHTMLAttributes } from 'react';
import * as S from './styles';
import { TVariant } from './styles';

type TRadioProps = InputHTMLAttributes<HTMLInputElement> & {
    checked: boolean;
    variant?: TVariant;
};

const Radio: FC<TRadioProps> = ({ checked, variant = 'medium', ...props }) => (
    <S.RadioContainer variant={variant}>
        <S.HiddenInput checked={checked} {...props} type="radio" />
        <S.StyledRadio checked={checked}>
            <S.RadioIcon />
        </S.StyledRadio>
    </S.RadioContainer>
);

export default Radio;
