import React, { FC, ReactNode } from 'react';
import { Arrow } from '@invoicebox/ui';
import * as S from './styles';

export type TProps = {
    children: ReactNode;
    onBack: () => void;
    height?: number;
};

export const AuthStepContainer: FC<TProps> = ({ children, onBack, height }) => (
    <S.Wrapper $height={height}>
        <div>
            <S.ButtonBack type="button" onClick={onBack}>
                <Arrow isOpen outterSize={20} />
            </S.ButtonBack>
        </div>
        {children}
    </S.Wrapper>
);
