import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@invoicebox/ui';
import { ReactComponent as ArrowRightIcon } from 'src/assets/icons/arrow-right.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as DownloadIcon } from './assets/download.svg';
import * as S from './styles';
import { DocumentReceiveMethodWidget } from '../../../../../stories/docs/widgets/DocumentReceiveMethodWidget';
import { getTaskFlowByCode } from '../../../../../store/TaskFlow/selectors';
import {
    INVOICE_IDS_FIELD_NAME,
    TASKFLOW_CODE_FIELD_NAME,
    TASKFLOW_CODES,
    TTaskFlowCode,
} from '../../../../../store/TaskFlow/types';
import { useAppDispatch } from '../../../../../store';
import { createTask } from '../../../../../store/Task';
import path from '../../../../../utils/path';
import { COUNTERPARTY_ID_QUERY_NAME, ORDER_CONTAINER_IDS_QUERY_NAME } from '../../../constants';
import { DOCS_PATHS } from '../../../paths';
import { DATA_SPLITTER } from '../../../../../hooks/useQueryFilter';

type TProps = {
    orderContainerIds?: Array<string>;
    isDisabled: boolean;
    counterpartyId?: string;
};

const DocumentReceiveMethods: FC<TProps> = ({ orderContainerIds, isDisabled, counterpartyId }) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const mailTaskFlow = useSelector(getTaskFlowByCode('mail'));
    const emailTaskFlow = useSelector(getTaskFlowByCode('email'));
    const downloadTaskFlow = useSelector(getTaskFlowByCode('download'));
    const edmTaskFlow = useSelector(getTaskFlowByCode('edm_non_formalized'));

    const serializedArray = orderContainerIds?.join(DATA_SPLITTER);
    const queryParams = new URLSearchParams({
        [ORDER_CONTAINER_IDS_QUERY_NAME]: serializedArray || '',
        [COUNTERPARTY_ID_QUERY_NAME]: counterpartyId || '',
    });

    const handleGoToGetDocumentPage = (taskflowCode: string) => {
        navigate({
            pathname: path(DOCS_PATHS.getDocuments, taskflowCode),
            search: queryParams.toString(),
        });
    };

    const handleGetByMail = () => {
        handleGoToGetDocumentPage(TASKFLOW_CODES.mail);
    };

    const handleGetByEdo = () => {
        handleGoToGetDocumentPage(TASKFLOW_CODES.edm_non_formalized);
    };

    const handleGetByEmail = () => {
        handleGoToGetDocumentPage(TASKFLOW_CODES.email);
    };

    const handleGetByDownload = () => {
        if (counterpartyId) {
            dispatch(
                createTask({
                    [TASKFLOW_CODE_FIELD_NAME]: TASKFLOW_CODES.download,
                    counterpartyId,
                    data: {
                        [INVOICE_IDS_FIELD_NAME]: orderContainerIds,
                    },
                }),
            );
        }
    };

    const documentReceiveMethodsList: Array<{
        onClick: () => void;
        variant: TTaskFlowCode;
        icon: ReactNode;
        isActive: boolean;
    }> = [
        {
            variant: TASKFLOW_CODES.mail,
            onClick: handleGetByMail,
            icon: <ArrowRightIcon />,
            isActive: !!mailTaskFlow,
        },
        {
            variant: TASKFLOW_CODES.edm_non_formalized,
            onClick: handleGetByEdo,
            icon: <ArrowRightIcon />,
            isActive: !!edmTaskFlow,
        },
        {
            variant: TASKFLOW_CODES.email,
            onClick: handleGetByEmail,
            icon: <ArrowRightIcon />,
            isActive: !!emailTaskFlow,
        },
        {
            variant: TASKFLOW_CODES.download,
            onClick: handleGetByDownload,
            icon: <DownloadIcon />,
            isActive: !!downloadTaskFlow,
        },
        // edm_non_formalized пока не поддерживаем
    ].filter((i) => i.isActive && i.variant !== TASKFLOW_CODES.edm_non_formalized);

    return (
        <S.Wrapper>
            <Typography variant="headline6">
                {intl.formatMessage({ defaultMessage: 'Способ получения документа' })}
            </Typography>
            <S.DocumentReceiveMethodsListWrapper>
                {documentReceiveMethodsList.map((methodItem) => (
                    <S.ButtonDocumentReceiveMethod
                        key={methodItem.variant}
                        type="button"
                        onClick={methodItem.onClick}
                        disabled={isDisabled}
                    >
                        <DocumentReceiveMethodWidget variant={methodItem.variant} endIcon={methodItem.icon} />
                    </S.ButtonDocumentReceiveMethod>
                ))}
            </S.DocumentReceiveMethodsListWrapper>
        </S.Wrapper>
    );
};

export default DocumentReceiveMethods;
