import React, { FC, useEffect } from 'react';
import { ORDER_FIELDS, TOrder, TOrderItem, UnprocessableOrderStatuses } from 'src/store/Order/types';
import {
    TotalValues,
    TotalValuesBlock,
    TotalWrapper,
} from 'src/pages/businessOffice/OrderContainer/components/OrderTotal/styles';
import TotalRow from 'src/pages/businessOffice/OrderContainer/components/TotalRow';
import {
    convertDateAndTimeToShow,
    formatCurrencyToShow,
    getTotalAmountAndTotalVat,
    toValidCurrencyFloat,
} from 'src/utils/helpers';
import StatusOrder from 'src/pages/businessOffice/OrderContainer/components/StatusOrder';
import { useField, useForm } from 'react-final-form';
import { isDate } from 'lodash';
import dayjs from 'dayjs';
import { ISO_SHORT_DATE_FORMAT } from 'src/utils/constants';
import MobileButtonEditOrSave from 'src/pages/businessOffice/OrderContainer/components/MobileButtonEditOrSave';
import QRCodeBlock from 'src/pages/businessOffice/OrderContainer/components/QRCodeBlock';
import { useIntl } from 'react-intl';
import Button from 'src/stories/common/Button';

interface IOrderTotal {
    order: TOrder;
    setOrder: (order: TOrder) => void;
    setIsEditForm: (isEditForm: boolean) => void;
    basketItems: TOrderItem[];
    isEdit: boolean;
    isOrderCanEdit: boolean;
    handleSubmit: (
        event?: Partial<Pick<React.SyntheticEvent<Element, Event>, 'preventDefault' | 'stopPropagation'>> | undefined,
    ) => Promise<any | undefined> | undefined;
    onCancel: () => void;
}

const OrderTotal: FC<IOrderTotal> = ({
    isOrderCanEdit,
    handleSubmit,
    setIsEditForm,
    setOrder,
    isEdit,
    basketItems,
    order,
    onCancel,
}) => {
    const intl = useIntl();
    const { totalAmount, totalVat } = basketItems
        ? getTotalAmountAndTotalVat(basketItems)
        : { totalAmount: null, totalVat: null };
    const validAmount = totalAmount && toValidCurrencyFloat(totalAmount);
    const validVatAmount = totalVat && toValidCurrencyFloat(totalVat);

    const {
        input: { value: expirationDate },
    } = useField(ORDER_FIELDS.expirationDate);

    const { change } = useForm();

    const getConvertedDate = (date: string | Date) => {
        if (isDate(date)) {
            return dayjs(expirationDate).format(ISO_SHORT_DATE_FORMAT);
        }
        return convertDateAndTimeToShow(expirationDate);
    };

    useEffect(() => {
        change(ORDER_FIELDS.amount, validAmount);
        change(ORDER_FIELDS.vatAmount, validVatAmount);
    }, [change, validAmount, validVatAmount]);

    const getValueExpirationDate = () => {
        if (isEdit) {
            return getConvertedDate(expirationDate);
        }
        if (order?.[ORDER_FIELDS.expirationDate]) {
            return convertDateAndTimeToShow(order[ORDER_FIELDS.expirationDate]);
        }
    };

    const orderTotalValues = [
        {
            label: intl.formatMessage({ defaultMessage: 'Сумма' }),
            value: validAmount && formatCurrencyToShow(validAmount),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'НДС' }),
            value: (validVatAmount || validVatAmount === 0) && formatCurrencyToShow(validVatAmount),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Оформлен' }),
            value: order?.[ORDER_FIELDS.createdAt] && convertDateAndTimeToShow(order[ORDER_FIELDS.createdAt]),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Оплачен' }),
            value: getValueExpirationDate(),
        },
    ];

    const isCanEditStatus =
        order?.[ORDER_FIELDS.status] === UnprocessableOrderStatuses.created && !order?.[ORDER_FIELDS.processable];

    return (
        <TotalWrapper>
            <TotalValuesBlock>
                <StatusOrder
                    setIsEditForm={setIsEditForm}
                    isCanEdit={isCanEditStatus}
                    order={order}
                    setOrder={setOrder}
                />
                <TotalValues>
                    {orderTotalValues.map((orderTotalValue) => (
                        <TotalRow
                            isShowSkeleton={!orderTotalValue.value}
                            key={orderTotalValue.label}
                            label={orderTotalValue.label}
                            value={String(orderTotalValue.value)}
                        />
                    ))}
                </TotalValues>
                {isOrderCanEdit && (
                    <MobileButtonEditOrSave handleSubmit={handleSubmit} setIsEditForm={setIsEditForm} isEdit={isEdit} />
                )}
                {isOrderCanEdit && (
                    <div style={{ marginTop: 40 }}>
                        <Button fullWidth type="danger" onClick={onCancel}>
                            {intl.formatMessage({ defaultMessage: 'Отменить заказ' })}
                        </Button>
                    </div>
                )}
            </TotalValuesBlock>
            <QRCodeBlock paymentURL={order?.[ORDER_FIELDS.paymentUrl]} />
        </TotalWrapper>
    );
};

export default OrderTotal;
