import styled from 'styled-components';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white()};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.large}px;
    overflow: hidden;

    @media ${({ theme }) => theme.breakpoints.md} {
        position: absolute;
        width: 100%;
        height: 100vh;
    }
`;
