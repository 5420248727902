import { MERCHANT_FIELDS } from './contants';

export interface IWorkHours {
    mon: any[];
    tue: any[];
    thu: any[];
    wed: any[];
    fri: any[];
    sat: any[];
    sun: any[];
}

export type TWeeksDayRU = {
    [key: string]: string;
};

export enum WeeksDay {
    'mon' = 'mon',
    'tue' = 'tue',
    'wed' = 'wed',
    'thu' = 'thu',
    'fri' = 'fri',
    'sat' = 'sat',
    'sun' = 'sun',
}

export interface IMeasure {
    id: string;
    name: string;
    nameFull: string;
}

export type IMerchant = {
    [MERCHANT_FIELDS.id]: string;
    [MERCHANT_FIELDS.counterpartyId]: string;
    [MERCHANT_FIELDS.systemCounterpartyId]: string;
    [MERCHANT_FIELDS.testMode]: boolean;
    [MERCHANT_FIELDS.shipmentRequired]: boolean;
    [MERCHANT_FIELDS.status]: string;
    [MERCHANT_FIELDS.active]: boolean;
    [MERCHANT_FIELDS.needSendEmptyReport]: boolean;
    [MERCHANT_FIELDS.languageId]: number;
    [MERCHANT_FIELDS.type]: string;
    [MERCHANT_FIELDS.paymentNotice]?: string;
    [MERCHANT_FIELDS.name]?: string;
    [MERCHANT_FIELDS.nameI18n]?: string;
    [MERCHANT_FIELDS.description]?: string;
    [MERCHANT_FIELDS.descriptionI18n]?: string;
    [MERCHANT_FIELDS.email]?: string;
    [MERCHANT_FIELDS.phone]?: string;
    [MERCHANT_FIELDS.website]?: string;
    [MERCHANT_FIELDS.address]?: string;
    [MERCHANT_FIELDS.bankDetailId]?: string;
    [MERCHANT_FIELDS.mccId]?: number;
    [MERCHANT_FIELDS.timezoneId]?: number;
    [MERCHANT_FIELDS.merchantContractId]?: number;
    [MERCHANT_FIELDS.code]?: string;
    [MERCHANT_FIELDS.lat]?: number;
    [MERCHANT_FIELDS.lon]?: number;
    [MERCHANT_FIELDS.distance]?: number;
    [MERCHANT_FIELDS.public]?: boolean;
    [MERCHANT_FIELDS.hours]?: IWorkHours;
    [MERCHANT_FIELDS.logoImageId]?: string;
    [MERCHANT_FIELDS.bgImageId]?: string;
    [MERCHANT_FIELDS.note]?: string;
    [MERCHANT_FIELDS.documentFlowId]?: string;
    [MERCHANT_FIELDS.notUniqueOrderIdAllowed]?: boolean;
};

export type IMerchantRequestFields = Pick<
    IMerchant,
    MERCHANT_FIELDS.name | MERCHANT_FIELDS.mccId | MERCHANT_FIELDS.description
>;

export type TIntroduceCreateMerchant = {
    [MERCHANT_FIELDS.counterpartyId]: string;
    [MERCHANT_FIELDS.name]: string;
    [MERCHANT_FIELDS.mccId]: number;
    [MERCHANT_FIELDS.description]: string;
    [MERCHANT_FIELDS.website]: string;
};

export interface IMerchantFile {
    filename: string;
    hash: string;
    id: string;
    mimeType: string;
    path: string;
    url: string;
    userId: string;
}

export interface IMcc {
    id: number;
    name: string;
}
