import styled, { DefaultTheme } from 'styled-components';

export type TButtonType = 'primary' | 'secondary' | 'link' | 'danger';
export type TStyledButtonType = TButtonType | 'linkWithIcon';

export type TSize = 'small' | 'large';

export const ButtonWrapper = styled('a')<{
    $buttonType: TStyledButtonType;
    $iconOnly: boolean;
    $hasIcon: boolean;
    disabled: boolean;
    $fullWidth: boolean;
    $size: TSize;
}>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: none;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    transition: ${({ theme }) => theme.decorations.transition?.base};
    width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'fit-content')};
    border-radius: ${({ $buttonType }) => (isLinkButton($buttonType) ? 0 : '50')}px;
    padding: ${({ $buttonType, $iconOnly, $hasIcon, $size }) =>
        getButtonPaddings($buttonType, $iconOnly, $hasIcon, $size)};
    background-color: ${({ theme, $buttonType, disabled }) => typeToBg($buttonType, theme, disabled)};
    color: ${({ theme, $buttonType }) => typeToColor($buttonType, theme)};
    text-decoration: none;

    &:hover {
        opacity: ${({ $buttonType }) => (isTextButton($buttonType) ? 0.4 : 1)};
        background-color: ${({ theme, $buttonType, disabled }) => typeToHoverBg($buttonType, theme, disabled)};
        color: ${({ theme, $buttonType }) => typeToColor($buttonType, theme)};
    }

    &:active,
    &:focus {
        background-color: ${({ theme, $buttonType, disabled }) => typeToActiveBg($buttonType, theme, disabled)};
    }

    &:disabled {
        opacity: 1;
        cursor: not-allowed;
        background-color: ${({ $buttonType, theme }) =>
            isTextButton($buttonType) ? 'transparent' : theme.colors.lightGrey()};

        & > * {
            opacity: ${({ $buttonType }) => (isLinkButton($buttonType) ? 0.3 : 0.5)};
        }
    }
`;

export const ButtonInner = styled.span<{
    $iconOnly: boolean;
    $isLoading: boolean;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    visibility: ${({ $isLoading }) => ($isLoading ? 'hidden' : 'visible')};

    svg {
        margin-right: ${({ $iconOnly }) => ($iconOnly ? '0' : '9')}px;
        width: ${({ $iconOnly }) => ($iconOnly ? '24' : '16')}px;
        height: ${({ $iconOnly }) => ($iconOnly ? '24' : '16')}px;
    }
`;

const typeToBg = (buttonType: TStyledButtonType, theme: DefaultTheme, disabled: boolean): string => {
    if (isTextButton(buttonType)) return 'transparent';
    if (disabled) return theme.colors.lightGrey();

    const hashMap = {
        primary: theme.colors.yellow(),
        secondary: theme.colors.lightGrey(),
        danger: theme.colors.red(),
        link: 'transparent',
        linkWithIcon: 'transparent',
    };

    return hashMap[buttonType];
};

const typeToHoverBg = (buttonType: TStyledButtonType, theme: DefaultTheme, disabled: boolean): string => {
    if (isTextButton(buttonType)) return 'transparent';
    if (disabled) return theme.colors.lightGrey();

    const hashMap = {
        primary: theme.colors.hoverYellow(),
        secondary: theme.colors.hoverGray(),
        danger: theme.colors.redPressed(),
        link: 'transparent',
        linkWithIcon: 'transparent',
    };

    return hashMap[buttonType];
};

const typeToActiveBg = (buttonType: TStyledButtonType, theme: DefaultTheme, disabled: boolean): string => {
    if (isTextButton(buttonType)) return 'transparent';
    if (disabled) return theme.colors.lightGrey();

    const hashMap = {
        primary: theme.colors.activeYellow(),
        secondary: theme.colors.activeGray(),
        danger: theme.colors.redPressed(),
        link: 'transparent',
        linkWithIcon: 'transparent',
    };

    return hashMap[buttonType];
};

const SIZE_PADDING_MAP: Record<TSize, { y: number; x: number }> = {
    small: {
        y: 8,
        x: 16,
    },
    large: {
        y: 13,
        x: 24,
    },
};

const getButtonPaddings = (buttonType: TStyledButtonType, iconOnly: boolean, hasIcon: boolean, size: TSize): string => {
    if (isTextButton(buttonType)) return '0px';

    const sizeY = SIZE_PADDING_MAP[size].y;
    const sizeX = SIZE_PADDING_MAP[size].x;

    if (iconOnly) {
        return `${sizeY}px`;
    } else {
        return hasIcon ? '15px 20px' : `${sizeY}px ${sizeX}px`;
    }
};

const typeToColor = (buttonType: TStyledButtonType, theme: DefaultTheme): string => {
    const hashMap = {
        primary: theme.colors.base(),
        secondary: theme.colors.base(),
        danger: theme.colors.white(),
        link: theme.colors.base(),
        linkWithIcon: theme.colors.base(),
    };

    return hashMap[buttonType];
};

const isLinkButton = (buttonType: TStyledButtonType) => buttonType === 'link';
const isLinkWithIconButton = (buttonType: TStyledButtonType) => buttonType === 'linkWithIcon';
const isTextButton = (buttonType: TStyledButtonType) =>
    isLinkButton(buttonType) || isLinkWithIconButton(buttonType) || false;
