import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';
import Button from '../../common/Button';

export const Wrapper = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 570px;
    text-align: center;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        padding: 16px;
        justify-content: center;
    }
`;

export const Description = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.5)};
    margin-top: 36px;
`;

export const CloseButton = styled(Button)`
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;
