import styled from 'styled-components';
import Typography from 'src/components/Typography';

export const MobileSlideWrapper = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    background: ${({ theme }) => theme.colors.lightGrey(0.5)};
    padding: 30px 20px 72px 20px;
`;

export const Title = styled(Typography)`
    margin-bottom: 24px;
`;

export const Paragraphs = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 14px;
`;

export const Paragraph = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.5)};
`;
