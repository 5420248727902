import styled from 'styled-components';

export const Wrapper = styled.div`
    border-radius: ${({ theme }) => theme.decorations.borderRadius.large}px;
    background-color: ${({ theme }) => theme.colors.white()};

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        min-height: 100vh;
        height: auto;
        display: flex;
    }
`;
