import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTimeDiff } from '../../../../utils/time';
import { getExpiredAt } from '../../../../store/Auth/selectors';

export const useExpiredEnterSmsCode = () => {
    const expiredIntervalId = useRef<number | null>(null);

    const [isExpired, setIsExpired] = useState(false);
    const [timer, setTimer] = useState(0);

    const expiredAt = useSelector(getExpiredAt);

    const handleExpiredIntervalClear = () => {
        if (expiredIntervalId.current) {
            window.clearInterval(expiredIntervalId.current);
        }
    };

    useEffect(() => {
        if (expiredAt && getTimeDiff(new Date(expiredAt), new Date()) > 0) {
            setIsExpired(false);
        }
    }, [expiredAt]);

    useEffect(() => {
        if (expiredAt && !isExpired) {
            expiredIntervalId.current = window.setInterval(() => {
                const isTimerExpired = getTimeDiff(new Date(expiredAt), new Date()) <= 0;

                setIsExpired(isTimerExpired);
                if (isTimerExpired) {
                    setTimer(0);
                } else {
                    setTimer(Math.floor(getTimeDiff(new Date(expiredAt), new Date()) / 1000));
                }
            }, 1000);
            return () => handleExpiredIntervalClear();
        }
    }, [expiredAt, isExpired]);

    return { timer, isExpired };
};
