import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
    background-color: ${({ theme }) => theme.colors.white()};
    height: 80px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        height: 70px;
    }
`;

export const Content = styled.div`
    width: 1170px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media ${({ theme }) => theme.breakpoints.xl} {
        width: 100%;
        padding: 0 24px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0 16px;
    }
`;

export const LogoButton = styled(Link)`
    display: flex;
    align-items: center;
`;

export const MenuItem = styled(NavLink)`
    text-decoration: none;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;

    &:after {
        content: '';
    }

    &.active {
        &:after {
            display: block;
            width: 100%;
            height: 2px;
            background-color: ${({ theme }) => theme.colors.base()};
            position: absolute;
            top: 48px;
            border-radius: 2px 2px 0 0;
        }
    }
`;

export const MenuLinks = styled.div`
    display: flex;
    gap: 24px;
`;
