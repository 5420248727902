import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ShopNavContainer, ShopNavButton, ShopNavLink } from '../styles';
import { getCurrentCounterpartyId } from '../../../../store/Counterparty/selectors';
import { BUSINESS_OFFICE_PATHS } from '../../paths';

interface INavigationProps {
    merchantId: string;
}

const ShopNavigation: FC<INavigationProps> = ({ merchantId }) => {
    const intl = useIntl();
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const NAVIGATION_FOR_SHOP = [
        {
            label: intl.formatMessage({ defaultMessage: 'Обзор' }),
            to: BUSINESS_OFFICE_PATHS.shop(counterpartyId).overview(merchantId),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Заказы' }),
            to: BUSINESS_OFFICE_PATHS.shop(counterpartyId).orders(merchantId),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Возвраты' }),
            to: BUSINESS_OFFICE_PATHS.shop(counterpartyId).refunds(merchantId),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Клиенты' }),
            to: BUSINESS_OFFICE_PATHS.shop(counterpartyId).clients(merchantId),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Настройки' }),
            to: BUSINESS_OFFICE_PATHS.shop(counterpartyId).settings(merchantId),
        },
        {
            label: intl.formatMessage({ defaultMessage: 'Интеграции' }),
            to: BUSINESS_OFFICE_PATHS.shop(counterpartyId).integrations(merchantId),
        },
    ];

    return (
        <ShopNavContainer>
            {NAVIGATION_FOR_SHOP.map(({ label, to }) => (
                <ShopNavButton key={label + to}>
                    <ShopNavLink to={to}>{label}</ShopNavLink>
                </ShopNavButton>
            ))}
        </ShopNavContainer>
    );
};

export default ShopNavigation;
