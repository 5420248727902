import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import * as S from './styles';

export type TProps = {
    children: ReactNode;
    vatNumber?: string;
    taxRegistrationReasonCode?: string;
};

export const Header: FC<TProps> = ({ children, taxRegistrationReasonCode, vatNumber }) => {
    const intl = useIntl();

    return (
        <S.Wrapper>
            <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
            <S.OrganizationData>
                {vatNumber && (
                    <S.Parameter>
                        <S.Label variant="captionRegular">
                            {intl.formatMessage({ id: 'ИНН:', defaultMessage: 'ИНН:' })}
                        </S.Label>
                        <S.Value variant="captionRegular">{vatNumber}</S.Value>
                    </S.Parameter>
                )}
                {taxRegistrationReasonCode && (
                    <S.Parameter>
                        <S.Label variant="captionRegular">
                            {intl.formatMessage({
                                id: 'КПП:',
                                defaultMessage: 'КПП:',
                            })}
                        </S.Label>
                        <S.Value variant="captionRegular">{taxRegistrationReasonCode}</S.Value>
                    </S.Parameter>
                )}
            </S.OrganizationData>
        </S.Wrapper>
    );
};
