import React, { FC } from 'react';
import { QRCode } from 'react-qrcode-logo';
import { useNavigate, useParams } from 'react-router-dom';
import logoImage from 'src/assets/icons/logoSmallQR.png';
import { copyOrShareLink } from 'src/utils/helpers';
import { useMobile } from 'src/hooks/useMedia';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactComponent as LinkIcon } from '../../assets/link.svg';

import {
    ModalSuccessOrderWrapper,
    SuccessQRArea,
    QRTitle,
    QRSubtitile,
    SuccessContent,
    SuccessContentSubtitle,
    SuccessContentTitle,
    ButtonCopyLink,
    ReFindOrder,
    OrdersLink,
    WrapperQRCode,
    QRDescription,
} from './styles';
import { BUSINESS_OFFICE_PATHS } from '../../../../../paths';
import { getCurrentCounterpartyId } from '../../../../../../../store/Counterparty/selectors';

interface IModalSuccessOrder {
    isOpen: boolean;
    linkToPayOrder: string | null;
}

const ModalSuccessOrder: FC<IModalSuccessOrder> = ({ linkToPayOrder, isOpen }) => {
    const intl = useIntl();

    const isMobile = useMobile();
    const navigate = useNavigate();
    const { merchantId } = useParams() as { merchantId: string };

    const counterpartyId = useSelector(getCurrentCounterpartyId);

    if (linkToPayOrder === null) {
        return null;
    }

    const closeModal = () => {
        navigate(BUSINESS_OFFICE_PATHS.shop(counterpartyId).overview(merchantId));
    };

    return (
        <ModalSuccessOrderWrapper closeModal={closeModal} isOpen={isOpen}>
            <SuccessQRArea>
                <QRDescription>
                    <QRTitle variant="subheading">{intl.formatMessage({ defaultMessage: 'QR код' })}</QRTitle>
                    <QRSubtitile variant="regular12">
                        {intl.formatMessage({ defaultMessage: 'Сформированный системой QR код счёта' })}
                    </QRSubtitile>
                </QRDescription>
                <WrapperQRCode>
                    <QRCode
                        value={linkToPayOrder}
                        eyeRadius={6}
                        size={114}
                        quietZone={8}
                        logoImage={logoImage}
                        logoWidth={25}
                    />
                </WrapperQRCode>
            </SuccessQRArea>
            <SuccessContent>
                <SuccessContentTitle variant="subheading">
                    {intl.formatMessage({ defaultMessage: 'Спасибо, счёт оформлен!' })}
                </SuccessContentTitle>
                <SuccessContentSubtitle variant="medium12">
                    {intl.formatMessage({ defaultMessage: 'Вы можете поделиться ссылкой на счёт' })}
                </SuccessContentSubtitle>
                <ButtonCopyLink
                    onClick={() => {
                        copyOrShareLink(linkToPayOrder, isMobile, intl);
                    }}
                >
                    <LinkIcon />
                </ButtonCopyLink>
                <ReFindOrder variant="regular12">
                    {intl.formatMessage({ defaultMessage: 'Повторно найти счёт можно в' })}{' '}
                    <OrdersLink to="orders"> {intl.formatMessage({ defaultMessage: 'списке счетов' })}* </OrdersLink>
                </ReFindOrder>
            </SuccessContent>
        </ModalSuccessOrderWrapper>
    );
};

export default ModalSuccessOrder;
