import React from 'react';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import Tag from '../Tag';

const EdoTag = () => {
    const theme = useTheme();
    const intl = useIntl();

    return (
        <Tag color={theme.colors.white()} backgroundColor="#4490B0" isCheckIcon>
            {intl.formatMessage({
                defaultMessage: 'ЭДО',
                id: 'ЭДО',
            })}
        </Tag>
    );
};

export default EdoTag;
