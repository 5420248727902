import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CreateOrderButtonContainer, AddIcon, OrderButton } from '../../styles';
import { BUSINESS_OFFICE_PATHS } from '../../../../pages/businessOffice/paths';
import { getCurrentCounterpartyId } from '../../../../store/Counterparty/selectors';

const CreateOrderButton = () => {
    const intl = useIntl();
    const { merchantId } = useParams() as { merchantId: string };

    const counterpartyId = useSelector(getCurrentCounterpartyId);

    return (
        <CreateOrderButtonContainer>
            <OrderButton to={BUSINESS_OFFICE_PATHS.shop(counterpartyId).orderToPay(merchantId)}>
                <AddIcon />
                {intl.formatMessage({ defaultMessage: 'Создать заказ' })}
            </OrderButton>
        </CreateOrderButtonContainer>
    );
};

export default CreateOrderButton;
