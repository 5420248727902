export enum COUNTRIES {
    RUS = 'RUS',
    BLR = 'BLR',
    KAZ = 'KAZ',
    AZE = 'AZE',
    ARM = 'ARM',
    KGZ = 'KGZ',
    MDA = 'MDA',
    TJK = 'TJK',
    UZB = 'UZB',
}

export const RUSCountryCode = COUNTRIES.RUS;

export const knownCountryCodes = [
    RUSCountryCode,
    COUNTRIES.BLR,
    COUNTRIES.KAZ,
    COUNTRIES.AZE,
    COUNTRIES.ARM,
    COUNTRIES.KGZ,
    COUNTRIES.MDA,
    COUNTRIES.TJK,
    COUNTRIES.UZB,
] as const;

export type TKnownCountryCode = (typeof knownCountryCodes)[number];

export type TKnownOrUnknownCountryCode = string;

export type TCountry = {
    id: string;
    name: string;
    code: TKnownOrUnknownCountryCode;
    shortCode: string;
    numericCode: number;
    active: boolean;
};
