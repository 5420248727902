import React, { FC, useCallback } from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { PhoneInput, TPhoneInputProps } from '@invoicebox/ui';

export type TProps = Omit<TPhoneInputProps, 'onChange' | 'value'> & {
    name: string;
};

export const RHFPhoneInput: FC<TProps> = ({ name, ...other }) => {
    const { control } = useFormContext();

    const renderTextField = useCallback(
        ({
            field,
            fieldState: { error, isTouched },
        }: {
            field: ControllerRenderProps;
            fieldState: ControllerFieldState;
        }) => (
            <PhoneInput
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                name={field.name}
                hasError={isTouched && !!error}
                {...other}
            />
        ),
        [other],
    );

    return <Controller name={name} control={control} render={renderTextField} />;
};
