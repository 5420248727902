import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSWR } from 'src/hooks/useSWR';
import { IOrderContainer, TOrder, UnprocessableOrderStatuses } from 'src/store/Order/types';
import { useSelector } from 'react-redux';
import { getCounterpartyIds } from 'src/store/Counterparty/selectors';
import { getUserId } from 'src/store/Auth/selectors';
import DetailPayment from 'src/pages/businessOffice/OrderContainer/components/DetailPayment';
import DetailOrder from 'src/pages/businessOffice/OrderContainer/components/DetailOrder';
import { isEmpty } from 'lodash';
import { useIntl } from 'react-intl';

const OrderContainer = () => {
    const intl = useIntl();
    const location = useLocation();

    const isOpenOrderContainer = location.state?.isOrderContainer;

    const { orderContainerId } = useParams<{ orderContainerId: string }>();
    const { data } = useSWR<IOrderContainer>(`billing/api/order/order-container/${orderContainerId}`, {
        _include: ['order', 'debit-invoice'],
        _extend: ['merchant', 'counterparty'],
    });

    const orderContainer: IOrderContainer = data?.data as any;
    const order =
        data?.extendedData &&
        data?.extendedData
            .find((item) => item.entity === 'order')
            ?.data.find((orderData: TOrder) => orderData.subtype === 'order');
    const merchant = data?.extendedData && data?.extendedData.find((item) => item.entity === 'merchant')?.data[0];
    const debitInvoice =
        data?.extendedData && data?.extendedData.find((item) => item.entity === 'debit-invoice')?.data[0];
    const counterpartiesIds = useSelector(getCounterpartyIds);
    const idCurrentUser = useSelector(getUserId);

    const isShowDebitInvoiceCustomer =
        !isEmpty(debitInvoice?.customer) && order?.status === UnprocessableOrderStatuses.completed;

    const userPayerCounterparty = counterpartiesIds.find(
        (counterpartyId) => counterpartyId === orderContainer?.payerCounterpartyId,
    );
    const isCurrentUserPayer = orderContainer?.payerCounterpartyId
        ? userPayerCounterparty
        : orderContainer?.payerUserId === idCurrentUser;

    const userCounterpartyCreatorOrder = counterpartiesIds.find(
        (counterpartyId) => counterpartyId === merchant?.counterpartyId,
    );

    if (typeof isOpenOrderContainer === 'boolean') {
        if (isOpenOrderContainer) {
            return (
                <DetailPayment
                    payerId={userPayerCounterparty || null}
                    orderContainer={orderContainer}
                    order={order}
                    merchant={merchant}
                    customer={isShowDebitInvoiceCustomer ? debitInvoice?.customer : order?.customer}
                />
            );
        }

        return (
            <DetailOrder
                debitInvoiceCustomer={isShowDebitInvoiceCustomer && debitInvoice?.customer}
                orderData={order}
                merchant={merchant}
            />
        );
    }

    // порядок условий такой ниже и в useEffect т.к. если у пользователя один контрагент поставил счет другому контрагенту (от этого же пользователя),
    // то нужно открывать страницу редактирования/просмотра счёта
    if (userCounterpartyCreatorOrder) {
        return (
            <DetailOrder
                debitInvoiceCustomer={isShowDebitInvoiceCustomer && debitInvoice?.customer}
                orderData={order}
                merchant={merchant}
            />
        );
    }

    if (isCurrentUserPayer) {
        return (
            <DetailPayment
                customer={isShowDebitInvoiceCustomer ? debitInvoice?.customer : order?.customer}
                payerId={userPayerCounterparty || null}
                orderContainer={orderContainer}
                order={order}
                merchant={merchant}
            />
        );
    }

    // todo нужно 404 страницу для этого сделать
    return <div>{intl.formatMessage({ defaultMessage: 'Счёт не найден' })}</div>;
};

export default OrderContainer;
