import { RootState } from '../index';
import { taskPlaceAdapter } from './index';
import { TaskPlace } from './entity';

const getTaskPlace = (state: RootState) => state.taskPlace;

const { selectAll } = taskPlaceAdapter.getSelectors(getTaskPlace);

export const getTaskPlaceByTaskId = (taskId: number) => (state: RootState) =>
    selectAll(state).filter((taskPlace: TaskPlace) => taskPlace.taskId === taskId);
