import React, { FC } from 'react';
import { Drawer, Dropdown, TDropdownProps } from '@invoicebox/ui';
import { useMobile } from '../../../hooks/useMedia';

export type TProps = {
    isOpen: boolean;
    onClose: () => void;
    dropdownProps?: Omit<TDropdownProps, 'isOpen' | 'children'>;
    drawerProps?: Omit<TDropdownProps, 'isOpen' | 'onClose'>;
};

export const AdaptiveDropdownDrawer: FC<TProps> = ({ isOpen, onClose, children, dropdownProps, drawerProps }) => {
    const isMobile = useMobile();

    if (isMobile) {
        return (
            <Drawer onClose={onClose} isOpen={isOpen} {...drawerProps}>
                {children}
            </Drawer>
        );
    }

    return (
        <Dropdown isOpen={isOpen} {...dropdownProps}>
            {children}
        </Dropdown>
    );
};
