import styled from 'styled-components';

export const HiddenInput = styled.input`
    display: none;
`;

export const RadioIcon = styled.div`
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.white()};
    transition: ${({ theme }) => theme.decorations.transition?.base};
`;

export const StyledRadio = styled.div<{
    checked: boolean;
}>`
    ${({ theme }) => theme.mixins.flexCenterCenter};

    background-color: ${({ checked, theme }) => (checked ? theme.colors.yellow() : 'transparent')};
    border: 2px solid ${({ checked, theme }) => (checked ? theme.colors.yellow() : theme.colors.grey())};
    border-radius: 100%;
    transition: ${({ theme }) => theme.decorations.transition?.base};
    cursor: pointer;

    ${RadioIcon} {
        opacity: ${(props) => (props.checked ? 1 : 0)};
    }
`;

export type TVariant = 'small' | 'medium';

const SIZES_BY_VARIANT: Record<TVariant, { wrapper: number; icon: number }> = {
    small: {
        wrapper: 16,
        icon: 6,
    },
    medium: {
        wrapper: 20,
        icon: 8,
    },
};

export const RadioContainer = styled.div<{ variant: TVariant }>`
    display: inline-block;
    vertical-align: middle;

    ${StyledRadio} {
        width: ${({ variant }) => SIZES_BY_VARIANT[variant].wrapper}px;
        height: ${({ variant }) => SIZES_BY_VARIANT[variant].wrapper}px;
    }

    ${RadioIcon} {
        width: ${({ variant }) => SIZES_BY_VARIANT[variant].icon}px;
        height: ${({ variant }) => SIZES_BY_VARIANT[variant].icon}px;
    }
`;
