import http, { ICommonResponse } from 'src/utils/http';
import { TOrganization } from './types';

export const getCounterpartyDetailListByVatNumberRequest = (vatNumber: string, countryId: string) =>
    http.get<ICommonResponse<Array<TOrganization>>>('filter/api/counterparty-detail', {
        params: {
            vatNumber,
            countryId,
        },
    });
