import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import * as S from './styles';
import { ReactComponent as ArrowIcon } from './assets/arrow-right.svg';
import { ReactComponent as ArrowBackIcon } from './assets/arrow-back.svg';

export type TProps = {
    title: string;
    onDocumentStatusCheck?: () => void;
    onBack?: () => void;
};

export const HeaderContentPage: FC<TProps> = ({ onDocumentStatusCheck, onBack, title }) => {
    const intl = useIntl();

    return (
        <S.Wrapper>
            <S.TitleWrapper>
                {onBack && (
                    <S.ArrowBackButton type="button" onClick={onBack}>
                        <ArrowBackIcon />
                    </S.ArrowBackButton>
                )}

                <Typography variant="headline3">{title}</Typography>
            </S.TitleWrapper>

            {onDocumentStatusCheck && (
                <S.ButtonCheckDocumentsStatus type="button" onClick={onDocumentStatusCheck}>
                    <Typography variant="buttonM">
                        {intl.formatMessage({
                            id: 'Проверка статуса отправления доументов',
                            defaultMessage: 'Проверка статуса отправления доументов',
                        })}
                    </Typography>
                    <S.ArrowWrapper>
                        <ArrowIcon />
                    </S.ArrowWrapper>
                </S.ButtonCheckDocumentsStatus>
            )}
        </S.Wrapper>
    );
};
