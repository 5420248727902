import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Button from 'src/stories/common/Button';

export const CreateIntegrationWrapper = styled.div`
    padding-top: 45px;
    width: 100%;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding-top: 33px;
    }
`;

export const CreateIntegrationTitle = styled(Typography)`
    margin-bottom: 50px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-bottom: 30px;
        ${({ theme }) => theme.mixins.getTypography('subheading')}
    }
`;

export const ButtonCreateIntegration = styled(Button)`
    font-weight: 600;
    margin-top: 40px;
`;
