import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { ORDER_CONTAINER_IDS_QUERY_NAME } from '../../constants';
import { DOCS_PATHS } from '../../paths';
import { DATA_SPLITTER } from '../../../../hooks/useQueryFilter';
import useSearchQuery from '../../../../hooks/useSearchQuery';

export const useQueryOrderContainerIds = () => {
    const navigate = useNavigate();
    const { searchParams } = useSearchQuery();

    const serializedArray = useMemo(() => searchParams.get(ORDER_CONTAINER_IDS_QUERY_NAME), [searchParams]);

    const handleGoToDashboard = useCallback(() => {
        navigate(DOCS_PATHS.dashboard);
    }, [navigate]);

    const orderContainerIds = useMemo(
        () => (serializedArray ? serializedArray.split(DATA_SPLITTER) : undefined),
        [serializedArray],
    );

    if (!orderContainerIds) {
        // eslint-disable-next-line no-console
        console.error('not found orderContainerIds');
        handleGoToDashboard();
    }

    return orderContainerIds as Array<string>;
};
