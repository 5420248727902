import styled from 'styled-components';
import Typography from 'src/components/Typography';
import Button from 'src/stories/common/Button';

export const CounterpartyClientWrapper = styled.div`
    display: grid;
    grid-row-gap: 30px;
`;

export const CounterpartyClientTitle = styled(Typography)`
    margin-bottom: 20px;
`;

export const ButtonRemoveClientCounterparty = styled(Button)`
    color: ${({ theme }) => theme.colors.base(0.5)};
    margin: 20px auto 0;
    @media ${({ theme }) => theme.breakpoints.md} {
        margin-top: 0;
    }
`;
