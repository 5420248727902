import { URLSearchParamsInit } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import useSearchQuery from './useSearchQuery';
import { FIRST_PAGE, PAGE } from './usePagination';
import { DATE_YYYY_MM_DD } from '../utils/constants';

type TUseQueryArgumentType = string | Array<string> | undefined | [Date, Date] | null;

export const DATA_SPLITTER = '!!!';

export const getDateArrOrNullFromQueryString = (value: string | null): [Date, Date] | null => {
    if (value) {
        const datesArray = value.split(DATA_SPLITTER).map((date) => new Date(date));

        if (datesArray.length === 2) {
            return [datesArray[0], datesArray[1]];
        }
    }

    return null;
};

export const getQueryStringFromDateArrOrNull = (value: [Date, Date] | null) =>
    value ? value.map((date) => dayjs(date).format(DATE_YYYY_MM_DD)).join(DATA_SPLITTER) : '';

export const getQueryStringFromArrayString = (value: Array<string> | undefined) => value?.join(DATA_SPLITTER) || '';
export const getArrayStringsFromQueryString = (value: string | null) =>
    value ? value?.split(DATA_SPLITTER) : undefined;

const removeAttributeFromQueryString = (
    nameField: string,
    searchParams: URLSearchParams,
    setSearchParams: (nextInit: URLSearchParamsInit) => void,
    isResetPageWhenChanged: boolean = true,
) => {
    if (searchParams.has(nameField)) {
        searchParams.delete(nameField);
        if (isResetPageWhenChanged) {
            searchParams.set(PAGE, String(FIRST_PAGE));
        }

        setSearchParams(searchParams);
    }
};

export const useQueryFilter = <T extends TUseQueryArgumentType>(
    name: string,
    initialValue: T,
    fromString?: (value: string | null) => T,
    toString?: (value: T) => string,
    isResetPageWhenChanged: boolean = true,
): [T, (newValue: T) => void] => {
    const { searchParams, setSearchParams } = useSearchQuery();

    const getIsEmptyValue = (value: TUseQueryArgumentType) =>
        value === '' || value === undefined || value === null || (Array.isArray(value) && value.length === 0);

    const handleQueryChange = useCallback(
        (newValue: T) => {
            if (getIsEmptyValue(newValue)) {
                removeAttributeFromQueryString(name, searchParams, setSearchParams);
                return;
            }
            if (toString) {
                searchParams.set(name, toString(newValue));
            } else {
                searchParams.set(name, String(newValue));
            }

            if (isResetPageWhenChanged) {
                searchParams.set(PAGE, String(FIRST_PAGE));
            }

            setSearchParams(searchParams);
        },
        [isResetPageWhenChanged, name, searchParams, setSearchParams, toString],
    );

    const acceptInitialValue = useCallback(
        (initial: T) => {
            if (!searchParams.has(name)) {
                if (getIsEmptyValue(initial)) {
                    removeAttributeFromQueryString(name, searchParams, setSearchParams);
                    return;
                }
                searchParams.set(name, String(initial));
                setSearchParams(searchParams);
            }
        },
        [name, searchParams, setSearchParams],
    );

    useEffect(() => {
        acceptInitialValue(initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    const handleGetValueFromString = useCallback(
        (queryValue: string | null): T => {
            if (fromString) {
                return fromString(queryValue);
            }
            return (queryValue || '') as T;
        },
        [fromString],
    );

    const formattedValue = useMemo(
        () => handleGetValueFromString(searchParams.get(name)),
        [handleGetValueFromString, name, searchParams],
    );

    return [formattedValue, handleQueryChange];
};
