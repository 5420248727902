import React, { FC } from 'react';
import {
    IconAdd,
    NavLinkCreateClient,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/Clients/components/ButtonCreateClient/styles';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { typesClients } from '../../index';
import { BUSINESS_OFFICE_PATHS } from '../../../../../paths';
import { getCurrentCounterpartyId } from '../../../../../../../store/Counterparty/selectors';

interface IButtonCreateClient {
    typeClient: typesClients;
}

const ButtonCreateClient: FC<IButtonCreateClient> = ({ typeClient }) => {
    const intl = useIntl();
    const { merchantId } = useParams() as { merchantId: string };
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    return (
        <NavLinkCreateClient
            to={
                typeClient === typesClients.CLIENT
                    ? BUSINESS_OFFICE_PATHS.shop(counterpartyId).createClient(merchantId)
                    : BUSINESS_OFFICE_PATHS.shop(counterpartyId).createCounterparty(merchantId)
            }
        >
            <IconAdd />
            {intl.formatMessage({ defaultMessage: 'Новый клиент' })}
        </NavLinkCreateClient>
    );
};

export default ButtonCreateClient;
