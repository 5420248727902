import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { Document } from './entity';
import { getDocumentListByOrderContainerIdRequest } from './http';

export const documentAdapter = createEntityAdapter<Document>();

const initialState = documentAdapter.getInitialState({
    isInitialized: false,
    isLoading: false,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

export const fetchDocumentListByOrderContainerId = createAsyncThunk(
    'document/fetchByOrderContainerId',
    async (orderContainerIds: Array<string>) => {
        const { data } = await getDocumentListByOrderContainerIdRequest(orderContainerIds);

        return data;
    },
);

const documentSlice = createSlice({
    name: 'ndocument',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDocumentListByOrderContainerId.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchDocumentListByOrderContainerId.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isInitialized = true;
            state.metaData = payload.metaData;
            documentAdapter.setAll(
                state as EntityState<Document>,
                payload.data.map((documentFromResponse) => Document.fromResponse(documentFromResponse)),
            );
        });
        builder.addCase(fetchDocumentListByOrderContainerId.rejected, (state) => {
            state.isLoading = false;
            state.isInitialized = true;
        });
    },
});

export default documentSlice.reducer;
