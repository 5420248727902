import styled from 'styled-components';
import Button from 'src/stories/common/Button';
import Modal from 'src/components/Modal';

export const ButtonModalAddItem = styled(Button)`
    margin: 20px auto 0;
    grid-column-start: 3;
    grid-column-end: 5;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-column-start: 2;
        grid-column-end: 4;
    }
`;

export const ModalAddItemOrderWrapper = styled(Modal)`
    width: 642px;
    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;

export const HeaderModalAddItemOrder = styled.div`
    padding: 42px 50px;
    background: ${({ theme }) => theme.colors.white()};
`;

export const FormAddItemOrder = styled.form`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 30px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: repeat(4, 1fr);
    }
`;
