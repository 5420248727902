import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Scrollbar } from '@invoicebox/ui';
import * as S from './styles';
import { getNoun } from '../../../../../utils/helpers';
import { OrderContainer } from '../../../../../store/OrderContainer/entity';
import { getDocumentList } from '../../../../../store/NDocument/selectors';
import { getDocumentGeneratorSettingList } from '../../../../../store/DocumentGeneratorSetting/selectors';
import { BillSummaryWidget } from '../../../../../stories/docs/widgets/BillSummaryWidget';
import { Document } from '../../../../../store/NDocument/entity';

type TProps = {
    orderContainers: Array<OrderContainer>;
};

const OrderContainersTable: FC<TProps> = ({ orderContainers }) => {
    const intl = useIntl();

    const documents = useSelector(getDocumentList);
    const documentGeneratorSettingList = useSelector(getDocumentGeneratorSettingList);

    const countOrderContainers = orderContainers.length;
    const interval =
        countOrderContainers > 1
            ? OrderContainer.intervalBetweenExtremesCreatedAt(orderContainers)
            : orderContainers[0].formattedCreatedAtToDate();

    return (
        <S.Wrapper>
            <S.Header>
                <S.OrderContainerCountLabel variant="headline6">
                    {`${countOrderContainers} ${getNoun(
                        countOrderContainers,
                        intl.formatMessage({ id: 'счёт', defaultMessage: 'счёт' }),
                        intl.formatMessage({ id: 'счёта', defaultMessage: 'счёта' }),
                        intl.formatMessage({ id: 'счётов', defaultMessage: 'счётов' }),
                    )}`}
                </S.OrderContainerCountLabel>
                <S.DatesInterval variant="smallMedium">{interval}</S.DatesInterval>
            </S.Header>
            <S.Title variant="bodyMRegular">
                {intl.formatMessage({ id: 'Документы к отправке:', defaultMessage: 'Документы к отправке:' })}
            </S.Title>
            <Scrollbar maxHeight={340}>
                {orderContainers.map((orderContainerItem) => (
                    <BillSummaryWidget
                        key={orderContainerItem.id}
                        orderContainer={orderContainerItem}
                        documents={Document.matchByOrderContainerId(orderContainerItem.id, documents)}
                        documentGeneratorSettingList={documentGeneratorSettingList}
                    />
                ))}
            </Scrollbar>
        </S.Wrapper>
    );
};

export default OrderContainersTable;
