import React, { FC } from 'react';
import { InvoiceboxLogo } from '@invoicebox/ui';
import * as S from './styles';
import { ReactComponent as CloseIcon } from './assets/cross.svg';

export type TProps = {
    isOpen: boolean;
    onClose: () => void;
    menuLinks: Array<{ href: string; label: string }>;
};

export const BurgerMenu: FC<TProps> = ({ isOpen, onClose, menuLinks }) => {
    const handleNavLinkClick = () => {
        onClose();
    };

    return (
        <S.Wrapper $isOpen={isOpen}>
            <S.Head>
                <S.LogoWrapper>
                    <InvoiceboxLogo height={26} />
                </S.LogoWrapper>
                <S.CloseButton type="button" onClick={onClose}>
                    <CloseIcon />
                </S.CloseButton>
            </S.Head>
            <S.MenuItems>
                {menuLinks.map((menuItem) => (
                    <S.MenuItem key={menuItem.label} to={menuItem.href} onClick={handleNavLinkClick}>
                        {menuItem.label}
                    </S.MenuItem>
                ))}
            </S.MenuItems>
        </S.Wrapper>
    );
};
