import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.white()};
    padding: 8px 0;
    width: 370px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 100%;
    }
`;

export const Head = styled.div`
    padding: 8px 20px;
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 8px;
    justify-content: space-between;
`;

export const Columns = styled.div`
    padding: 12px 20px;
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 8px;
    justify-content: space-between;
    background: #f5f6fa;
    color: ${({ theme }) => theme.colors.base(0.5)};
`;

export const EmptyLabel = styled(Typography)`
    text-align: center;
    margin: 20px 0;
`;
