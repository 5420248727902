import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';
import { Wrapper } from './styles';
import AuthWidget from '../../../stories/auth/AuthWidget';

const Auth: FC = () => {
    const intl = useIntl();

    return (
        <Wrapper>
            <Helmet>
                <title>{intl.formatMessage({ defaultMessage: 'Вход в приложение' })}</title>
            </Helmet>
            <AuthWidget />
        </Wrapper>
    );
};

export default Auth;
