import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyMerchants } from 'src/store/Merchant/selectors';
import { ReactComponent as InvoicingIcon } from 'src/assets/icons/invoicing.svg';
import { ReactComponent as BillsIcon } from 'src/assets/icons/bills.svg';
import ProductCard from 'src/components/ProductCard';
import useModal from 'src/hooks/useModal';
import { getSettlementBalances } from 'src/store/MerchantSettlementBalance/selectors';
import { getCounterpartiesIsPending, getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { AddingMerchantContext } from 'src/components/ProductsAccordion/context';
import MerchantModalInner from 'src/pages/businessOffice/Introduce/components/MerchantModalInner';
import { ModalMerchantCreate } from 'src/components/ProductsAccordion/styles';
import { IMerchantModalValue, MerchantsModal } from './components/MerchantsModal';
import { AccessToInvoicingModal } from './components/AccessToInvoicing';
import { ProductCardSkeleton, ProductsGrid } from './styles';
import { BUSINESS_OFFICE_PATHS } from '../../../paths';

const Products: FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { isOpen: invoicingModalIsVisible, open: openModal, close: closeModal } = useModal();
    const {
        isOpen: isOpenModalCreatingMerchant,
        open: openModalCreatingMerchant,
        close: onCloseModalCreatingMerchant,
    } = useModal(false);

    const selectedCounterpartyId = useSelector(getCurrentCounterpartyId);
    const merchants = useSelector(getCurrentCounterpartyMerchants);
    const balances = useSelector(getSettlementBalances);
    const counterpartiesIsPending = useSelector(getCounterpartiesIsPending);

    const merchantModalSubmit = ({ merchantId }: IMerchantModalValue) => {
        navigate(BUSINESS_OFFICE_PATHS.shop(selectedCounterpartyId).overview(merchantId));
    };

    const ProductCardWithLoading = counterpartiesIsPending ? ProductCardSkeleton : ProductCard;

    const goToRoute = (link: string) => {
        navigate(link);
    };

    const addingMerchantContextProviderValue = useMemo(
        () => ({
            closeModal: onCloseModalCreatingMerchant,
        }),
        [onCloseModalCreatingMerchant],
    );

    return (
        <>
            <ProductsGrid>
                <ProductCardWithLoading
                    icon={<InvoicingIcon />}
                    hasWarning={!selectedCounterpartyId}
                    title={intl.formatMessage({ defaultMessage: 'Продажи' })}
                    onClick={openModal}
                />
                <ProductCardWithLoading
                    icon={<BillsIcon />}
                    title={intl.formatMessage({ defaultMessage: 'Входящие счета' })}
                    onClick={() => {
                        goToRoute(BUSINESS_OFFICE_PATHS.payment(selectedCounterpartyId));
                    }}
                />
            </ProductsGrid>
            {merchants && balances && selectedCounterpartyId && merchants.length > 0 ? (
                <MerchantsModal
                    closeModal={closeModal}
                    isOpen={invoicingModalIsVisible}
                    onSubmit={merchantModalSubmit}
                    merchants={merchants}
                    balances={balances}
                />
            ) : (
                <AccessToInvoicingModal
                    onClickCreateMerchant={openModalCreatingMerchant}
                    isCounterparty={Boolean(selectedCounterpartyId)}
                    closeModal={closeModal}
                    isOpen={invoicingModalIsVisible}
                />
            )}
            <ModalMerchantCreate
                title={intl.formatMessage({ defaultMessage: 'Добавить новый магазин' })}
                isOpen={isOpenModalCreatingMerchant}
                closeModal={onCloseModalCreatingMerchant}
            >
                <AddingMerchantContext.Provider value={addingMerchantContextProviderValue}>
                    <MerchantModalInner />
                </AddingMerchantContext.Provider>
            </ModalMerchantCreate>
        </>
    );
};

export default Products;
