import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 430px;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        gap: 20px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;

    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
    }
`;
