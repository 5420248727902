import React, { FC } from 'react';
import { Arrow, Drawer, Typography } from '@invoicebox/ui';
import * as S from './styles';
import useModal from '../../../../../../../hooks/useModal';

type TProps = {
    value: string;
    options: Array<{ label: string; name: string }>;
    onSelect: (value: string) => void;
};

const SelectHeadLabel: FC<TProps> = ({ value, options, onSelect }) => {
    const { isOpen, open, close } = useModal(false);

    const optionsWithoutCurrent = options.filter((option) => option.name !== value);
    const currentLabel = options.find((option) => option.name === value)?.label;

    return (
        <>
            <S.Wrapper type="button" onClick={open}>
                <Typography variant="headline5">{currentLabel}</Typography>
                <S.ArrowWrapper>
                    <Arrow isOpen={isOpen} outterSize={20} />
                </S.ArrowWrapper>
            </S.Wrapper>
            <Drawer onClose={close} isOpen={isOpen}>
                <S.Options>
                    {optionsWithoutCurrent.map((option) => (
                        <S.Option key={option.name} type="button" onClick={() => onSelect(option.name)}>
                            {option.label}
                        </S.Option>
                    ))}
                </S.Options>
            </Drawer>
        </>
    );
};

export default SelectHeadLabel;
