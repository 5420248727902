import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../stories/common/Header';
import { DashboardHeaderAction } from '../../stories/common/DashboardHeaderAction';
import { logout } from '../../store/Auth';
import { useAppDispatch } from '../../store';
import { getIdentifier, getUserFirstName, getUserLastName } from '../../store/Auth/selectors';
import { convertPhoneToShow } from '../../utils/formatters';
import { getAllCounterparties, getCurrentCounterparty } from '../../store/Counterparty/selectors';
import { HeaderUserDropdown } from '../../stories/common/HeaderUserDropdown';
import NotificationMenuItem from '../NotificationMenuItem';
import { BUSINESS_OFFICE_PATHS } from '../../pages/businessOffice/paths';

const DashboardHeader = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const firstName = useSelector(getUserFirstName) || '';
    const lastName = useSelector(getUserLastName) || '';
    const userIdentifier = useSelector(getIdentifier);
    const currentCounterparty = useSelector(getCurrentCounterparty);
    const counterparties = useSelector(getAllCounterparties);

    const [isOpenHeaderDropdown, setIsOpenHeaderDropdown] = useState(false);

    const identifier =
        userIdentifier?.identifier && userIdentifier?.type === 'phone'
            ? convertPhoneToShow(userIdentifier.identifier)
            : userIdentifier?.identifier || '';

    const logoutHandler = async () => {
        setIsOpenHeaderDropdown(false);
        dispatch(logout());
    };

    const goToProfile = () => {
        setIsOpenHeaderDropdown(false);
        navigate(BUSINESS_OFFICE_PATHS.profile(currentCounterparty?.id));
    };

    const selectOrganization = (counterpartyId?: string) => {
        setIsOpenHeaderDropdown(false);
        navigate(BUSINESS_OFFICE_PATHS.dashboard(counterpartyId));
    };

    return (
        <Header
            userActions={
                <DashboardHeaderAction
                    isOpen={isOpenHeaderDropdown}
                    setIsOpen={setIsOpenHeaderDropdown}
                    title={`${lastName} ${firstName ? `${firstName[0]}.` : ''}`}
                    subtitle={currentCounterparty ? currentCounterparty.name : identifier}
                    notificationItem={<NotificationMenuItem />}
                >
                    <HeaderUserDropdown
                        userFullName={`${lastName} ${firstName}`}
                        identifier={identifier}
                        onLogout={logoutHandler}
                        onProfileGoTo={goToProfile}
                        onOrganizationSelect={selectOrganization}
                        counterparties={counterparties}
                        selectedCounterparty={currentCounterparty}
                    />
                </DashboardHeaderAction>
            }
        />
    );
};

export default DashboardHeader;
