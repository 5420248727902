import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 12px;
    justify-content: space-between;
    padding: 0 16px 8px 8px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        flex-direction: column;
        align-items: flex-end;
        gap: 4px;
        padding: 0 12px 8px 4px;
    }
`;

export const ChildrenWrapper = styled.div``;

export const OrganizationData = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 16px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding-right: 4px;
    }
`;

export const Parameter = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 4px;
`;

export const Label = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.3)};
`;

export const Value = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.5)};
`;
