import { TDocumentGeneratorSetting } from './types';
import { assert } from '../../utils/assert';

export class DocumentGeneratorSetting {
    readonly id: string;
    readonly title: string;

    private constructor(state: TDocumentGeneratorSetting) {
        const { id, title } = state;

        this.id = id;
        this.title = title;
    }

    static matchById(id: string, documentGeneratorSettingArray: Array<DocumentGeneratorSetting>) {
        return documentGeneratorSettingArray.find((documentGeneratorItem) => documentGeneratorItem.id === id);
    }

    static fromResponse(response: unknown): DocumentGeneratorSetting {
        assert(typeof response === 'object' && response !== null);

        assert('id' in response);
        const { id } = response;
        assert(typeof id === 'string');

        let title = '';
        if ('title' in response) {
            assert(typeof response.title === 'string');
            title = response.title;
            assert(!!title.length);
        }

        const checkedResponse = {
            id,
            title,
        } satisfies TDocumentGeneratorSetting;

        return new DocumentGeneratorSetting(checkedResponse);
    }

    static fromMock(): DocumentGeneratorSetting {
        return new DocumentGeneratorSetting({
            id: 'merchant-invoice',
            title: 'Счёт на оплату по реквизитам организации',
        });
    }

    static fromMockArray(): Array<DocumentGeneratorSetting> {
        return [
            new DocumentGeneratorSetting({
                id: 'accepted-funds',
                title: 'Отчёт о принятых платежах',
            }),
            new DocumentGeneratorSetting({
                id: 'merchant-invoice',
                title: 'Счёт на оплату по реквизитам организации',
            }),
            new DocumentGeneratorSetting({
                id: 'invoice',
                title: 'Счёт на оплату через Инвойсбокс',
            }),
            new DocumentGeneratorSetting({
                id: 'merchant-attorney',
                title: 'Доверенность на оформление актов от имени магазина',
            }),
            new DocumentGeneratorSetting({
                id: 'merchant-contract-package',
                title: 'Пакет документов для контракта с мерчантом',
            }),
            new DocumentGeneratorSetting({
                id: 'merchant-contract-package-upload',
                title: 'Загружаемый пользователем документ для пакета документов для контракта с мерчантом',
            }),
        ];
    }
}
