import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from '@reduxjs/toolkit';
import { DocumentGeneratorSetting } from './entity';
import { getDocumentGeneratorSettingListRequest } from './http';

export const documentGeneratorSettingAdapter = createEntityAdapter<DocumentGeneratorSetting>();

const initialState = documentGeneratorSettingAdapter.getInitialState({
    isInitialized: false,
    isLoading: false,
    metaData: {
        totalCount: 0,
        pageSize: 0,
        page: 0,
    },
});

export const fetchDocumentGeneratorSettingList = createAsyncThunk(
    'documentGeneratorSettingSlice/fetchDocumentGeneratorSettingList',
    async () => {
        const { data } = await getDocumentGeneratorSettingListRequest();

        return data;
    },
);

const documentGeneratorSettingSlice = createSlice({
    name: 'documentGeneratorSetting',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDocumentGeneratorSettingList.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchDocumentGeneratorSettingList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isInitialized = true;
            state.metaData = payload.metaData;
            documentGeneratorSettingAdapter.setAll(
                state as EntityState<DocumentGeneratorSetting>,
                payload.data.map((documentFromResponse) => DocumentGeneratorSetting.fromResponse(documentFromResponse)),
            );
        });
        builder.addCase(fetchDocumentGeneratorSettingList.rejected, (state) => {
            state.isLoading = false;
            state.isInitialized = true;
        });
    },
});

export default documentGeneratorSettingSlice.reducer;
