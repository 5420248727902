import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { getUser } from '../store/Auth/selectors';
import { TAuthTypeFlow } from './constants';
import Auth from './businessOffice/Auth';
import { BUSINESS_OFFICE_PATHS } from './businessOffice/paths';

const AuthRoutes = () => {
    const user = useSelector(getUser);
    const { authTypeFlow } = useParams() as { authTypeFlow?: TAuthTypeFlow };
    const isAuth = !!user;
    const isNeedIntroduce = !user?.firstName && !user?.lastName;

    if (isAuth) {
        if (isNeedIntroduce) {
            return <Navigate to={BUSINESS_OFFICE_PATHS.introduce(authTypeFlow)} />;
        }
        return <Navigate to={BUSINESS_OFFICE_PATHS.dashboard()} />;
    }

    return <Auth />;
};

export default AuthRoutes;
