import React from 'react';
import { Skeleton, useMobile } from '@invoicebox/ui';
import * as S from '../styles';

const OrganizationSkeleton = () => {
    const isMobile = useMobile();

    return (
        <S.OrganizationAutocompleteSkeletonWrapper>
            <Skeleton height={isMobile ? '54px' : '94px'} />
        </S.OrganizationAutocompleteSkeletonWrapper>
    );
};

const arraySkeletonItems = new Array(3).fill(OrganizationSkeleton);
export const vatAutocompleteLoadingComponent = (
    <>
        {arraySkeletonItems.map((SkeletonItem, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SkeletonItem key={index} />
        ))}
    </>
);
