import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IntroduceStepContainer } from '../../../../../stories/introduce/IntroduceStepContainer';
import { OrganizationForm } from '../../../../../stories/introduce/OrganizationForm';
import { getCountriesList } from '../../../../../store/Country/selectors';
import { countryLogic } from '../../../../../store/Country/logic';
import useDictionary from '../../../../../hooks/useDictionarty';
import { DICTIONARY_TYPES } from '../../../../../store/Dictionaries/constants';
import { TLegalEntity } from '../../../../../store/Dictionaries/types';
import { getOptionsFromArray } from '../../../../../utils/helpers/getOptionsFromArray';
import { useAppDispatch } from '../../../../../store';
import { createCounterparty } from '../../../../../store/Counterparty';
import { ICounterparty, TCounterpartyCreate } from '../../../../../store/Counterparty/types';
import { TOrganization } from '../../../../../store/CounterpartyDetail/types';

type TProps = {
    onSkip: () => void;
    goToNextStep: () => void;
    defaultValues: Partial<TOrganization> & { countryId: string };
    onCounterpartySet: (counterparty: ICounterparty) => void;
    countSteps: number;
};

const CreateCounterpartyScreen: FC<TProps> = ({
    onSkip,
    goToNextStep,
    defaultValues,
    onCounterpartySet,
    countSteps,
}) => {
    const intl = useIntl();
    const dispatch = useAppDispatch();

    const countries = useSelector(getCountriesList);
    const countryOptions = countryLogic.getCountryOptions(countries, intl);
    const legalEntityTypeList: Array<TLegalEntity> = useDictionary(DICTIONARY_TYPES.legalEntityType)?.data as any;

    const legalEntityTypeOptions = getOptionsFromArray(legalEntityTypeList, 'id', 'name');

    const handleSubmit = (formValues: TCounterpartyCreate) =>
        dispatch(createCounterparty(formValues))
            .unwrap()
            .then((res) => {
                onCounterpartySet(res.data);
                goToNextStep();
            });

    return (
        <IntroduceStepContainer
            title={intl.formatMessage({
                id: 'Сведения об организации или ИП',
                defaultMessage: 'Сведения об организации или ИП',
            })}
            subtitle={intl.formatMessage({
                id: 'Пожалуйста, выберите страну регистрации и укажите ИНН',
                defaultMessage: 'Пожалуйста, выберите страну регистрации и укажите ИНН',
            })}
            countSteps={countSteps}
            activeStep={2}
        >
            <OrganizationForm
                onSubmit={handleSubmit}
                onSkip={onSkip}
                countryOptions={countryOptions}
                legalEntityTypeOptions={legalEntityTypeOptions}
                defaultValues={defaultValues}
            />
        </IntroduceStepContainer>
    );
};

export default CreateCounterpartyScreen;
