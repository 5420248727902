import React from 'react';
import Breadcrumb from 'src/components/Breadcrumb';
import PaymentGeneral from 'src/pages/businessOffice/Payment/components/PaymentGeneral';
import { useIntl } from 'react-intl';
import { BreadcrumbToDashboard } from '../../../components/BreadcrumbToDashboard';

const Payment = () => {
    const intl = useIntl();

    return (
        <>
            <Breadcrumb>
                <BreadcrumbToDashboard />
                <Breadcrumb.Item>{intl.formatMessage({ defaultMessage: 'Входящие счета' })}</Breadcrumb.Item>
            </Breadcrumb>
            <PaymentGeneral />
        </>
    );
};

export default Payment;
