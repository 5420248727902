import React, { FC, useEffect, useState } from 'react';
import {
    AllNavLink,
    Header,
    NotificationsList,
    Title,
    Wrapper,
} from 'src/components/NotificationMenuItem/components/NotificationWindow/styles';
import NotificationRow from 'src/components/NotificationMenuItem/components/NotificationRow';
import { IFeedItem } from 'src/store/Feed/types';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useOutsideClick } from '@invoicebox/ui';
import { useSelector } from 'react-redux';
import { BUSINESS_OFFICE_PATHS } from '../../../../pages/businessOffice/paths';
import { getCurrentCounterpartyId } from '../../../../store/Counterparty/selectors';

interface INotificationWindow {
    feeds: IFeedItem[];
    setIsShowWindowNotifications: (arg: boolean) => void;
    isShowWindowNotifications: boolean;
}

const NotificationWindow: FC<INotificationWindow> = ({
    isShowWindowNotifications,
    feeds,
    setIsShowWindowNotifications,
}) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [savedFeeds, setSavedFeeds] = useState<IFeedItem[]>([]);

    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const hideWindowNotification = () => setIsShowWindowNotifications(false);

    const windowRef = useOutsideClick(() => {
        hideWindowNotification();
    });

    useEffect(() => {
        if (feeds.length > 0) {
            setSavedFeeds(feeds);
        }
    }, [feeds]);

    const onClickAllFeeds = () => {
        navigate(BUSINESS_OFFICE_PATHS.feeds(counterpartyId));
        hideWindowNotification();
    };

    if (isShowWindowNotifications) {
        return (
            <Wrapper ref={windowRef}>
                <Header>
                    <Title variant="title">{intl.formatMessage({ defaultMessage: 'Уведомления' })}</Title>
                    <AllNavLink onClick={onClickAllFeeds}>{intl.formatMessage({ defaultMessage: 'Все' })}</AllNavLink>
                </Header>
                <NotificationsList>
                    {savedFeeds.map((feed) => (
                        <NotificationRow hideWindowNotification={hideWindowNotification} key={feed.id} feed={feed} />
                    ))}
                </NotificationsList>
            </Wrapper>
        );
    }
    return null;
};

export default NotificationWindow;
