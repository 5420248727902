import React, { FC, useCallback } from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { SearchInput, TSearchInputProps } from '@invoicebox/ui';

export type TProps = Partial<Pick<TSearchInputProps, 'onChange'>> &
    Omit<TSearchInputProps, 'value' | 'onChange'> & {
        name: string;
    };

export const RHFSearchInput: FC<TProps> = ({ name, onChange, ...other }) => {
    const { control } = useFormContext();

    const getChangeHandler = useCallback(
        (func) =>
            (...props: any[]) => {
                // @ts-ignore
                if (onChange) onChange(...props);
                return func(...props);
            },
        [onChange],
    );

    const render = useCallback(
        ({ field }: { field: ControllerRenderProps; fieldState: ControllerFieldState }) => (
            <SearchInput
                {...other}
                onChange={getChangeHandler(field.onChange)}
                onBlur={field.onBlur}
                value={field.value || ''}
            />
        ),
        [getChangeHandler, other],
    );

    return <Controller name={name} control={control} render={render} />;
};
