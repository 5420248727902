import styled from 'styled-components';
import Modal from 'src/components/Modal';

export const ModalSearchWrapper = styled(Modal)`
    width: 642px;
    padding: 30px 50px;

    overflow-y: auto;
    height: 688px;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.38);
    }
    scrollbar-color: rgba(0, 0, 0, 0.38);
    scrollbar-width: thin;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 0;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    margin-bottom: 30px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin: 10px 20px 30px 20px;
    }
`;

export const ButtonSearch = styled.button`
    position: absolute;
    padding-top: 10px;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
`;

export const ButtonAddNewOrganization = styled.button`
    ${({ theme }) => theme.mixins.getTypography('regular14')};
    ${({ theme }) => theme.mixins.flexCenter}
    grid-column-gap: 10px;
    padding: 13px 15px;
    border: 1px solid ${({ theme }) => theme.colors.grey(0.3)};
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    margin: 0 auto;
    path {
        stroke: ${({ theme }) => theme.colors.base()};
    }
`;

export const CounterpartiesWrapper = styled.div`
    margin-top: 52px;
    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-top: 30px;
    }
`;
