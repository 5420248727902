import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 12px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.base(0.1)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    &:last-child {
        border: none;
    }
`;

export const Title = styled.div`
    padding: 4px 12px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.base}px;
    border: 1px solid #676f6a;
    color: #676f6a;
    display: inline-block;
`;

export const DocumentsList = styled.div`
    padding: 12px;
    background-color: ${({ theme }) => theme.colors.wrongGrey100()};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
`;
