import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { OrganizationAutocompleteItem } from '@invoicebox/ui';
import { RHFAutocomplete } from '../../RHFAutocomplete';
import { useFetchCounterparty } from './useFetchCounterparty';
import { INDIVIDUAL_VAT_NUMBER_LENGTH } from '../../../../utils/constants';
import { TOrganization } from '../../../../store/CounterpartyDetail/types';

export type TProps = {
    name: string;
};

export const RHFRusVatNumberAutocomplete: FC<TProps> = ({ name }) => {
    const intl = useIntl();
    const [searchedVatNumber, setSearchedVatNumber] = useState('');

    const { options, isLoading } = useFetchCounterparty(searchedVatNumber);

    const renderVatAutocompleteItem = ({ entity }: { entity?: any }) => {
        const organization = entity as TOrganization;

        return (
            <OrganizationAutocompleteItem
                name={organization.name || ''}
                vatNumber={organization.vatNumber}
                taxRegistrationReasonCode={organization.taxRegistrationReasonCode}
                taxRegistrationReasonCodeLabel={intl.formatMessage({ id: 'КПП:', defaultMessage: 'КПП:' })}
                vatNumberLabel={intl.formatMessage({
                    id: 'ИНН:',
                    defaultMessage: 'ИНН:',
                })}
                registrationAddress={organization.registrationAddress || ''}
            />
        );
    };

    const vatNumberChange = (vatNumber: string) => {
        setSearchedVatNumber(vatNumber.replace(/[^\d]/g, ''));
    };

    const autocompleteOptions = options.filter((option) => option.value === searchedVatNumber);

    return (
        <RHFAutocomplete
            name={name}
            options={autocompleteOptions}
            onChange={vatNumberChange}
            label={intl.formatMessage({
                id: 'ИНН организации или ИП',
                defaultMessage: 'ИНН организации или ИП',
            })}
            renderOption={renderVatAutocompleteItem}
            isLoading={isLoading}
            inputMaxLength={INDIVIDUAL_VAT_NUMBER_LENGTH}
        />
    );
};
