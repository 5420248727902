import styled from 'styled-components';

export const Wrapper = styled.div<{ $iconColor: string }>`
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    padding: 20px;

    path,
    rect {
        stroke: ${({ $iconColor }) => $iconColor};
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px;
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: ${({ theme }) => theme.colors.base(0.5)};
`;
