import React, { FC } from 'react';
import {
    ClientItemWrapper,
    Email,
    NameWrapper,
    Phone,
    VatNumber,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/Clients/components/ClientListItem/styles';
import { IClient } from 'src/store/Client/types';
import Avatar, { AVATAR_SIZE, AVATAR_VARIANT } from 'src/components/Avatar';
import { CLIENT_FIELDS } from 'src/store/Client/contants';
import { convertPhoneToShow } from 'src/utils/formatters';
import { IClientCounterparty } from 'src/store/ClientCounterparty/types';
import { CLIENT_COUNTERPARTY_FIELDS } from 'src/store/ClientCounterparty/constants';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { typesClients } from '../../index';
import { getCurrentCounterpartyId } from '../../../../../../../store/Counterparty/selectors';
import { BUSINESS_OFFICE_PATHS } from '../../../../../paths';

interface IClientListItem {
    client: IClient | IClientCounterparty;
    typeClient: typesClients;
}

const ClientListItem: FC<IClientListItem> = ({ typeClient, client }) => {
    const { merchantId } = useParams() as { merchantId: string };

    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const clientName = client[CLIENT_FIELDS.name];
    const isClientCounterparty = typeClient === typesClients.CLIENT_COUNTERPARTY;
    const uniqPathOnClickClient = isClientCounterparty
        ? BUSINESS_OFFICE_PATHS.shop(counterpartyId).detailClientCounterparty(merchantId, String(client.id))
        : BUSINESS_OFFICE_PATHS.shop(counterpartyId).detailClient(merchantId, String(client.id));

    const renderDescription = (renderingClient: IClient | IClientCounterparty) => {
        if (isClientCounterparty) {
            const clientCounterparty: IClientCounterparty = renderingClient as any;
            return (
                <VatNumber variant="regular14">{clientCounterparty[CLIENT_COUNTERPARTY_FIELDS.vatNumber]}</VatNumber>
            );
        }
        const clientData: IClient = renderingClient as any;
        return (
            <>
                <Email variant="regular14">{clientData[CLIENT_FIELDS.email]}</Email>
                <Phone variant="regular14">
                    {clientData[CLIENT_FIELDS.phone] && convertPhoneToShow(clientData[CLIENT_FIELDS.phone])}
                </Phone>
            </>
        );
    };

    return (
        <ClientItemWrapper to={uniqPathOnClickClient} $isClientCounterparty={isClientCounterparty}>
            <NameWrapper>
                <Avatar
                    isColumnPosition={false}
                    size={AVATAR_SIZE.xs}
                    name={clientName}
                    variant={AVATAR_VARIANT.dark}
                />
                {clientName}
            </NameWrapper>
            {renderDescription(client)}
        </ClientItemWrapper>
    );
};

export default ClientListItem;
