import styled from 'styled-components';

export const RowCell = styled.td`
    padding: 12px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 12px 6px;
    }
`;

export const TableRowWrapper = styled.tr<{ $isShowBorder: boolean; $isPointer: boolean }>`
    background-color: ${({ theme }) => theme.colors.white()};
    color: ${({ theme }) => theme.colors.base(0.7)};
    border-bottom: ${({ $isShowBorder, theme }) => ($isShowBorder ? `1px solid ${theme.colors.base(0.1)}` : 'none')};

    ${({ $isPointer }) => $isPointer && `cursor: pointer`};

    td:first-of-type {
        padding-left: 20px;
    }

    td:last-of-type {
        padding-right: 20px;
    }
`;

export const CheckboxRowWrapper = styled.td`
    width: 20px;
    padding-right: 8px;
`;

export const ArrowIconWrapper = styled.td`
    width: 18px;
    vertical-align: middle;
    padding: 12px;

    svg {
        padding: 0;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 12px 6px;
    }
`;

export const DescriptionWrapper = styled.td`
    padding-bottom: 12px;
`;

export const Description = styled.div`
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.wrongGrey100()};
    padding: 4px 8px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 12px;
    }
`;
