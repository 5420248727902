import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';
import Button from '../../../../../stories/common/Button';

export const Wrapper = styled.div`
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.white()};
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    @media ${({ theme }) => theme.breakpoints.ld} {
        padding: 20px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        flex: 1;
    }
`;

export const TaskContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const TaskDataWrapper = styled.div`
    background: #fbfbfe;
    border-radius: 10px;
    padding: 24px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const PropertyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const PropertyLabel = styled(Typography)`
    color: ${({ theme }) => theme.colors.base(0.5)};
`;

export const OtherWaySendButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    margin-bottom: 0;

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-top: 32px;
    }
`;

export const ButtonOtherWaySend = styled(Button)`
    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 13px 24px;
    }
`;

export const MobileButtonOpenTaskListWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const MobileButtonOpenTaskList = styled.button`
    padding: 12px;
    border-radius: 24px;
    background: ${({ theme }) => theme.colors.grey100()};
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 8px;
`;
