import React, { FC } from 'react';
import * as S from './styles';
import Radio from '../../../components/Radio';

export type TProps = {
    title: string;
    date: string;
    countDocument: string;
    checked: boolean;
    onClick: () => void;
};

export const TaskRow: FC<TProps> = ({ title, countDocument, date, checked, onClick }) => (
    <S.Wrapper>
        <S.Head>
            <S.TitleWrapper>
                <Radio variant="small" checked={checked} onChange={onClick} />
                <S.Title variant="bodyMRegular">{title}</S.Title>
            </S.TitleWrapper>
            <S.Date variant="bodyMRegular">{date}</S.Date>
        </S.Head>
        <S.Subtitle variant="captionRegular">{countDocument}</S.Subtitle>
    </S.Wrapper>
);
