import { useContext } from 'react';
import { SearchParamsContext } from '../context/SearchParamsContext';

const useSearchQuery = () => {
    const context = useContext(SearchParamsContext);

    if (!context) throw new Error('SearchParamsContext must be use inside SearchParamsProvider');

    return context;
};

export default useSearchQuery;
