import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    gap: 4px;
    align-items: center;
    flex-wrap: wrap;

    @media ${({ theme }) => theme.breakpoints.sm} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const DividerWrapper = styled.div`
    padding: 4px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;

export const Divider = styled.div`
    background: #c3c6c8;
    width: 2px;
    height: 2px;
`;

export const DocumentItemWithDividerWrapper = styled.div`
    display: contents;

    @media ${({ theme }) => theme.breakpoints.sm} {
        display: block;
        width: 100%;
    }
`;
