import { IntlShape } from 'react-intl';
import {
    aboutInvoiceBoxLink,
    amlpolicyInvoiceBoxLink,
    apkLink,
    contactsInvoiceBoxLink,
    corprulesInvoiceBoxLink,
    newsInvoiceBoxLink,
    offerInvoiceBoxLink,
    partnersInvoiceBoxLink,
    playMarketAppLink,
    pressInvoiceBoxLink,
    privacyInvoiceBoxLink,
    ruStoreAppLink,
    supportInvoiceBoxLink,
} from '../../../utils/constants';

export const appLinks = [
    {
        href: playMarketAppLink,
        variant: 'googlePlay' as const,
    },
    {
        href: apkLink,
        variant: 'apk' as const,
    },
    {
        href: '',
        variant: 'appGallery' as const,
    },
    {
        href: ruStoreAppLink,
        variant: 'ruStore' as const,
    },
].filter((i) => !!i.href);

export const getMainLinks = (intl: IntlShape) => [
    {
        href: aboutInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'О компании',
            defaultMessage: 'О компании',
        }),
    },
    {
        href: newsInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Новости',
            defaultMessage: 'Новости',
        }),
    },
    {
        href: partnersInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Партнёры',
            defaultMessage: 'Партнёры',
        }),
    },
    {
        href: pressInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Для прессы',
            defaultMessage: 'Для прессы',
        }),
    },
    {
        href: supportInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Поддержка',
            defaultMessage: 'Поддержка',
        }),
    },
    {
        href: contactsInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Контакты',
            defaultMessage: 'Контакты',
        }),
    },
];

export const getSecondaryLinks = (intl: IntlShape) => [
    {
        href: offerInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Оферта',
            defaultMessage: 'Оферта',
        }),
    },
    {
        href: corprulesInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Правила оказания услуг',
            defaultMessage: 'Правила оказания услуг',
        }),
    },
    {
        href: privacyInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Обработка персональных данных',
            defaultMessage: 'Обработка персональных данных',
        }),
    },
    {
        href: amlpolicyInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Политика по борьбе с отмыванием денег',
            defaultMessage: 'Политика по борьбе с отмыванием денег',
        }),
    },
];

export const getTabletSecondaryLinks = (intl: IntlShape) => [
    {
        href: offerInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Оферта',
            defaultMessage: 'Оферта',
        }),
    },
    {
        href: supportInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Поддержка',
            defaultMessage: 'Поддержка',
        }),
    },
    {
        href: privacyInvoiceBoxLink,
        label: intl.formatMessage({
            id: 'Политика ОПД',
            defaultMessage: 'Политика ОПД',
        }),
    },
];
