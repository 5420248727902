import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderContentPage, TProps as THeaderContentPageProps } from '../../stories/docs/HeaderContentPage';
import { DOCS_PATHS } from '../../pages/docs/paths';

type TProps = Pick<THeaderContentPageProps, 'title' | 'onBack'> & {
    isHaveStatusButton?: boolean;
};

const DocsHeaderContentPageWidget: FC<TProps> = ({ onBack, isHaveStatusButton = true, title }) => {
    const navigate = useNavigate();

    const handleDocumentStatusCheck = () => {
        navigate(DOCS_PATHS.statuses);
    };

    return (
        <HeaderContentPage
            title={title}
            onDocumentStatusCheck={isHaveStatusButton ? handleDocumentStatusCheck : undefined}
            onBack={onBack}
        />
    );
};

export default DocsHeaderContentPageWidget;
