import React, { FC, ReactNode } from 'react';
import { Arrow, Dropdown, useOutsideClick } from '@invoicebox/ui';
import { useTheme } from 'styled-components';
import * as S from './styles';
import { ReactComponent as SearchIcon } from './assets/search.svg';

export type TProps = {
    title: string;
    subtitle: string;
    children: ReactNode;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    notificationItem: ReactNode;
    onSearchClick?: () => void;
};

export const DashboardHeaderAction: FC<TProps> = ({
    subtitle,
    title,
    children,
    onSearchClick,
    isOpen,
    setIsOpen,
    notificationItem,
}) => {
    const theme = useTheme();

    const toggleOpen = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);

    const wrapperRef = useOutsideClick(close);

    const arrowPalette = {
        close: theme.colors.base(),
        open: theme.colors.base(),
    };

    return (
        <S.Wrapper>
            <S.IconActionsWrapper>
                {notificationItem}
                {onSearchClick && (
                    <S.IconButton type="button" onClick={onSearchClick}>
                        <SearchIcon />
                    </S.IconButton>
                )}
            </S.IconActionsWrapper>
            <S.UserButtonWrapper ref={wrapperRef}>
                <S.UserButton type="button" onClick={toggleOpen}>
                    <S.UserData>
                        <S.Title variant="buttonM">{title}</S.Title>
                        <S.Subtitle variant="captionRegular">{subtitle}</S.Subtitle>
                    </S.UserData>
                    <Arrow palette={arrowPalette} isOpen={isOpen} />
                </S.UserButton>
                <Dropdown isOpen={isOpen} width="254px" positionLeft="auto" positionRight="0px">
                    {children}
                </Dropdown>
            </S.UserButtonWrapper>
        </S.Wrapper>
    );
};
