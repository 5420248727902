import React, { FC, useCallback } from 'react';
import { Controller, ControllerFieldState, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { TextInput, TTextInputProps } from '@invoicebox/ui';

export type TProps = Omit<TTextInputProps, 'onChange' | 'value' | 'hasError' | 'onBlur' | 'onFocus'> & {
    name: string;
};

export const RHFTextInput: FC<TProps> = ({ name, ...other }) => {
    const { control } = useFormContext();

    const render = useCallback(
        ({
            field,
            fieldState: { error, isTouched },
        }: {
            field: ControllerRenderProps;
            fieldState: ControllerFieldState;
        }) => (
            <TextInput
                {...other}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value || ''}
                hasError={!!error && isTouched}
            />
        ),
        [other],
    );

    return <Controller name={name} control={control} render={render} />;
};
