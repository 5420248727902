import React, { FC } from 'react';
import FormField, { FormFieldTypeEnum } from 'src/components/FormField';
import { Form } from 'react-final-form';
import { IClientCounterparty } from 'src/store/ClientCounterparty/types';
import useValidationSchema from 'src/hooks/useValidationSchema';
import { ModalAddCounterpartySchema } from 'src/pages/businessOffice/Shop/validations';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from 'src/store/Counterparty/selectors';
import { FormCreateCounterparty } from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/FormEditClientCounterparty/styles';
import { COUNTERPARTY_FIELDS } from 'src/store/Counterparty/contants';
import { ORDER_CUSTOMER_FIELDS } from 'src/store/Order/types';
import { ICounterpartyDetails } from 'src/components/InputAutocomplete/types';
import { createClientCounterparty, updateClientCounterparty } from 'src/store/ClientCounterparty';
import { CLIENT_COUNTERPARTY_FIELDS } from 'src/store/ClientCounterparty/constants';
import { useIntl } from 'react-intl';
import { BUSINESS_OFFICE_PATHS } from '../../../../../paths';

interface IFormEditClientCounterparty {
    formId: string;
    setIsEditSettings: (arg: boolean) => void;
    clientCounterparty: IClientCounterparty | undefined;
    toggleSaving: () => void;
}

const FormEditClientCounterparty: FC<IFormEditClientCounterparty> = ({
    formId,
    setIsEditSettings,
    clientCounterparty,
    toggleSaving,
}) => {
    const intl = useIntl();
    const validation = useValidationSchema(ModalAddCounterpartySchema);
    const { merchantId, clientCounterpartyId } = useParams() as { merchantId: string; clientCounterpartyId: string };
    const dispatch = useAppDispatch();
    const currentCounterpartyId = useSelector(getCurrentCounterpartyId);
    const navigate = useNavigate();

    const onSubmit = async (formValues: IClientCounterparty) => {
        toggleSaving();
        if (clientCounterpartyId && clientCounterparty) {
            const result = await dispatch(updateClientCounterparty(formValues));
            // @ts-ignore
            if (!result?.error && result?.payload) {
                setIsEditSettings(false);
            }
        } else {
            const result = await dispatch(
                createClientCounterparty({
                    ...formValues,
                    [CLIENT_COUNTERPARTY_FIELDS.ownerCounterpartyId]: String(currentCounterpartyId),
                }),
            );
            // @ts-ignore
            if (!result?.error && result?.payload) {
                navigate(
                    BUSINESS_OFFICE_PATHS.shop(currentCounterpartyId).detailClientCounterparty(
                        merchantId,
                        result?.payload.id,
                    ),
                );
            }
        }
        toggleSaving();
    };
    return (
        <Form onSubmit={onSubmit} initialValues={clientCounterparty} validate={validation}>
            {({ handleSubmit }) => (
                <FormCreateCounterparty id={formId} onSubmit={handleSubmit}>
                    <FormField
                        name={COUNTERPARTY_FIELDS.name}
                        label={intl.formatMessage({ defaultMessage: 'Наименование' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите наименование' })}
                    />
                    <FormField
                        fieldType={FormFieldTypeEnum.autoCompleteVat}
                        name={ORDER_CUSTOMER_FIELDS.vatNumber}
                        label={intl.formatMessage({ defaultMessage: 'ИНН' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите номер ИНН' })}
                        autoFill={({ name, registrationAddress }: ICounterpartyDetails) => ({
                            [ORDER_CUSTOMER_FIELDS.name]: name,
                            [ORDER_CUSTOMER_FIELDS.registrationAddress]: registrationAddress,
                        })}
                    />
                    <FormField
                        name={COUNTERPARTY_FIELDS.registrationAddress}
                        label={intl.formatMessage({ defaultMessage: 'Адрес' })}
                        placeholder={intl.formatMessage({ defaultMessage: 'Введите адрес регистрации' })}
                    />
                </FormCreateCounterparty>
            )}
        </Form>
    );
};

export default FormEditClientCounterparty;
