import styled from 'styled-components';
import Button from '../../../../../stories/common/Button';

export const InputsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 20px 0;

    @media ${({ theme }) => theme.breakpoints.sm} {
        grid-template-columns: 1fr;
        gap: 16px;
        padding: 0;
    }
`;

export const Buttons = styled.div`
    padding: 10px 0;
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: flex-end;
    gap: 12px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        position: fixed;
        bottom: 16px;
        left: 0;
        padding: 0 16px;
        width: 100%;
    }
`;

export const FormButton = styled(Button)`
    border-radius: 50px;
    padding: 8px 16px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 13px 24px;
    }
`;
