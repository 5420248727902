import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import * as S from './styles';
import { ReactComponent as TimeIcon } from './assets/time.svg';
import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ReactComponent as DownloadIcon } from './assets/download.svg';

export type TProps = {
    isDone: boolean;
    label: string;
    onDownload?: () => void;
};

export const DocumentTypeLabel: FC<TProps> = ({ isDone, label, onDownload }) => (
    <S.Wrapper>
        <S.Content>
            {isDone ? <CheckIcon /> : <TimeIcon />}
            <Typography variant="captionRegular">{label}</Typography>
        </S.Content>

        {onDownload && (
            <S.DownloadButton type="button" onClick={onDownload}>
                <DownloadIcon />
            </S.DownloadButton>
        )}
    </S.Wrapper>
);
