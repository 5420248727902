import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import { ReactComponent as CheckIcon } from './assets/check.svg';
import { Wrapper } from './styles';

export type TProps = {
    color: string;
    backgroundColor: string;
    isCheckIcon?: boolean;
};

const Tag: FC<TProps> = ({ children, color, backgroundColor, isCheckIcon }) => (
    <Wrapper $color={color} $backgroundColor={backgroundColor}>
        <Typography variant="smallMedium">{children}</Typography>
        {isCheckIcon && <CheckIcon />}
    </Wrapper>
);

export default Tag;
