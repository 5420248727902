import * as yup from 'yup';
import { addMinutesToDate, convertCurrencyToPointNumber } from 'src/utils/helpers';
import { INDIVIDUAL_OGRNIP, ORGANISATION_OGRN } from 'src/utils/constants';
import { createIntl, createIntlCache } from 'react-intl';
import { Validate } from '@invoicebox/ui';
import ruMessages from '../i18/ru';

const cache = createIntlCache();
const intl = createIntl({ locale: 'ru', messages: ruMessages }, cache);

// eslint-disable-next-line func-names
yup.addMethod(yup.date, 'isNotToDayAndFifteenMinutesAfter', function () {
    // eslint-disable-next-line func-names
    return this.test('isNotToDayAndFifteenMinutesAfter', '', function (value: Date = new Date()) {
        const { createError, path } = this as yup.TestContext;
        const currentDataAndFifteenMinutes = addMinutesToDate(new Date(), 15);
        if (currentDataAndFifteenMinutes < value) {
            return true;
        }
        return createError({
            path,
            message: intl.formatMessage({
                id: '7jOaZA',
                defaultMessage: 'Дата должна быть не раньше текущего времени + 15 минут',
            }),
        });
    });
});

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidMinPrice', function () {
    // eslint-disable-next-line func-names
    return this.test('isValidMinPrice', '', function (value: string = '') {
        const { createError, path } = this as yup.TestContext;
        if (convertCurrencyToPointNumber(value) > 0) {
            return true;
        }
        return createError({
            path,
            message: intl.formatMessage({
                id: 'BWnmAa',
                defaultMessage: `Число должно быть не меньше 0.01`,
            }),
        });
    });
});

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidPhone', function () {
    // eslint-disable-next-line func-names
    return this.test('isValidPhone', '', function (value: string = '') {
        if (value === '') {
            return true;
        }
        const { createError, path } = this as yup.TestContext;
        const valueWithoutNumbers = value.replace(/\d/gm, '');
        if (value.length - valueWithoutNumbers.length === 11) {
            return true;
        }
        return createError({
            path,
            message: intl.formatMessage({
                id: 'sCLdLh',
                defaultMessage: 'Некорректный номер',
            }),
        });
    });
});

yup.addMethod(yup.string, 'isValidInn', function schemaMethod() {
    return this.test('isValidInn', '', function stringSchemaFunc(value: string = '') {
        const {
            createError,
            path,
            parent: { countryId },
        } = this as yup.TestContext;

        return Validate.vatNumber(value, path, countryId, createError);
    });
});

yup.addMethod(yup.string, 'isValidWebsite', function schemaMethod() {
    return this.test('isValidWebsite', '', function stringSchemaFunc(value: string = '') {
        const { createError, path } = this as yup.TestContext;

        return Validate.website(value, path, createError);
    });
});

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidOgrn', function () {
    // @ts-ignore
    // eslint-disable-next-line func-names
    return this.test('isValidOgrn', '', function (value: string) {
        // @ts-ignore
        const { createError, path } = this as yup.TestContext;
        const ogrn = value || '';

        if (ogrn === '') {
            return true;
        }

        if (/[^0-9]/.test(ogrn)) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'aA4Fwf',
                    defaultMessage: 'ОГРН может состоять только из цифр',
                }),
            });
        }

        if (ogrn.length !== ORGANISATION_OGRN) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'C6rnQv',
                    defaultMessage: 'ОГРН может состоять только из 13 цифр',
                }),
            });
        } else {
            const n13 = parseInt((parseInt(ogrn.slice(0, -1), 10) % 11).toString().slice(-1), 10);

            if (n13 === parseInt(ogrn[12], 10)) {
                return true;
            } else {
                return createError({
                    path,
                    message: intl.formatMessage({
                        id: 'h0Chh6',
                        defaultMessage: 'Неправильное контрольное число',
                    }),
                });
            }
        }
    });
});

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidOgrnip', function () {
    // @ts-ignore
    // eslint-disable-next-line func-names
    return this.test('isValidOgrnip', '', function (value: string) {
        // @ts-ignore
        const { createError, path } = this as yup.TestContext;
        const ogrnip = value || '';

        if (ogrnip === '') {
            return true;
        }

        if (/[^0-9]/.test(ogrnip)) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: '/DCgTj',
                    defaultMessage: 'ОГРНИП может состоять только из цифр',
                }),
            });
        }

        if (ogrnip.length !== INDIVIDUAL_OGRNIP) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'GelxEX',
                    defaultMessage: 'ОГРНИП может состоять только из 15 цифр',
                }),
            });
        } else {
            const n15 = parseInt((parseInt(ogrnip.slice(0, -1), 10) % 13).toString().slice(-1), 10);

            if (n15 === parseInt(ogrnip[14], 10)) {
                return true;
            } else {
                return createError({
                    path,
                    message: intl.formatMessage({
                        id: 'h0Chh6',
                        defaultMessage: 'Неправильное контрольное число',
                    }),
                });
            }
        }
    });
});

yup.addMethod(yup.string, 'isValidKpp', function schemaMethod() {
    return this.test('isValidKpp', '', function stringSchemaFunc(kpp: string = '') {
        const { createError, path } = this as yup.TestContext;

        return Validate.taxRegistrationReasonCode(kpp, path, createError);
    });
});

yup.addMethod(yup.string, 'isValidRegistrationNumber', function schemaMethod() {
    return this.test('isValidRegistrationNumber', '', function stringSchemaFunc(registrationNumber: string = '') {
        const {
            createError,
            path,
            parent: { vatNumber, countryId },
        } = this as yup.TestContext;

        return Validate.registrationNumber(registrationNumber, path, vatNumber, countryId, createError);
    });
});

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidBik', function () {
    // @ts-ignore
    // eslint-disable-next-line func-names
    return this.test('isValidBik', '', function (value: string) {
        // @ts-ignore

        const { createError, path } = this as yup.TestContext;

        if (/[^0-9]/.test(value)) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'Amdw8L',
                    defaultMessage: 'БИК может состоять только из цифр',
                }),
            });
        }

        if (value.length !== 9) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'tkcHSF',
                    defaultMessage: 'БИК может состоять только из 9 цифр',
                }),
            });
        }
        return true;
    });
});

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidKs', function () {
    // @ts-ignore
    // eslint-disable-next-line func-names
    return this.test('isValidKs', '', function (value: string) {
        const {
            createError,
            path,
            parent: { bic },
        } = this as yup.TestContext;

        if (/[^0-9]/.test(value)) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 's1jAMo',
                    defaultMessage: 'Кор. счёт может состоять только из цифр',
                }),
            });
        }

        if (value.length !== 20) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'rHrsMF',
                    defaultMessage: 'Кор. счёт может состоять только из 20 цифр',
                }),
            });
        }

        let checksum = 0;
        const bikKs = `0${String(bic).slice(4, 6)}${value}`;
        const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

        coefficients.forEach((coefficient, i) => {
            // @ts-ignore
            checksum += coefficient * (bikKs[i] % 10);
        });

        if (checksum % 10 !== 0) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'h0Chh6',
                    defaultMessage: 'Неправильное контрольное число',
                }),
            });
        }

        return true;
    });
});

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidRs', function () {
    // @ts-ignore
    // eslint-disable-next-line func-names
    return this.test('isValidRs', '', function (value: string) {
        // @ts-ignore
        const {
            createError,
            path,
            parent: { bic },
        } = this as yup.TestContext;

        if (!bic) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'u6KeoV',
                    defaultMessage: 'Введите корректный БИК',
                }),
            });
        }

        if (bic.length < 9) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'u6KeoV',
                    defaultMessage: 'Введите корректный БИК',
                }),
            });
        }

        if (/[^0-9]/.test(value)) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'iGF9UW',
                    defaultMessage: 'Расчётный счёт может состоять только из цифр',
                }),
            });
        }

        if (value.length !== 20) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'mhWiRY',
                    defaultMessage: 'Расчётный счёт может состоять только из 20 цифр',
                }),
            });
        }

        let checksum = 0;
        const bikRs = bic.toString().slice(-3) + value;
        const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];

        coefficients.forEach((coefficient, i) => {
            // @ts-ignore
            checksum += coefficient * (bikRs[i] % 10);
        });

        if (checksum % 10 !== 0) {
            return createError({
                path,
                message: intl.formatMessage({
                    id: 'h0Chh6',
                    defaultMessage: 'Неправильное контрольное число',
                }),
            });
        }
        return true;
    });
});

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidPassSer', function () {
    // eslint-disable-next-line func-names
    return this.test('isValidPassSer', '', function (value: string = '') {
        const { createError, path } = this as yup.TestContext;
        const valueOnlyNumbers = value.replace(/[^0-9]/g, '');
        if (valueOnlyNumbers === value && valueOnlyNumbers.length === 4) {
            return true;
        }
        return createError({
            path,
            message: intl.formatMessage({
                id: 'WK9moF',
                defaultMessage: 'Некорректная серия',
            }),
        });
    });
});

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidPassNum', function () {
    // eslint-disable-next-line func-names
    return this.test('isValidPassNum', '', function (value: string = '') {
        const { createError, path } = this as yup.TestContext;
        const valueOnlyNumbers = value.replace(/[^0-9]/g, '');
        if (valueOnlyNumbers === value && valueOnlyNumbers.length === 6) {
            return true;
        }
        return createError({
            path,
            message: intl.formatMessage({
                id: 'ZB4Jx6',
                defaultMessage: 'Некорректнй номер',
            }),
        });
    });
});

// eslint-disable-next-line func-names
yup.addMethod(yup.string, 'isValidIssuedPlaceCode', function () {
    // eslint-disable-next-line func-names
    return this.test('isValidIssuedPlaceCode', '', function (value: string = '') {
        const { createError, path } = this as yup.TestContext;
        const valueOnlyNumbers = value.replace(/[^0-9]/g, '');
        if (valueOnlyNumbers.length === 6) {
            return true;
        }
        return createError({
            path,
            message: intl.formatMessage({
                id: 'ZB4Jx6',
                defaultMessage: 'Некорректнй номер',
            }),
        });
    });
});

export default yup;
