import styled from 'styled-components';
export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const DashedLine = styled.div`
    width: 2px;
    height: calc(100% + 30px);
    border-left: 1px solid #d9d9d9;
    position: absolute;
    left: 12px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const CircleWrapper = styled.div`
    flex-shrink: 0;
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white()};
`;

export const TitleWrapper = styled.div``;

export const SubtitleWrapper = styled.div`
    color: ${({ theme }) => theme.colors.base(0.5)};
`;

export const Step = styled.div<{ $isActive: boolean }>`
    display: flex;
    gap: 12px;
    position: relative;

    ${TitleWrapper} {
        color: ${({ $isActive, theme }) => ($isActive ? theme.colors.base() : theme.colors.base(0.5))};
    }

    ${CircleWrapper} {
        ${({ $isActive }) => ($isActive ? 'border: none; background-color: #3ED77B;' : 'border: 1px solid #d9d9d9')}
    }
`;
