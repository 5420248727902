import React, { FC, useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { RoutesBusinessOffice } from 'src/pages/constants';
import { useAppDispatch } from 'src/store';
import { getClientsList } from 'src/store/Client';
import CreateOrderToPay from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay';
import { getClientCounterparty } from 'src/store/ClientCounterparty';
import CreateNewClient from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient';
import { setSelectedMerchantId } from 'src/store/Merchant';
import DashboardPage from 'src/pages/businessOffice/Shop/ShopTabsTables/Dashboard';
import MerchantTemplate from 'src/pages/businessOffice/Shop/components/MerchantTemplate';
import Integrations from 'src/pages/businessOffice/Shop/ShopTabsTables/Integrations';
import CreateIntegration from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateIntegration';
import { fetchApplicationList } from 'src/store/Application';
import Orders from './ShopTabsTables/Orders/Orders';
import Clients from './ShopTabsTables/Clients';
import Settings from './ShopTabsTables/Settings';
import { ShopNav } from './styles';
import Refunds from './ShopTabsTables/Refunds';
import RefundDetail from './ShopTabsTables/RefundDetail';
import path from '../../../utils/path';

export type TShopRouteParams = {
    merchantId: string;
};

const Shop: FC = () => {
    const dispatch = useAppDispatch();
    const { merchantId } = useParams() as TShopRouteParams;

    useEffect(() => {
        if (merchantId) {
            dispatch(getClientsList());
            dispatch(getClientCounterparty());
            dispatch(setSelectedMerchantId(merchantId));
            dispatch(fetchApplicationList());
        }
    }, [dispatch, merchantId]);

    return (
        <>
            <MerchantTemplate />
            <ShopNav>
                <Routes>
                    <Route path={RoutesBusinessOffice.overview} Component={DashboardPage} />
                    <Route path={RoutesBusinessOffice.orders} Component={Orders} />
                    <Route path={RoutesBusinessOffice.refunds} Component={Refunds} />
                    <Route path={RoutesBusinessOffice.clients} Component={Clients} />
                    <Route path={RoutesBusinessOffice.settings} Component={Settings} />
                    <Route path={RoutesBusinessOffice.orderToPay} Component={CreateOrderToPay} />
                    <Route path={RoutesBusinessOffice.createClient} Component={CreateNewClient} />
                    <Route path={RoutesBusinessOffice.createCounterparty} Component={CreateNewClient} />
                    <Route path={path(RoutesBusinessOffice.detailClient, ':clientId')} Component={CreateNewClient} />
                    <Route
                        path={path(RoutesBusinessOffice.detailClientCounterparty, ':clientCounterpartyId')}
                        Component={CreateNewClient}
                    />
                    <Route path={path(RoutesBusinessOffice.refundDetail, ':refundId')} Component={RefundDetail} />
                    <Route path={RoutesBusinessOffice.integrations} Component={Integrations} />
                    <Route path={RoutesBusinessOffice.createIntegration} Component={CreateIntegration} />
                </Routes>
            </ShopNav>
        </>
    );
};

export default Shop;
