import React, { FC, ReactNode } from 'react';
import { ColumnsHeader } from '../ColumnsHeader';
import { THeadLabel, TRow } from '../types';
import { TableRow } from '../TableRow';
import * as S from './styles';
import { NotFoundTableRow } from '../NotFoundTableRow';
import { getColSpanFromHeadLabels } from '../getColSpanFromHeadLabels';

export type TProps = {
    headLabels: Array<THeadLabel>;
    rows: Array<TRow>;
    isLoading: boolean;
    isInitialized: boolean;
    selectedRowIds?: Array<string>;
    onSelectRows: (ids: Array<string>) => void;
    children: ReactNode;
};

export const Table: FC<TProps> = ({
    headLabels,
    rows,
    isLoading,
    selectedRowIds,
    onSelectRows,
    isInitialized,
    children,
}) => {
    const isSelectedAllRows =
        !!rows.length && rows.every((rowItem) => selectedRowIds?.includes(rowItem.id)) && !!selectedRowIds?.length;
    const rowsIds = rows.map((rowItem) => rowItem.id);

    const handleSelectAllRows = () => {
        if (rows.length) {
            onSelectRows(
                isSelectedAllRows
                    ? selectedRowIds.filter((selectedRowId) => !rowsIds.includes(selectedRowId))
                    : [
                          ...(selectedRowIds?.length ? selectedRowIds : []),
                          ...rowsIds.filter((rowItem) => !selectedRowIds?.includes(rowItem)),
                      ],
            );
        }
    };

    const handleSelectRow = (id: string) => {
        onSelectRows(
            selectedRowIds?.includes(id)
                ? [...selectedRowIds.filter((selectedId) => selectedId !== id)]
                : [...(selectedRowIds?.length ? selectedRowIds : []), id],
        );
    };

    const isHaveDescriptionColumn = !!rows.find((row) => row.description);

    return (
        <S.TableWrapper $isLoading={isLoading}>
            <ColumnsHeader
                headLabels={headLabels}
                isSelectedAllRows={isSelectedAllRows}
                onSelectAllRows={handleSelectAllRows}
                isHaveDescriptionColumn={isHaveDescriptionColumn}
            />
            <tbody>
                {rows.length
                    ? rows.map((row, index, array) => (
                          <TableRow
                              key={row.id}
                              row={row}
                              headLabels={headLabels}
                              isShowBorderBottom={index !== array.length - 1}
                              onSelectRow={handleSelectRow}
                              isSelected={selectedRowIds?.includes(row.id) || false}
                              isCanOpenDescription={row.description}
                              isHaveDescriptionColumn={isHaveDescriptionColumn}
                          >
                              {row.description}
                          </TableRow>
                      ))
                    : isInitialized && (
                          <NotFoundTableRow colSpan={getColSpanFromHeadLabels(headLabels, isHaveDescriptionColumn)} />
                      )}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={getColSpanFromHeadLabels(headLabels, isHaveDescriptionColumn)}>{children}</td>
                </tr>
            </tfoot>
        </S.TableWrapper>
    );
};
