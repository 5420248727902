import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import SelectHeadLabel from '../components/SelectHeadLabel';
import { useMobile } from '../../../../../../hooks/useMedia';

export const useHeadLabel = () => {
    const isMobile = useMobile();
    const intl = useIntl();

    const [mobileAdditionalHeadLabel, setMobileAdditionalHeadLabel] = useState<string>('originalAmount');

    const commonHeadLabels = [
        { label: intl.formatMessage({ id: 'Сумма', defaultMessage: 'Сумма' }), name: 'originalAmount' },
        {
            label: intl.formatMessage({ id: 'Дата', defaultMessage: 'Дата' }),
            name: 'createdAt',
        },
        { label: intl.formatMessage({ id: 'Статус', defaultMessage: 'Статус' }), name: 'status' },
    ];

    const desktopHeadLabels = [
        { label: intl.formatMessage({ id: '№ счёта', defaultMessage: '№ счёта' }), name: 'merchantOrderIdVisible' },
        ...commonHeadLabels,
    ];

    const mobileHeadLabels = [
        { label: intl.formatMessage({ id: '№ счёта', defaultMessage: '№ счёта' }), name: 'merchantOrderIdVisible' },
        {
            label: (
                <SelectHeadLabel
                    value={mobileAdditionalHeadLabel}
                    options={commonHeadLabels}
                    onSelect={setMobileAdditionalHeadLabel}
                />
            ),
            name: mobileAdditionalHeadLabel,
            align: 'right' as const,
        },
    ];

    return isMobile ? mobileHeadLabels : desktopHeadLabels;
};
