import { TDocument, TRelatedObjectLink } from './types';
import { assert } from '../../utils/assert';
import { handleFileLink } from '../../utils/helpers/handleFileLink';

export class Document {
    readonly id: number;
    readonly type: string;
    private readonly format: string;
    readonly url?: string;
    readonly relatedObjectLink?: TRelatedObjectLink;

    private constructor(state: TDocument) {
        const { id, type, format, url, relatedObjectLink } = state;

        this.id = id;
        this.type = type;
        this.format = format;
        this.url = url;
        this.relatedObjectLink = relatedObjectLink;
    }

    download() {
        if (this.url) {
            handleFileLink(this.url);
        }
    }

    static matchByOrderContainerId(orderContainerId: string, documents: Array<Document>) {
        return documents.filter((documentItem) =>
            documentItem.relatedObjectLink?.orderContainerIds?.includes(orderContainerId),
        );
    }

    static fromResponse(response: unknown): Document {
        assert(typeof response === 'object' && response !== null);

        assert('id' in response);
        const { id } = response;
        assert(typeof id === 'number');

        assert('type' in response);
        const { type } = response;
        assert(typeof type === 'string');
        assert(!!type.length);

        assert('format' in response);
        const { format } = response;
        assert(typeof format === 'string');
        assert(!!format.length);

        let url;
        if ('url' in response) {
            url = response.url;
            assert(typeof url === 'string');
        }

        let relatedObjectLink;
        if ('relatedObjectLink' in response) {
            relatedObjectLink = response.relatedObjectLink as TRelatedObjectLink;
        }

        const checkedResponse = {
            id,
            type,
            format,
            url,
            relatedObjectLink,
        } satisfies TDocument;

        return new Document(checkedResponse);
    }

    static fromMock(): Document {
        return new Document({
            id: 1,
            type: 'invoice',
            format: 'pdf',
            url: 'https://cdn.invoicebox.ru/docs/invoice/a4f19e48-698a-4f18-a2ff-fbc5e7860ecc.pdf',
        });
    }

    static fromMockArray(): Array<Document> {
        return [
            new Document({
                id: 1,
                type: 'invoice',
                format: 'pdf',
                url: 'https://cdn.invoicebox.ru/docs/invoice/a4f19e48-698a-4f18-a2ff-fbc5e7860ecc.pdf',
            }),
            new Document({
                id: 2,
                type: 'invoice',
                format: 'pdf',
                url: 'https://cdn.invoicebox.ru/docs/invoice/a4f19e48-698a-4f18-a2ff-fbc5e7860ecc.pdf',
            }),
            new Document({
                id: 3,
                type: 'accepted-funds',
                format: 'pdf',
            }),
            new Document({
                id: 4,
                type: 'merchant-attorney',
                format: 'pdf',
                url: 'https://cdn.invoicebox.ru/docs/invoice/a4f19e48-698a-4f18-a2ff-fbc5e7860ecc.pdf',
            }),
            new Document({
                id: 5,
                type: 'merchant-attorney',
                format: 'pdf',
            }),
        ];
    }
}
