import React, { FC } from 'react';
import * as S from './styles';
import { DocumentTypeLabel } from '../../DocumentTypeLabel';
import { DocumentGeneratorSetting } from '../../../../store/DocumentGeneratorSetting/entity';
import { Document } from '../../../../store/NDocument/entity';
import { useMobile } from '../../../../hooks/useMedia';

export type TProps = {
    documents: Array<Document>;
    documentGeneratorSettingList: Array<DocumentGeneratorSetting>;
};

export const DocumentTypesRow: FC<TProps> = ({ documents, documentGeneratorSettingList }) => {
    const isMobile = useMobile();

    return (
        <S.Wrapper>
            {documents.map((documentItem, index, array) => (
                <S.DocumentItemWithDividerWrapper key={documentItem.id}>
                    <DocumentTypeLabel
                        isDone
                        label={
                            DocumentGeneratorSetting.matchById(documentItem.type, documentGeneratorSettingList)
                                ?.title || ''
                        }
                        onDownload={documentItem.url ? () => documentItem.download() : undefined}
                    />
                    {index !== array.length - 1 && !isMobile && (
                        <S.DividerWrapper>
                            <S.Divider />
                        </S.DividerWrapper>
                    )}
                </S.DocumentItemWithDividerWrapper>
            ))}
        </S.Wrapper>
    );
};
