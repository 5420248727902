import React, { FC } from 'react';
import { Form } from 'react-final-form';
import FormField from 'src/components/FormField';
import ButtonsNextPrev from 'src/pages/businessOffice/Claim/components/ButtonsNextPrev';
import { BANK_DETAILS_FIELDS } from 'src/store/Counterparty/contants';
import {
    BankDetailFormWrapper,
    BankNameInput,
    InputsWrapper,
    SettlementAccountInput,
} from 'src/pages/businessOffice/Claim/components/FormBankDetail/styles';
import { InputTypeEnum } from 'src/components/Input';
import useValidationSchema from 'src/hooks/useValidationSchema';
import { BankDetailSchema } from 'src/pages/businessOffice/Claim/validations';
import { ICounterpartyBankDetail } from 'src/store/Counterparty/types';
import { useAppDispatch } from 'src/store';
import { createCounterpartyBankDetails } from 'src/store/Counterparty';
import { useIntl } from 'react-intl';
import { useLoadingSubmit } from '@invoicebox/ui';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from '../../../../../store/Counterparty/selectors';

interface IFormBankDetail {
    onClickBack: () => void;
}

const FormBankDetail: FC<IFormBankDetail> = ({ onClickBack }) => {
    const intl = useIntl();
    const { isLoadingSubmit, toggleLoadingSubmit } = useLoadingSubmit();
    const validation = useValidationSchema(BankDetailSchema);
    const dispatch = useAppDispatch();
    const counterpartyId: string = useSelector(getCurrentCounterpartyId) as any;

    const onSubmit = async (formValues: ICounterpartyBankDetail) => {
        toggleLoadingSubmit();
        await dispatch(
            createCounterpartyBankDetails({ ...formValues, [BANK_DETAILS_FIELDS.counterpartyId]: counterpartyId }),
        );
        toggleLoadingSubmit();
    };

    return (
        <Form onSubmit={onSubmit} validate={validation}>
            {({ valid, handleSubmit }) => (
                <BankDetailFormWrapper onSubmit={handleSubmit}>
                    <InputsWrapper>
                        <BankNameInput
                            name={BANK_DETAILS_FIELDS.bankName}
                            label={intl.formatMessage({ defaultMessage: 'Наименование банка' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите наименование' })}
                        />
                        <SettlementAccountInput
                            name={BANK_DETAILS_FIELDS.settlementAccount}
                            label={intl.formatMessage({ defaultMessage: 'Номер расчётного счёта' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите номер расчётного счёта' })}
                            typeInput={InputTypeEnum.numeric}
                        />
                        <FormField
                            typeInput={InputTypeEnum.numeric}
                            name={BANK_DETAILS_FIELDS.bic}
                            label={intl.formatMessage({ defaultMessage: 'БИК' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите БИК' })}
                        />
                        <FormField
                            name={BANK_DETAILS_FIELDS.correspondentAccount}
                            label={intl.formatMessage({ defaultMessage: 'Номер корр. счёта' })}
                            placeholder={intl.formatMessage({ defaultMessage: 'Введите номер корр. счёта' })}
                            typeInput={InputTypeEnum.numeric}
                        />
                    </InputsWrapper>
                    <ButtonsNextPrev
                        isLoading={isLoadingSubmit}
                        isActiveNextButton={valid}
                        onClickPrev={onClickBack}
                        onClickNext={handleSubmit}
                    />
                </BankDetailFormWrapper>
            )}
        </Form>
    );
};

export default FormBankDetail;
