import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { CodeInput } from '@invoicebox/ui';
import Subtitle from '../Subtitle';
import * as S from './styles';
import Button from '../../../../../stories/common/Button';
import { useMobile } from '../../../../../hooks/useMedia';
import { NewCodeCounterLabel } from './styles';
import { SMS_CODE_LENGTH } from '../../../../../utils/constants';

type TProps = {
    timer: number;
    onGetNewCode: () => void;
};

const CodeEnterStep: FC<TProps> = ({ timer, onGetNewCode }) => {
    const intl = useIntl();
    const isMobile = useMobile();

    const [code, setCode] = useState('');
    const [isCodeError, setIsCodeError] = useState(false);

    const handleSendCode = () => {
        // тут логика по отправке введенного кода
        setIsCodeError(true);
    };

    return (
        <>
            <Subtitle steps="2/2" label={intl.formatMessage({ defaultMessage: 'Введите код из SMS' })} />
            <S.Wrapper>
                <CodeInput value={code} onChange={setCode} codeLength={SMS_CODE_LENGTH} hasError={isCodeError} />
                <S.NoteWrapper>
                    {isCodeError && (
                        <S.WrongLabel variant="buttonM">
                            {intl.formatMessage({ defaultMessage: 'Код неверный.' })}
                        </S.WrongLabel>
                    )}
                    {timer === 0 ? (
                        <button type="button" onClick={onGetNewCode}>
                            <S.SendCodeAgainLabel variant="buttonM">
                                {intl.formatMessage({ defaultMessage: 'Отправить код повторно' })}
                            </S.SendCodeAgainLabel>
                        </button>
                    ) : (
                        <NewCodeCounterLabel variant="buttonM">
                            {intl.formatMessage(
                                { defaultMessage: 'Отправить повторно через {seconds} сек' },
                                {
                                    seconds: timer,
                                },
                            )}
                        </NewCodeCounterLabel>
                    )}
                </S.NoteWrapper>
                <Button fullWidth={isMobile} disabled={code.length !== SMS_CODE_LENGTH} onClick={handleSendCode}>
                    {intl.formatMessage({ defaultMessage: 'Продолжить' })}
                </Button>
            </S.Wrapper>
        </>
    );
};

export default CodeEnterStep;
