export const TASK_PLACE_CODE_LABEL: Record<string, string> = {
    created: 'Создан',
    send: 'Отправлен',
    generated: 'Сгенерирован',
    error: 'Ошибка',
    sent_to_notification: 'Отправлено уведомление',
    sent: 'Отправлен',
    printed: 'Напечатан',
    received: 'Получен',
};
