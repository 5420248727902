import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IntroduceUserName } from '../../../../../stories/introduce/IntroduceUserName';
import { updateUser } from '../../../../../store/Auth';
import { useAppDispatch } from '../../../../../store';
import { getUser } from '../../../../../store/Auth/selectors';

type TProps = {
    goToNextStep: () => void;
};

const CreateUserNameScreen: FC<TProps> = ({ goToNextStep }) => {
    const dispatch = useAppDispatch();

    const user = useSelector(getUser);

    const createUserName = (formValues: { firstName: string; lastName: string }) =>
        dispatch(updateUser({ ...formValues, id: user?.id as number })).then(() => {
            goToNextStep();
        });

    return <IntroduceUserName onSubmit={createUserName} />;
};

export default CreateUserNameScreen;
