import { IntlShape } from 'react-intl';
import { assert } from '../../utils/assert';
import { TCountry } from './types';
import { countryLogic } from './logic';

type TAssert = {
    (value: unknown): asserts value;
};

const countryAssert: TAssert = (value: unknown) => assert(value, 'invalid country');

export class Country {
    private readonly id: string;
    readonly name: string;
    readonly code: string;
    private readonly shortCode: string;
    private readonly numericCode: number;
    private readonly active: boolean;

    private constructor(state: TCountry) {
        const { id, name, active, shortCode, numericCode, code } = state;

        this.id = id;
        this.name = name;
        this.active = active;
        this.shortCode = shortCode;
        this.numericCode = numericCode;
        this.code = code;
    }

    static fromResponse(response: unknown): Country {
        countryAssert(typeof response === 'object' && response !== null);

        countryAssert('id' in response);
        const { id } = response;
        countryAssert(typeof id === 'string');
        countryAssert(!!id.length);

        countryAssert('name' in response);
        const { name } = response;
        countryAssert(typeof name === 'string');
        countryAssert(!!name.length);

        countryAssert('active' in response);
        const { active } = response;
        countryAssert(typeof active === 'boolean');

        countryAssert('shortCode' in response);
        const { shortCode } = response;
        countryAssert(typeof shortCode === 'string');
        countryAssert(!!shortCode.length);

        countryAssert('numericCode' in response);
        const { numericCode } = response;
        countryAssert(typeof numericCode === 'number');
        countryAssert(numericCode > 0);

        countryAssert('code' in response);
        const { code } = response;
        countryAssert(typeof code === 'string');
        countryAssert(!!code.length);

        const checkedResponse = {
            id,
            active,
            name,
            code,
            numericCode,
            shortCode,
        } satisfies TCountry;

        return new Country(checkedResponse);
    }

    static fromMock(): Country {
        return new Country({
            id: 'RUS',
            name: 'Россия',
            code: 'RUS',
            shortCode: 'RU',
            numericCode: 643,
            active: true,
        });
    }

    vatLabel(intl: IntlShape) {
        return countryLogic.getVatLabel(this.code, intl);
    }

    flag() {
        return countryLogic.getFlag(this.code);
    }
}
