import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';
import Button from '../../common/Button';
import FormProvider from '../../../components/FormProvider';
import { findOrganizationSchema } from './validation';
import {
    RHFVatNumberAutocomplete,
    TProps as TRHFVatNumberAutocompleteProps,
} from '../../formFields/widgets/RHFVatNumberAutocomplete';
import { FORM_FIELD, TFormInner } from './constants';
import { RUSCountryCode } from '../../../store/Country/types';
import { useFetchCounterpartyDetailOptions } from '../../../hooks/useFetchCounterpartyDetailOptions';
import { organizationLogic } from '../../../store/CounterpartyDetail/organizationLogic';
import { TOrganization } from '../../../store/CounterpartyDetail/types';
import { countryLogic } from '../../../store/Country/logic';
import { useTablet } from '../../../hooks/useMedia';

export type TProps = {
    countryOptions: Pick<TRHFVatNumberAutocompleteProps['countrySelectProps'], 'options'>['options'];
    onSubmit: (vatNumber: string, countryId: string) => void;
    onSelectOrganization: (value: TOrganization) => void;
    onSkip: () => void;
};

export const FindOrganizationForm: FC<TProps> = ({ countryOptions, onSubmit, onSelectOrganization, onSkip }) => {
    const intl = useIntl();
    const isTablet = useTablet();

    const defaultValues = {
        [FORM_FIELD.vatNumber]: '',
        [FORM_FIELD.countryId]: RUSCountryCode,
    };

    const methods = useForm<TFormInner>({
        defaultValues,
        resolver: yupResolver(findOrganizationSchema),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: { isValid },
        watch,
    } = methods;

    const formVatNumber = watch(FORM_FIELD.vatNumber);
    const formCountryId = watch(FORM_FIELD.countryId);

    const { options, isLoading } = useFetchCounterpartyDetailOptions(formVatNumber, formCountryId);

    const handleVatNumberChange = (newValue: string, option?: { entity?: any }) => {
        if (option) {
            onSelectOrganization(option.entity as TOrganization);
        }
    };

    const submit = (formValues: TFormInner) => {
        onSubmit(formValues.vatNumber, formValues.countryId);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
            <S.Wrapper>
                <RHFVatNumberAutocomplete
                    name={FORM_FIELD.vatNumber}
                    options={options}
                    isLoadingOptions={isLoading}
                    onVatNumberChange={handleVatNumberChange}
                    countrySelectProps={{
                        name: FORM_FIELD.countryId,
                        options: countryOptions,
                    }}
                    minValidVatNumberLength={organizationLogic.getMinLengthVatNumber(formCountryId) || 0}
                    label={countryLogic.getVatLabel(formCountryId, intl)}
                />
                <S.Buttons>
                    <Button size="large" type="secondary" onClick={onSkip} fullWidth={isTablet}>
                        {intl.formatMessage({ id: 'Пропустить', defaultMessage: 'Пропустить' })}
                    </Button>
                    <Button htmlType="submit" size="large" disabled={!isValid} fullWidth={isTablet}>
                        {intl.formatMessage({ id: 'Продолжить', defaultMessage: 'Продолжить' })}
                    </Button>
                </S.Buttons>
            </S.Wrapper>
        </FormProvider>
    );
};
