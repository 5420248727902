import React, { createContext, ReactNode, useMemo } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

const SearchParamsContext = createContext<{
    searchParams: URLSearchParams;
    setSearchParams: (nextInit: URLSearchParamsInit) => void;
} | null>(null);

type Props = {
    children: ReactNode;
};

const SearchParamsProvider = ({ children }: Props) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const value = useMemo(
        () => ({
            searchParams,
            setSearchParams,
        }),
        [searchParams, setSearchParams],
    );

    return <SearchParamsContext.Provider value={value}>{children}</SearchParamsContext.Provider>;
};

export { SearchParamsProvider, SearchParamsContext };
