import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 660px;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        gap: 16px;
    }
`;

export const RowFields = styled.div`
    display: flex;
    gap: 16px;

    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;

    @media ${({ theme }) => theme.breakpoints.md} {
        flex-direction: column;
    }
`;

export const Notice = styled(Typography)`
    text-align: center;
    color: ${({ theme }) => theme.colors.grey700()};
`;
