import { useEffect } from 'react';
import { fetchOrderContainerList } from '../../../../store/OrderContainer';
import { useAppDispatch } from '../../../../store';
import { fetchDocumentListByOrderContainerId } from '../../../../store/NDocument';

export const useData = (isNeedFetchOrderContainers: boolean, orderContainerIds?: Array<string>) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isNeedFetchOrderContainers && orderContainerIds?.length) {
            dispatch(
                fetchOrderContainerList({
                    _page: 1,
                    _pageSize: 20,
                    id: orderContainerIds,
                }),
            );

            dispatch(fetchDocumentListByOrderContainerId(orderContainerIds));
        }
    }, [dispatch, isNeedFetchOrderContainers, orderContainerIds]);
};
