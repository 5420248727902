import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@invoicebox/ui';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IntroduceContainer } from '../IntroduceContainer';
import * as S from './styles';
import FormProvider from '../../../components/FormProvider';
import { RHFTextInput } from '../../formFields/RHFTextInput';
import { FORM_FIELD, TUserNameForm } from './constants';
import { userNameSchema } from './validation';
import Button from '../../common/Button';
import { useTablet } from '../../../hooks/useMedia';

export type TProps = {
    onSubmit: (params: { firstName: string; lastName: string }) => Promise<any>;
};

export const IntroduceUserName: FC<TProps> = ({ onSubmit }) => {
    const intl = useIntl();
    const isTablet = useTablet();

    const defaultValues = {
        firstName: '',
        lastName: '',
    };

    const methods = useForm<TUserNameForm>({
        defaultValues,
        resolver: yupResolver(userNameSchema),
        mode: 'all',
    });

    const {
        handleSubmit,
        formState: { isValid, isSubmitting },
    } = methods;

    const submit = (formValues: TUserNameForm) => onSubmit(formValues);

    return (
        <IntroduceContainer>
            <S.Wrapper>
                <Typography variant="headline3">
                    {intl.formatMessage({
                        id: 'Пожалуйста, представьтесь',
                        defaultMessage: 'Пожалуйста, представьтесь',
                    })}
                </Typography>
                <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
                    <S.FormWrapper>
                        <S.InputsWrapper>
                            <RHFTextInput
                                label={intl.formatMessage({
                                    id: 'Введите имя',
                                    defaultMessage: 'Введите имя',
                                })}
                                name={FORM_FIELD.firstName}
                            />
                            <RHFTextInput
                                label={intl.formatMessage({
                                    id: 'Введите фамилию',
                                    defaultMessage: 'Введите фамилию',
                                })}
                                name={FORM_FIELD.lastName}
                            />
                        </S.InputsWrapper>
                        <S.SubmitWrapper>
                            <Button
                                htmlType="submit"
                                fullWidth={isTablet}
                                isLoading={isSubmitting}
                                disabled={!isValid}
                                size="large"
                            >
                                {intl.formatMessage({
                                    id: 'Сохранить',
                                    defaultMessage: 'Сохранить',
                                })}
                            </Button>
                        </S.SubmitWrapper>
                    </S.FormWrapper>
                </FormProvider>
            </S.Wrapper>
        </IntroduceContainer>
    );
};
