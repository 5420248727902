import styled from 'styled-components';

export const Wrapper = styled.div`
    padding: 16px 20px 18px 20px;
    border-radius: ${({ theme }) => theme.decorations.borderRadius.large}px;
    background-color: ${({ theme }) => theme.colors.white()};
    width: 100%;

    @media ${({ theme }) => theme.breakpoints.sm} {
        padding: 20px 16px 16px;
        flex: 1;
    }
`;

export const Header = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 20px;

    @media ${({ theme }) => theme.breakpoints.sm} {
        margin-bottom: 16px;
    }
`;
