import styled from 'styled-components';

export const Wrapper = styled.div<{ $height?: number }>`
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 360px;
    ${({ $height }) => $height && `height: ${$height}px;`}

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
        height: 100%;
        padding: 24px 16px;
    }
`;

export const ButtonBack = styled.button`
    padding: 8px;
    transform: rotateZ(270deg);

    path {
        stroke: ${({ theme }) => theme.colors.base()};
    }

    &:hover {
        opacity: 0.5;
    }
`;
