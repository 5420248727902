import React, { FC } from 'react';
import {
    Balance,
    MerchantItemWrapper,
    MerchantName,
} from 'src/pages/businessOffice/Shop/components/MerchantListItem/styles';
import { IMerchant } from 'src/store/Merchant/types';
import { MERCHANT_FIELDS } from 'src/store/Merchant/contants';
import MerchantLogo, { enumSizeLogo } from 'src/components/MerchantLogo';
import { formatCurrencyToShow } from 'src/utils/helpers';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BUSINESS_OFFICE_PATHS } from '../../../paths';
import { getCurrentCounterpartyId } from '../../../../../store/Counterparty/selectors';

interface IMerchantListItem {
    merchant: IMerchant;
    balance: number;
    isActive: boolean;
    hideMerchantList: () => void;
}

const MerchantListItem: FC<IMerchantListItem> = ({ hideMerchantList, isActive, balance, merchant }) => {
    const navigate = useNavigate();

    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const onClickToMerchantItem = () => {
        navigate(BUSINESS_OFFICE_PATHS.shop(counterpartyId).overview(merchant.id));
        hideMerchantList();
    };

    return (
        <MerchantItemWrapper
            onClick={onClickToMerchantItem}
            // @ts-ignore fix error button to set param isActive
            isactivemerchant={isActive ? 1 : undefined}
        >
            <MerchantLogo merchantId={merchant[MERCHANT_FIELDS.id]} size={enumSizeLogo.SMALL} />
            <MerchantName variant="regular14">{merchant[MERCHANT_FIELDS.name]}</MerchantName>
            <Balance variant="regular14">{formatCurrencyToShow(balance)}</Balance>
        </MerchantItemWrapper>
    );
};

export default MerchantListItem;
