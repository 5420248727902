import React from 'react';
import { useTheme } from 'styled-components';
import { useIntl } from 'react-intl';
import Tag from '../Tag';

const IbbTag = () => {
    const theme = useTheme();
    const intl = useIntl();

    return (
        <Tag color={theme.colors.white()} backgroundColor={theme.colors.green()} isCheckIcon>
            {intl.formatMessage({
                defaultMessage: 'Гарантийный фонд',
                id: 'kKHzbB',
            })}
        </Tag>
    );
};

export default IbbTag;
