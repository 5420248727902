import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    ButtonAddOrderItemWrapper,
    ContainerOrderToPay,
    ContentOrderToPay,
    FormCreateOrder,
    LabelButtonAddOrderItem,
    TitleOrderToPay,
} from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/styles';
import NewOrderPayAccordion from 'src/components/NewOrderPayAccordion';
import CompositionOrderPay from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/CompositionOrderPay';
import TotalValues from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/TotalValues';
import { NewOrderAccordionsWrapper } from 'src/components/NewOrderPayAccordion/styles';
import useModal from 'src/hooks/useModal';
import ModalAddItemOrder from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/ModalAddItemOrder';
import { ORDER_FIELDS, ORDER_ITEM_FIELDS, TOrder, TOrderItem, TVatType } from 'src/store/Order/types';
import Requisites from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/Requisites';
import { Form } from 'react-final-form';
import useValidationSchema from 'src/hooks/useValidationSchema';
import { orderSchema } from 'src/pages/businessOffice/Shop/validations';
import Customer from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/Customer';
import { useAppDispatch } from 'src/store';
import { createOrder } from 'src/store/Order';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ModalSuccessOrder from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateOrderToPay/components/ModalSuccessOrder';
import { UTC_DATE_FORMAT } from 'src/utils/constants';
import { useMobile } from 'src/hooks/useMedia';
import { IMeasure } from 'src/store/Merchant/types';
import getCustomerForSubmitOrder from 'src/utils/helpers/getCustomerForSubmitOrder';
import useDictionary from 'src/hooks/useDictionarty';
import { DICTIONARY_TYPES } from 'src/store/Dictionaries/constants';
import { useIntl } from 'react-intl';
import { ReactComponent as PlusCircleIcon } from './assets/plus-circle-small.svg';

dayjs.extend(utc);

const CreateOrderToPay = () => {
    const intl = useIntl();
    const isMobile = useMobile();
    const dispatch = useAppDispatch();
    const { merchantId } = useParams() as { merchantId: string };
    const validation = useValidationSchema(orderSchema);

    const vatTypes: TVatType[] = useDictionary(DICTIONARY_TYPES.vat)?.data as any;
    const measures: IMeasure[] = useDictionary(DICTIONARY_TYPES.measure)?.data as any;

    // modals
    const { isOpen: addItemOrderModalVisible, open: openModal, close: closeModal } = useModal();
    const { isOpen: successModalVisible, open: openModalSuccess } = useModal();
    const [linkToPayOrder, setLinkToPayOrder] = useState(null);

    // items order
    const [itemsCompositionOrder, setItemsCompositionOrder] = useState<TOrderItem[]>([]);
    const addItemOrder = (itemOrder: TOrderItem) => {
        setItemsCompositionOrder([...itemsCompositionOrder, itemOrder]);
    };

    const editItemOrder = (itemOrder: TOrderItem) => {
        const editedArrayItems = itemsCompositionOrder.map((itemCompositionOrder) =>
            itemCompositionOrder[ORDER_ITEM_FIELDS.sku] === itemOrder[ORDER_ITEM_FIELDS.sku]
                ? itemOrder
                : itemCompositionOrder,
        );
        setItemsCompositionOrder(editedArrayItems);
    };

    const removeItemOrder = (itemOrderWillRemoved: TOrderItem) => {
        const arrayItemsOrderWithoutRemovedItem = itemsCompositionOrder.filter(
            (itemOrder) => itemOrder[ORDER_ITEM_FIELDS.sku] !== itemOrderWillRemoved[ORDER_ITEM_FIELDS.sku],
        );
        setItemsCompositionOrder(arrayItemsOrderWithoutRemovedItem);
    };

    // eslint-disable-next-line react/no-unstable-nested-components
    const ButtonAddOrderItem = () => (
        <ButtonAddOrderItemWrapper onClick={openModal}>
            <PlusCircleIcon />
            {!isMobile && (
                <LabelButtonAddOrderItem variant="regular14">
                    {intl.formatMessage({ defaultMessage: 'Добавить позицию' })}
                </LabelButtonAddOrderItem>
            )}
        </ButtonAddOrderItemWrapper>
    );

    const onSubmit = async (formValues: TOrder) => {
        const customerOrder = getCustomerForSubmitOrder(formValues);

        const dataOrder = {
            // items order
            [ORDER_FIELDS.basketItems]: itemsCompositionOrder,
            [ORDER_FIELDS.currencyId]: formValues[ORDER_FIELDS.currencyId],
            [ORDER_FIELDS.amount]: formValues[ORDER_FIELDS.amount],
            [ORDER_FIELDS.vatAmount]: formValues[ORDER_FIELDS.vatAmount],
            // requisites
            [ORDER_FIELDS.merchantOrderId]: formValues[ORDER_FIELDS.merchantOrderId],
            [ORDER_FIELDS.expirationDate]: dayjs(formValues[ORDER_FIELDS.expirationDate]).utc().format(UTC_DATE_FORMAT),
            [ORDER_FIELDS.description]: formValues[ORDER_FIELDS.description],
            // customer
            [ORDER_FIELDS.merchantId]: merchantId,
            [ORDER_FIELDS.customer]: customerOrder,
        };
        // @ts-ignore
        const resultSubmit: any = await dispatch(createOrder(dataOrder));
        if (!resultSubmit?.error && resultSubmit?.payload) {
            setLinkToPayOrder(resultSubmit.payload.paymentUrl);
            openModalSuccess();
        }
    };

    return (
        <ContainerOrderToPay>
            <TitleOrderToPay variant="bigTitle">{intl.formatMessage({ defaultMessage: 'Новый счёт' })}</TitleOrderToPay>
            <ContentOrderToPay>
                <Form
                    subscription={{ submitting: true, valid: true }}
                    validate={validation}
                    onSubmit={onSubmit}
                    initialValues={{
                        // fields for validation
                        [ORDER_FIELDS.isHideRequisites]: false,
                        [ORDER_FIELDS.isShowRequisites]: true,
                        [ORDER_FIELDS.isLegalCustomer]: false,

                        [ORDER_FIELDS.currencyId]: 'RUB',
                    }}
                >
                    {({ handleSubmit, valid }) => (
                        <FormCreateOrder onSubmit={handleSubmit}>
                            <NewOrderAccordionsWrapper>
                                <NewOrderPayAccordion
                                    title={intl.formatMessage({ defaultMessage: 'Состав счёта' })}
                                    OptionalComponentInHeader={
                                        itemsCompositionOrder.length > 0 ? ButtonAddOrderItem : undefined
                                    }
                                >
                                    <CompositionOrderPay
                                        itemsOrder={itemsCompositionOrder}
                                        openModalAddOrderItem={openModal}
                                        removeItemOrder={removeItemOrder}
                                        vatTypes={vatTypes}
                                        editItemOrder={editItemOrder}
                                        measures={measures}
                                    />
                                </NewOrderPayAccordion>
                                <NewOrderPayAccordion title={intl.formatMessage({ defaultMessage: 'Реквизиты' })}>
                                    <Requisites />
                                </NewOrderPayAccordion>
                                <NewOrderPayAccordion title={intl.formatMessage({ defaultMessage: 'Покупатель' })}>
                                    <Customer />
                                </NewOrderPayAccordion>
                            </NewOrderAccordionsWrapper>
                            <TotalValues handleSubmit={handleSubmit} valid={valid} itemsOrder={itemsCompositionOrder} />
                        </FormCreateOrder>
                    )}
                </Form>
            </ContentOrderToPay>
            <ModalAddItemOrder
                vatTypes={vatTypes}
                measures={measures}
                addOrEditItemOrder={addItemOrder}
                closeModal={closeModal}
                isOpen={addItemOrderModalVisible}
            />
            <ModalSuccessOrder linkToPayOrder={linkToPayOrder} isOpen={successModalVisible} />
        </ContainerOrderToPay>
    );
};

export default React.memo(CreateOrderToPay);
