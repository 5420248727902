import styled from 'styled-components';
import { Typography } from '@invoicebox/ui';

export const MobileActionsWrapper = styled.div`
    display: contents;

    @media ${({ theme }) => theme.breakpoints.sm} {
        ${({ theme }) => theme.mixins.flexCenter};
        gap: 10px;
        padding: 7px;
        border-radius: 50px;
        background: ${({ theme }) => theme.colors.lightGrey()};
    }
`;

export const MobileActionsDivider = styled.div`
    width: 1px;
    height: 16px;
    background-color: ${({ theme }) => theme.colors.base(0.04)};
`;

export const BurgerButton = styled.button`
    ${({ theme }) => theme.mixins.flexCenterCenter};
    padding-right: 3px;
`;

export const LoginButton = styled.a`
    text-decoration: none;
`;

export const MobileUserWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;

export const UserShortNameWrapper = styled.div`
    width: 30px;
    height: 30px;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    color: ${({ theme }) => theme.colors.grey500()};
    border: 1px solid ${({ theme }) => theme.colors.grey400()};
    border-radius: 100px;
    background: ${({ theme }) => theme.colors.grey200()};
`;

export const UserWrapper = styled.div`
    width: 178px;
    gap: 10px;
    ${({ theme }) => theme.mixins.flexCenter};
    border-radius: 16px;
    padding: 8px 12px 8px 16px;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.colors.wrongGrey100()};
    color: ${({ theme }) => theme.colors.base()};

    &:hover {
        opacity: 0.8;
    }
`;

export const UserName = styled(Typography)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const UserArrowWrapper = styled.div`
    flex-shrink: 0;
    ${({ theme }) => theme.mixins.flexCenterCenter};
`;
