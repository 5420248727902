import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media ${({ theme }) => theme.breakpoints.sm} {
        flex: 1;
    }
`;

export const Header = styled.div`
    ${({ theme }) => theme.mixins.flexCenter};
`;

export const Content = styled.div`
    display: flex;
    gap: 24px;

    @media ${({ theme }) => theme.breakpoints.ld} {
        flex-direction: column;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        display: contents;
    }
`;

export const TaskTableWrapper = styled.div`
    flex-shrink: 0;

    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 100%;
    }
`;
