import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    ${({ theme }) => theme.mixins.flexCenterCenter};
    background: #f6f6fa;

    @media ${({ theme }) => theme.breakpoints.sm} {
        max-width: 290px;
    }
`;
