import React, { FC } from 'react';
import { Typography } from '@invoicebox/ui';
import * as S from './styles';

export type TLabelColor = 'success' | 'error' | 'warning' | 'default';

const LABEL_COLOR_MAP: Record<TLabelColor, { color: string; backgroundColor: string }> = {
    success: {
        color: '#2BAA5D',
        backgroundColor: '#2BAA5D1F',
    },
    error: {
        color: '#EB5757',
        backgroundColor: '#EB575729',
    },
    warning: {
        color: '#F29F22',
        backgroundColor: '#FEBC5929',
    },
    default: {
        color: '#637381',
        backgroundColor: '#edeff2',
    },
};

export type TProps = {
    propName: string;
    colorMap: { [key: string]: TLabelColor };
    labelMap?: { [key: string]: string };
    label?: string;
};

export const LabelMap: FC<TProps> = ({ propName, colorMap, labelMap, label }) => {
    const colorFromMap = colorMap[propName];
    const value = label || labelMap?.[propName];

    const { color, backgroundColor } = LABEL_COLOR_MAP[colorFromMap];

    return (
        <S.Label $backgroundColor={backgroundColor} $color={color}>
            <Typography variant="smallMedium">{value ?? propName}</Typography>
        </S.Label>
    );
};
