import React, { FC } from 'react';
import * as S from './styles';
import { Step } from '../Step';

export type TProps = {
    count: number;
    active: number;
};

export const Steps: FC<TProps> = ({ count, active }) => {
    const arr = new Array(count).fill('').map((item, index) => index + 1);

    return (
        <S.Wrapper>
            {arr.map((item) => (
                <Step key={item} number={item} isActive={active >= item} />
            ))}
        </S.Wrapper>
    );
};
