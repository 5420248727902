import path from '../../utils/path';
import { APP_VARIANT_ROUTES, RoutesBusinessOffice, USER_PARAM_NAME } from '../constants';

type TCounterpartyId = string | null | undefined;

export const BUSINESS_OFFICE_PATHS = {
    introduce: (authTypeFlow?: string) =>
        authTypeFlow
            ? path(APP_VARIANT_ROUTES.dashboard, RoutesBusinessOffice.introduce, authTypeFlow)
            : path(APP_VARIANT_ROUTES.dashboard, RoutesBusinessOffice.introduce),
    profile: (counterpartyId?: TCounterpartyId) =>
        path(APP_VARIANT_ROUTES.dashboard, counterpartyId || USER_PARAM_NAME, RoutesBusinessOffice.profile),
    feeds: (counterpartyId?: TCounterpartyId) =>
        path(APP_VARIANT_ROUTES.dashboard, counterpartyId || USER_PARAM_NAME, RoutesBusinessOffice.feeds),
    claim: (counterpartyId?: TCounterpartyId) =>
        path(APP_VARIANT_ROUTES.dashboard, counterpartyId || USER_PARAM_NAME, RoutesBusinessOffice.claim),
    claimByMerchant: (merchantId: string, counterpartyId?: TCounterpartyId) =>
        path(APP_VARIANT_ROUTES.dashboard, counterpartyId || USER_PARAM_NAME, RoutesBusinessOffice.claim, merchantId),
    organizations: (counterpartyId?: TCounterpartyId) =>
        path(
            APP_VARIANT_ROUTES.dashboard,
            counterpartyId || USER_PARAM_NAME,
            RoutesBusinessOffice.profile,
            RoutesBusinessOffice.organizations,
        ),
    personal: (counterpartyId?: TCounterpartyId) =>
        path(
            APP_VARIANT_ROUTES.dashboard,
            counterpartyId || USER_PARAM_NAME,
            RoutesBusinessOffice.profile,
            RoutesBusinessOffice.personal,
        ),
    dashboard: (counterpartyId?: TCounterpartyId) =>
        path(APP_VARIANT_ROUTES.dashboard, counterpartyId || USER_PARAM_NAME, RoutesBusinessOffice.dashboard),

    payment: (counterpartyId?: TCounterpartyId) =>
        path(APP_VARIANT_ROUTES.dashboard, counterpartyId || USER_PARAM_NAME, RoutesBusinessOffice.payment),
    counterpartyDetail: (counterpartyId: string, selectedCounterpartyId?: TCounterpartyId) =>
        path(
            APP_VARIANT_ROUTES.dashboard,
            selectedCounterpartyId || USER_PARAM_NAME,
            RoutesBusinessOffice.counterparty,
            counterpartyId,
        ),
    orderContainer: (orderContainerId: string, counterpartyId?: TCounterpartyId) =>
        path(
            APP_VARIANT_ROUTES.dashboard,
            counterpartyId || USER_PARAM_NAME,
            RoutesBusinessOffice.orderContainer,
            orderContainerId,
        ),
    orderContainerRefundCreate: (orderContainerId: string, counterpartyId?: TCounterpartyId) =>
        path(
            APP_VARIANT_ROUTES.dashboard,
            counterpartyId || USER_PARAM_NAME,
            RoutesBusinessOffice.orderContainer,
            orderContainerId,
            RoutesBusinessOffice.refundCreate,
        ),
    shop: (counterpartyId?: TCounterpartyId) => ({
        overview: (merchantId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.overview,
            ),
        orders: (merchantId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.orders,
            ),
        refunds: (merchantId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.refunds,
            ),
        clients: (merchantId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.clients,
            ),
        settings: (merchantId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.settings,
            ),
        integrations: (merchantId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.integrations,
            ),
        createClient: (merchantId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.createClient,
            ),
        createCounterparty: (merchantId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.createCounterparty,
            ),
        detailClient: (merchantId: string, clientId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.detailClient,
                clientId,
            ),
        detailClientCounterparty: (merchantId: string, clientCounterpartyId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.detailClientCounterparty,
                clientCounterpartyId,
            ),
        refundDetail: (merchantId: string, refundId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.refundDetail,
                refundId,
            ),
        createIntegration: (merchantId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.createIntegration,
            ),
        orderToPay: (merchantId: string) =>
            path(
                APP_VARIANT_ROUTES.dashboard,
                counterpartyId || USER_PARAM_NAME,
                RoutesBusinessOffice.shop,
                merchantId,
                RoutesBusinessOffice.orderToPay,
            ),
    }),
};
