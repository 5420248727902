import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Breadcrumb from '../Breadcrumb';
import { BUSINESS_OFFICE_PATHS } from '../../pages/businessOffice/paths';
import { getCurrentCounterpartyId } from '../../store/Counterparty/selectors';

export const BreadcrumbToDashboard = () => {
    const intl = useIntl();
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    return (
        <Breadcrumb.Item to={BUSINESS_OFFICE_PATHS.dashboard(counterpartyId)}>
            {intl.formatMessage({ defaultMessage: 'Главная' })}
        </Breadcrumb.Item>
    );
};
