import React, { FC, useCallback } from 'react';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { CountrySelect, TCountrySelectProps } from '@invoicebox/ui';

export type TProps = Omit<TCountrySelectProps, 'value' | 'onChange'> &
    Partial<Pick<TCountrySelectProps, 'onChange'>> & {
        name: string;
    };

export const RHFCountry: FC<TProps> = ({ name, onChange, ...other }) => {
    const { control } = useFormContext();

    const render = useCallback(
        ({ field }: { field: ControllerRenderProps }) => {
            const handleChange = (value: string) => {
                field.onChange(value);
                if (onChange) {
                    onChange(value);
                }
            };

            return <CountrySelect {...other} onChange={handleChange} value={field.value || ''} />;
        },
        [onChange, other],
    );

    return <Controller name={name} control={control} render={render} />;
};
