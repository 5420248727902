import styled from 'styled-components';

export const Wrapper = styled.div`
    background: #f6f6fa;
    padding: 46px 0 30px 0;

    @media ${({ theme }) => theme.breakpoints.xl} {
        padding: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
    }
`;

export const ContentWrapper = styled.div`
    margin: 0 auto;
    width: 1170px;

    @media ${({ theme }) => theme.breakpoints.xl} {
        width: 100%;
        padding: 24px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        display: contents;
    }
`;
