import React, { ReactNode, useState } from 'react';
import { useSelector } from 'react-redux';
import { InitialScreen } from '../InitialScreen';
import { TAuthByLoginForm } from '../AuthByLogin/constants';
import { useAppDispatch } from '../../../store';
import { authByLogin, verificationByCode, verificationByPhone } from '../../../store/Auth';
import { EnterSmsCodeScreen } from '../EnterSmsCodeScreen';
import { useReCaptchaTokenContext } from '../../../context/ReCaptchaTokenContext';
import { convertPhoneToShow, phoneFormatter, removePlusFromPhone } from '../../../utils/formatters';
import { useExpiredEnterSmsCode } from './hooks/useExpiredEnterSmsCode';
import { getLeftAttempts } from '../../../store/Auth/selectors';
import * as S from './styles';

enum AUTH_SCREENS {
    INITIAL = 'INITIAL',
    ENTER_SMS_CODE = 'ENTER_SMS_CODE',
}

const AuthWidget = () => {
    const dispatch = useAppDispatch();
    const { token, onRefreshReCaptcha } = useReCaptchaTokenContext();

    const [authPhone, setAuthPhone] = useState('');
    const [isCodeError, setIsCodeError] = useState(false);

    const leftAttempts = useSelector(getLeftAttempts) as number;

    const { isExpired, timer } = useExpiredEnterSmsCode();

    const [screen, setScreen] = useState<keyof typeof AUTH_SCREENS>(AUTH_SCREENS.INITIAL);
    const goToInitialScreen = () => setScreen(AUTH_SCREENS.INITIAL);

    const authByLoginSubmit = (formValues: TAuthByLoginForm) => dispatch(authByLogin(formValues));

    const sendCodeByPhone = async (phone: string) => {
        const data = await dispatch(
            verificationByPhone({
                phone: phoneFormatter(removePlusFromPhone(phone)),
                token,
            }),
        );
        onRefreshReCaptcha();
        return data;
    };

    const authByPhone = async (phone: string) => {
        const data = await sendCodeByPhone(phone);

        if (!('error' in data) && data?.payload) {
            setScreen(AUTH_SCREENS.ENTER_SMS_CODE);
            setAuthPhone(phone);
        }
    };

    const sendCode = async (code: string) => {
        const result = await dispatch(verificationByCode({ code, token }));
        onRefreshReCaptcha();

        if ('error' in result) {
            setIsCodeError(true);
        }
        return result;
    };

    const handleResendCode = () => sendCodeByPhone(authPhone).then(() => setIsCodeError(false));

    const SCREENS_MAP: Record<AUTH_SCREENS, ReactNode> = {
        INITIAL: <InitialScreen onByLoginSubmit={authByLoginSubmit} onByPhoneSubmit={authByPhone} />,
        ENTER_SMS_CODE: (
            <EnterSmsCodeScreen
                onBack={goToInitialScreen}
                onInitialScreenMove={goToInitialScreen}
                onResendCode={handleResendCode}
                onSubmit={sendCode}
                phone={convertPhoneToShow(authPhone)}
                isCodeError={isCodeError}
                isDisabled={isExpired || leftAttempts < 1}
                timer={timer}
                leftAttempts={leftAttempts}
            />
        ),
    };

    return <S.Wrapper> {SCREENS_MAP[screen]} </S.Wrapper>;
};

export default AuthWidget;
