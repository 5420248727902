import React, { ReactNode } from 'react';
import { ReactComponent as HonestSignIcon } from 'src/assets/icons/honestSign.svg';
import IbbTag from 'src/stories/common/widgets/IbbTag';
import EdoTag from 'src/stories/common/widgets/EdoTag';
import { LENGTH_VAT_NUMBER } from '@invoicebox/ui';
import { TOrganization } from './types';
import { COUNTRIES, TKnownCountryCode } from '../Country/types';

class OrganizationLogic {
    getIsLegalEntrepreneurVatNumberLength(vatNumber?: string) {
        return vatNumber?.length === LENGTH_VAT_NUMBER.RUS_LEGAL_ENTREPRENEUR;
    }

    getIsIndividualEntrepreneurVatNumberLength(vatNumber?: string) {
        return vatNumber?.length === LENGTH_VAT_NUMBER.RUS_INDIVIDUAL_ENTREPRENEUR;
    }

    getIsRUSVatNumberLengthValid(vatNumber?: string) {
        return (
            this.getIsLegalEntrepreneurVatNumberLength(vatNumber) ||
            this.getIsIndividualEntrepreneurVatNumberLength(vatNumber)
        );
    }

    getIsBLRVatNumberLengthValid(vatNumber?: string) {
        return vatNumber?.length === LENGTH_VAT_NUMBER.BLR_VAT_NUMBER;
    }

    getIsKAZVatNumberLengthValid(vatNumber?: string) {
        return vatNumber?.length === LENGTH_VAT_NUMBER.KAZ_VAT_NUMBER;
    }

    getIsAZEVatNumberLengthValid(vatNumber?: string) {
        return vatNumber?.length === LENGTH_VAT_NUMBER.AZE_VAT_NUMBER;
    }

    getIsARMVatNumberLengthValid(vatNumber?: string) {
        return vatNumber?.length === LENGTH_VAT_NUMBER.ARM_VAT_NUMBER;
    }

    getIsKGZVatNumberLengthValid(vatNumber?: string) {
        return vatNumber?.length === LENGTH_VAT_NUMBER.KGZ_VAT_NUMBER;
    }

    getIsMDAVatNumberLengthValid(vatNumber?: string) {
        return vatNumber?.length === LENGTH_VAT_NUMBER.MDA_VAT_NUMBER;
    }

    getIsTJKVatNumberLengthValid(vatNumber?: string) {
        return (
            vatNumber?.length === LENGTH_VAT_NUMBER.TJK_VAT_NUMBER_SHORT ||
            vatNumber?.length === LENGTH_VAT_NUMBER.TJK_VAT_NUMBER_LONG
        );
    }

    getIsUZBVatNumberLengthValid(vatNumber?: string) {
        return vatNumber?.length === LENGTH_VAT_NUMBER.UZB_VAT_NUMBER;
    }

    getTagsFromOrganization(organization: TOrganization) {
        const features = [
            organization.internalProperty?.ibb && <IbbTag />,
            organization.internalProperty?.edo && <EdoTag />,
            organization.internalProperty?.honestSign && <HonestSignIcon />,
        ].filter((i) => i) as Array<ReactNode>;

        return features?.length > 0 ? features : undefined;
    }

    getIsVatNumberLengthValid(vatNumber?: string, countryCode?: string): boolean {
        if (!vatNumber || !countryCode) {
            return false;
        }

        const validFunctionsMap: Record<TKnownCountryCode, (value: string) => boolean> = {
            [COUNTRIES.RUS]: this.getIsRUSVatNumberLengthValid,
            [COUNTRIES.BLR]: this.getIsBLRVatNumberLengthValid,
            [COUNTRIES.KAZ]: this.getIsKAZVatNumberLengthValid,
            [COUNTRIES.AZE]: this.getIsAZEVatNumberLengthValid,
            [COUNTRIES.ARM]: this.getIsARMVatNumberLengthValid,
            [COUNTRIES.KGZ]: this.getIsKGZVatNumberLengthValid,
            [COUNTRIES.MDA]: this.getIsMDAVatNumberLengthValid,
            [COUNTRIES.TJK]: this.getIsTJKVatNumberLengthValid,
            [COUNTRIES.UZB]: this.getIsUZBVatNumberLengthValid,
        };

        const validFunction = validFunctionsMap[countryCode as COUNTRIES]?.bind(this);

        return validFunction ? validFunction(vatNumber) : false;
    }

    getIsCanFetchOrganization(vatNumber?: string, countryCode?: string) {
        return this.getIsVatNumberLengthValid(vatNumber, countryCode);
    }

    getMinLengthVatNumber(countryCode: string) {
        const minLengthVatNumberByCountry: Record<TKnownCountryCode, number> = {
            [COUNTRIES.RUS]: LENGTH_VAT_NUMBER.RUS_LEGAL_ENTREPRENEUR,
            [COUNTRIES.BLR]: LENGTH_VAT_NUMBER.BLR_VAT_NUMBER,
            [COUNTRIES.KAZ]: LENGTH_VAT_NUMBER.KAZ_VAT_NUMBER,
            [COUNTRIES.AZE]: LENGTH_VAT_NUMBER.AZE_VAT_NUMBER,
            [COUNTRIES.ARM]: LENGTH_VAT_NUMBER.ARM_VAT_NUMBER,
            [COUNTRIES.KGZ]: LENGTH_VAT_NUMBER.KGZ_VAT_NUMBER,
            [COUNTRIES.MDA]: LENGTH_VAT_NUMBER.MDA_VAT_NUMBER,
            [COUNTRIES.TJK]: LENGTH_VAT_NUMBER.TJK_VAT_NUMBER_SHORT,
            [COUNTRIES.UZB]: LENGTH_VAT_NUMBER.UZB_VAT_NUMBER,
        };

        return minLengthVatNumberByCountry[countryCode as COUNTRIES];
    }
}

export const organizationLogic = new OrganizationLogic();
