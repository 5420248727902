import styled from 'styled-components';

export const Wrapper = styled.div<{
    $color: string;
    $backgroundColor: string;
}>`
    color: ${({ $color }) => $color};
    border-radius: 100px;
    background: ${({ $backgroundColor }) => $backgroundColor};
    padding: 4px 12px;
    ${({ theme }) => theme.mixins.flexCenter};
    gap: 4px;
    white-space: nowrap;

    svg {
        path {
            stroke: ${({ color }) => color};
        }
    }
`;
