import React, { FC, ReactNode, useMemo } from 'react';
import { EntityAutocompleteOptionsDrawer } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import { useFormContext } from 'react-hook-form';
import { RHFSearchInput } from '../../../../RHFSearchInput';
import { TOrganization } from '../../../../../../store/CounterpartyDetail/types';
import { MAX_LENGTH_VAT_NUMBER } from '../../../../../../utils/constants';

type TOption = { value: string; entity: TOrganization };

type TProps = {
    name: string;
    isOpen: boolean;
    onClose: () => void;
    options: Array<TOption>;
    renderOption: (option: TOption) => ReactNode;
    onChange: (value: string, option?: TOption) => void;
    isLoadingOptions: boolean;
    minValidVatNumberLength: number;
    placeholder?: string;
};

const VatNumberAutocompleteOptionsDrawer: FC<TProps> = ({
    isOpen,
    onClose,
    options,
    renderOption,
    placeholder,
    onChange,
    name,
    isLoadingOptions,
    minValidVatNumberLength,
}) => {
    const intl = useIntl();
    const { watch } = useFormContext();
    const fieldValue = watch(name);

    const vatNumberValue = fieldValue || '';

    const promptMessage = useMemo(() => {
        if (vatNumberValue.length < minValidVatNumberLength) {
            return intl.formatMessage(
                {
                    id: 'Список подходящих организаций появится, когда вы введёте {count} и больше символов',
                    defaultMessage:
                        'Список подходящих организаций появится, когда вы введёте {count} и больше символов',
                },
                {
                    count: minValidVatNumberLength,
                },
            );
        }
        if (!isLoadingOptions && !options.length) {
            return intl.formatMessage({
                id: 'Организаций не найдено',
                defaultMessage: 'Организаций не найдено',
            });
        }
    }, [intl, isLoadingOptions, minValidVatNumberLength, options.length, vatNumberValue]);

    return (
        <EntityAutocompleteOptionsDrawer
            isOpen={isOpen}
            onClose={onClose}
            options={options}
            renderOption={renderOption}
            onChange={onChange}
            isLoadingOptions={isLoadingOptions}
            searchInputField={
                <RHFSearchInput
                    name={name}
                    hasBorder
                    autoFocus={false}
                    placeholder={placeholder}
                    onChange={onChange}
                    isOnlyNumbers
                    maxLength={MAX_LENGTH_VAT_NUMBER}
                />
            }
            promptMessage={promptMessage}
        />
    );
};

export default VatNumberAutocompleteOptionsDrawer;
