import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    padding: 50px 84px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    text-align: center;
    justify-content: center;

    @media ${({ theme }) => theme.breakpoints.md} {
        padding: 0 16px;
    }
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 342px;
    margin: 0 auto;

    @media ${({ theme }) => theme.breakpoints.md} {
        width: 100%;
    }
`;

export const InputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const SubmitWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
