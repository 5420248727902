import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { NewClientWrapper } from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/styles';
import { RoutesBusinessOffice } from 'src/pages/constants';
import DetailClient from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/DetailClient';
import DetailClientCounterparty from 'src/pages/businessOffice/Shop/ShopTabsTables/CreateNewClient/components/DetailClientCounterparty';

const CreateNewClient = () => {
    const { clientCounterpartyId, clientId } = useParams<{ clientCounterpartyId: string; clientId: string }>();
    const location = useLocation();

    const renderContent = () => {
        if (clientCounterpartyId) {
            return <DetailClientCounterparty idClientCounterparty={Number(clientCounterpartyId)} />;
        }
        if (clientId) {
            return <DetailClient clientId={Number(clientId)} />;
        }

        if (location.pathname.includes(RoutesBusinessOffice.createClient)) {
            return <DetailClient />;
        }
        if (location.pathname.includes(RoutesBusinessOffice.createCounterparty)) {
            return <DetailClientCounterparty />;
        }
        return <div>404</div>;
    };

    return <NewClientWrapper>{renderContent()}</NewClientWrapper>;
};

export default CreateNewClient;
