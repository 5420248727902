import React from 'react';
import { ButtonCreateWrapper } from 'src/pages/businessOffice/OrderContainer/components/ButtonCreateRefund/styles';
import { ReactComponent as IconRefund } from 'src/assets/icons/return.svg';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentCounterpartyId } from '../../../../../store/Counterparty/selectors';
import { BUSINESS_OFFICE_PATHS } from '../../../paths';

const ButtonCreateRefund = () => {
    const { orderContainerId } = useParams() as { orderContainerId: string };
    const intl = useIntl();
    const selectedCounterpartyId = useSelector(getCurrentCounterpartyId);

    return (
        <ButtonCreateWrapper
            to={BUSINESS_OFFICE_PATHS.orderContainerRefundCreate(orderContainerId, selectedCounterpartyId)}
        >
            <IconRefund /> {intl.formatMessage({ defaultMessage: 'Создать возврат' })}
        </ButtonCreateWrapper>
    );
};

export default ButtonCreateRefund;
