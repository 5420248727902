import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    @media ${({ theme }) => theme.breakpoints.sm} {
        flex: 1;
    }
`;

export const ContentWrapper = styled.div`
    ${({ theme }) => theme.mixins.flexStart};
    gap: 30px;
    justify-content: space-between;

    @media ${({ theme }) => theme.breakpoints.ld} {
        flex-direction: column;
        gap: 20px;
    }

    @media ${({ theme }) => theme.breakpoints.sm} {
        display: contents;
    }
`;

export const OrderContainersTableWrapper = styled.div`
    flex-shrink: 0;

    @media ${({ theme }) => theme.breakpoints.ld} {
        width: 100%;
    }
`;
