import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { FindOrganizationForm } from '../../../../../stories/introduce/FindOrganizationForm';
import { countryLogic } from '../../../../../store/Country/logic';
import { getCountriesList } from '../../../../../store/Country/selectors';
import { TOrganization } from '../../../../../store/CounterpartyDetail/types';
import { IntroduceStepContainer } from '../../../../../stories/introduce/IntroduceStepContainer';

type TProps = {
    goToNextStep: () => void;
    setOrganizationInitialValue: (organization: Partial<TOrganization>) => void;
    setCountryId: (value: string) => void;
    onSkip: () => void;
};

const FindOrganizationScreen: FC<TProps> = ({ setOrganizationInitialValue, goToNextStep, setCountryId, onSkip }) => {
    const intl = useIntl();
    const countries = useSelector(getCountriesList);
    const countryOptions = countryLogic.getCountryOptions(countries, intl);

    const handleFindOrganizationSubmit = (vatNumber: string, countryId: string) => {
        setOrganizationInitialValue({
            vatNumber,
        });
        setCountryId(countryId);
        goToNextStep();
    };

    const handleSelectOrganization = (organization: TOrganization) => {
        setOrganizationInitialValue(organization);
        goToNextStep();
    };

    return (
        <IntroduceStepContainer
            title={intl.formatMessage({
                id: 'Сведения об организации или ИП',
                defaultMessage: 'Сведения об организации или ИП',
            })}
            subtitle={intl.formatMessage({
                id: 'Пожалуйста, выберите страну регистрации и укажите ИНН',
                defaultMessage: 'Пожалуйста, выберите страну регистрации и укажите ИНН',
            })}
            countSteps={2}
            activeStep={1}
        >
            <FindOrganizationForm
                countryOptions={countryOptions}
                onSubmit={handleFindOrganizationSubmit}
                onSelectOrganization={handleSelectOrganization}
                onSkip={onSkip}
            />
        </IntroduceStepContainer>
    );
};

export default FindOrganizationScreen;
