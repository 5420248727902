import React, { FC } from 'react';
import { Scrollbar, Typography } from '@invoicebox/ui';
import { useIntl } from 'react-intl';
import { TaskRowWidget } from 'src/stories/docs/widgets/TaskRowWidget';
import * as S from './styles';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { Task } from '../../../../../store/Task/entity';
import { useLargeTablet } from '../../../../../hooks/useMedia';

type TProps = {
    taskList: Array<Task>;
    selectedTaskId?: number;
    onSelectTask: (task: Task) => void;
};

const TasksTable: FC<TProps> = ({ taskList, selectedTaskId, onSelectTask }) => {
    const intl = useIntl();
    const isLargeTablet = useLargeTablet();

    return (
        <S.Wrapper>
            <S.Head>
                <Typography variant="headline6">
                    {intl.formatMessage({
                        id: 'Отправленные документы',
                        defaultMessage: 'Отправленные документы',
                    })}
                </Typography>
                <CalendarIcon />
            </S.Head>
            <S.Columns>
                <Typography variant="bodyMRegular">
                    {intl.formatMessage({
                        id: '№ отправления',
                        defaultMessage: '№ отправления',
                    })}
                </Typography>
                <Typography variant="bodyMRegular">
                    {intl.formatMessage({
                        id: 'Дата / период',
                        defaultMessage: 'Дата / период',
                    })}
                </Typography>
            </S.Columns>
            {taskList.length === 0 ? (
                <S.EmptyLabel variant="bodyMRegular">
                    {intl.formatMessage({ id: 'Документы отсутствуют', defaultMessage: 'Документы отсутствуют' })}
                </S.EmptyLabel>
            ) : (
                <Scrollbar maxHeight={isLargeTablet ? 240 : 383}>
                    {taskList.map((taskItem) => (
                        <TaskRowWidget
                            checked={selectedTaskId === taskItem.id}
                            key={taskItem.id}
                            task={taskItem}
                            onClick={onSelectTask}
                        />
                    ))}
                </Scrollbar>
            )}
        </S.Wrapper>
    );
};

export default TasksTable;
