import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { fetchOrderContainerList } from '../../../../../../store/OrderContainer';
import { convertDateToString } from '../../../../../../utils/helpers';
import { useAppDispatch } from '../../../../../../store';
import { ICounterparty } from '../../../../../../store/Counterparty/types';
import {
    getIsInitializedOrderContainerList,
    getIsLoadingOrderContainerList,
    getOrderContainerList,
    getOrderContainerMetaData,
} from '../../../../../../store/OrderContainer/selectors';
import { getUniqIdsFromArray } from '../../../../../../utils/helpers/getUniqIdsFromArray';
import { fetchDocumentListByOrderContainerId } from '../../../../../../store/NDocument';
import { getIsLoadingDocumentList } from '../../../../../../store/NDocument/selectors';
import usePagination from '../../../../../../hooks/usePagination';
import {
    getDateArrOrNullFromQueryString,
    getQueryStringFromDateArrOrNull,
    useQueryFilter,
} from '../../../../../../hooks/useQueryFilter';

export const useData = (selectedCounterparty?: ICounterparty) => {
    const dispatch = useAppDispatch();
    const pageSizeOptions = [5, 10, 15];

    const { page, pageSize, onPageChange, onPageSizeChange } = usePagination();

    const [createdAtFilter, setCreatedAtFilter] = useQueryFilter<[Date, Date] | null>(
        'created',
        null,
        getDateArrOrNullFromQueryString,
        getQueryStringFromDateArrOrNull,
    );

    const orderContainerList = useSelector(getOrderContainerList);
    const isLoadingOrderContainerList = useSelector(getIsLoadingOrderContainerList);
    const isInitializedOrderContainerList = useSelector(getIsInitializedOrderContainerList);
    const metaData = useSelector(getOrderContainerMetaData);

    const isLoadingDocument = useSelector(getIsLoadingDocumentList);

    const handlePageSizeChange = (newPageSize: number) => {
        onPageSizeChange(newPageSize);
        onPageChange(1);
    };

    const goToNextPage = () => onPageChange(page + 1);
    const goToPrevPage = () => onPageChange(page - 1);

    useEffect(() => {
        if (selectedCounterparty?.id) {
            dispatch(
                fetchOrderContainerList({
                    _pageSize: pageSize,
                    _page: page,
                    payerCounterpartyId: selectedCounterparty.id,
                    'createdAt[_ge]': createdAtFilter ? convertDateToString(createdAtFilter[0]) : undefined,
                    'createdAt[lt]': createdAtFilter ? convertDateToString(createdAtFilter[1]) : undefined,
                }),
            );
        }
    }, [createdAtFilter, dispatch, page, pageSize, selectedCounterparty?.id]);

    const orderContainerIds = useMemo(() => getUniqIdsFromArray(orderContainerList, 'id'), [orderContainerList]);

    useEffect(() => {
        if (orderContainerIds.length) {
            dispatch(fetchDocumentListByOrderContainerId(orderContainerIds));
        }
    }, [dispatch, orderContainerIds]);

    return {
        orderContainerList,
        isLoading: isLoadingOrderContainerList || isLoadingDocument,
        isInitialized: isInitializedOrderContainerList,
        metaData,
        goToNextPage,
        goToPrevPage,
        handlePageSizeChange,
        handleCreatedAtFilterChange: setCreatedAtFilter,
        createdAtFilter,
        pageSizeOptions,
        pageSize,
    };
};
