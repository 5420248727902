import Breadcrumb from 'src/components/Breadcrumb';
import { RoutesBusinessOffice } from 'src/pages/constants';
import Skeleton from 'src/components/Skeleton';
import MerchantLogo from 'src/components/MerchantLogo';
import ShopNavigation from 'src/pages/businessOffice/Shop/ShopNavigation/ShopNavigation';
import React, { useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IMerchant } from 'src/store/Merchant/types';
import { getMerchantById } from 'src/store/Merchant/selectors';
import ClaimBanners from 'src/pages/businessOffice/Shop/components/ClaimBanners';
import { useLargeTablet } from 'src/hooks/useMedia';
import MerchantsList from 'src/pages/businessOffice/Shop/components/MerchantsList';
import { ReactComponent as IconArrowImage } from 'src/assets/icons/arrow-down.svg';
import { ModalMerchantCreate } from 'src/components/ProductsAccordion/styles';
import { AddingMerchantContext } from 'src/components/ProductsAccordion/context';
import MerchantModalInner from 'src/pages/businessOffice/Introduce/components/MerchantModalInner';
import useModal from 'src/hooks/useModal';
import { useIntl } from 'react-intl';
import { useOutsideClick } from '@invoicebox/ui';
import { TShopRouteParams } from '../../index';
import { IconWrapper, MerchantName, ShopHeader, ShopHeaderWrapper } from './styles';
import { BreadcrumbToDashboard } from '../../../../../components/BreadcrumbToDashboard';
import { getCurrentCounterpartyId } from '../../../../../store/Counterparty/selectors';
import { BUSINESS_OFFICE_PATHS } from '../../../paths';

const hideHeaderRoutes = [
    RoutesBusinessOffice.orderToPay,
    RoutesBusinessOffice.createClient,
    RoutesBusinessOffice.createCounterparty,
    RoutesBusinessOffice.detailClientCounterparty,
    RoutesBusinessOffice.detailClient,
    RoutesBusinessOffice.refundDetail,
    RoutesBusinessOffice.createIntegration,
];

const MerchantTemplate = () => {
    const intl = useIntl();

    const routesThirdLevelBreadcrumb = [
        {
            title: intl.formatMessage({ defaultMessage: 'Новый счет' }),
            path: RoutesBusinessOffice.orderToPay,
        },
        {
            title: intl.formatMessage({ defaultMessage: 'Новый клиент' }),
            path: RoutesBusinessOffice.createClient,
        },
        {
            title: intl.formatMessage({ defaultMessage: 'Возврат по заказу' }),
            path: RoutesBusinessOffice.refundDetail,
        },
        {
            title: intl.formatMessage({ defaultMessage: 'Добавление интеграции' }),
            path: RoutesBusinessOffice.createIntegration,
        },
    ];

    const { isOpen: isOpenModal, open: openModal, close: onCloseModal } = useModal(false);

    const { merchantId } = useParams() as TShopRouteParams;
    const location = useLocation();
    const isLargeTablet = useLargeTablet();

    const merchant: IMerchant = useSelector(getMerchantById(merchantId)) as any;
    const counterpartyId = useSelector(getCurrentCounterpartyId);

    const isShowHeader = !hideHeaderRoutes.find((hideHeaderRoute) => location.pathname.includes(hideHeaderRoute));
    const thirdLevelBreadcrumb = routesThirdLevelBreadcrumb.find((route) => location.pathname.includes(route.path));

    const [isShowMerchantsList, setIsShowMerchantsList] = useState(false);

    const hideMerchantList = () => setIsShowMerchantsList(false);

    const merchantListWrapperRef = useOutsideClick(() => {
        setIsShowMerchantsList(false);
    });

    const onClickOpenMerchantsList = () => {
        if (isLargeTablet) {
            setIsShowMerchantsList(!isShowMerchantsList);
        }
    };

    const addingMerchantContextProviderValue = useMemo(
        () => ({
            closeModal: onCloseModal,
        }),
        [onCloseModal],
    );

    return (
        <>
            <Breadcrumb>
                <BreadcrumbToDashboard />
                <Breadcrumb.Item to={BUSINESS_OFFICE_PATHS.shop(counterpartyId).overview(merchantId)}>
                    {merchant?.name || <Skeleton width={80} />}
                </Breadcrumb.Item>
                {thirdLevelBreadcrumb && <Breadcrumb.Item>{thirdLevelBreadcrumb.title}</Breadcrumb.Item>}
            </Breadcrumb>
            {isShowHeader && (
                <>
                    <ShopHeaderWrapper ref={merchantListWrapperRef}>
                        <ShopHeader type="button" onClick={onClickOpenMerchantsList}>
                            <MerchantLogo merchantId={merchantId} />
                            <MerchantName variant="bigTitle">{merchant?.name || <Skeleton width={220} />}</MerchantName>
                            {isLargeTablet && (
                                <IconWrapper isActiveIcon={isShowMerchantsList}>
                                    <IconArrowImage />
                                </IconWrapper>
                            )}
                        </ShopHeader>
                        {isShowMerchantsList && (
                            <MerchantsList
                                openModal={openModal}
                                hideMerchantList={hideMerchantList}
                                merchant={merchant}
                            />
                        )}
                    </ShopHeaderWrapper>
                    <ClaimBanners />
                    <ShopNavigation merchantId={merchantId} />
                    <ModalMerchantCreate
                        title={intl.formatMessage({ defaultMessage: 'Добавить новый магазин' })}
                        isOpen={isOpenModal}
                        closeModal={onCloseModal}
                    >
                        <AddingMerchantContext.Provider value={addingMerchantContextProviderValue}>
                            <MerchantModalInner />
                        </AddingMerchantContext.Provider>
                    </ModalMerchantCreate>
                </>
            )}
        </>
    );
};

export default MerchantTemplate;
