import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import * as S from './styles';
import { DocumentSentSuccessfullyWidget } from '../../../../../stories/docs/widgets/DocumentSentSuccessfullyWidget';
import { OrderContainer } from '../../../../../store/OrderContainer/entity';
import { TTaskFlowCodeWithoutDownload } from '../../../../../store/TaskFlow/types';
import { useMobile } from '../../../../../hooks/useMedia';

type TProps = {
    goToDashboard: () => void;
    goToBusinessOffice: () => void;
    variant: TTaskFlowCodeWithoutDownload;
    orderContainers: Array<OrderContainer>;
};

const RouteSuccessReceiveDocument: FC<TProps> = ({ goToDashboard, goToBusinessOffice, variant, orderContainers }) => {
    const intl = useIntl();
    const isMobile = useMobile();

    return (
        <div>
            <DocumentSentSuccessfullyWidget variant={variant} orderContainers={orderContainers} />
            <S.ButtonsWrapper>
                <S.FormButton type="secondary" onClick={goToBusinessOffice}>
                    {isMobile
                        ? intl.formatMessage({
                              id: 'Личный кабинет',
                              defaultMessage: 'Личный кабинет',
                          })
                        : intl.formatMessage({
                              id: 'В личный кабинет',
                              defaultMessage: 'В личный кабинет',
                          })}
                </S.FormButton>
                <S.FormButton onClick={goToDashboard}>
                    {isMobile
                        ? intl.formatMessage({
                              id: 'Ко всем счетам',
                              defaultMessage: 'Ко всем счетам',
                          })
                        : intl.formatMessage({
                              id: 'Вернуться ко всем счетам',
                              defaultMessage: 'Вернуться ко всем счетам',
                          })}
                </S.FormButton>
            </S.ButtonsWrapper>
        </div>
    );
};

export default RouteSuccessReceiveDocument;
