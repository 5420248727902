import React, { useEffect } from 'react';
import { BusinessOfficeAuthRouter, BusinessOfficeNotAuthRouter } from 'src/pages/routes';
import { useSelector } from 'react-redux';
import { getUser } from 'src/store/Auth/selectors';
import { useAppDispatch } from 'src/store';
import { initializeDictionaries } from 'src/store/Dictionaries';
import { useParams } from 'react-router-dom';
import { AUTH_TYPE_FLOW, TAuthTypeFlow } from './constants';

const BusinessOfficeRoutes = () => {
    const dispatch = useAppDispatch();
    const { authTypeFlow } = useParams() as { authTypeFlow?: TAuthTypeFlow };

    const user = useSelector(getUser);
    const isNeedIntroduce =
        (!user?.firstName && !user?.lastName) ||
        authTypeFlow === AUTH_TYPE_FLOW.buyer ||
        authTypeFlow === AUTH_TYPE_FLOW.merchant;

    useEffect(() => {
        dispatch(initializeDictionaries());
    }, [dispatch]);

    if (user) {
        return <BusinessOfficeAuthRouter isNeedIntroduce={isNeedIntroduce} authTypeFlow={authTypeFlow} />;
    }

    return <BusinessOfficeNotAuthRouter />;
};

export default BusinessOfficeRoutes;
