import { RootState } from 'src/store';
import { counterpartyAdapter } from './index';

const { selectAll, selectById } = counterpartyAdapter.getSelectors();
export const getCounterparty = (state: RootState) => state.counterparty;
export const getAllCounterparties = (state: RootState) => selectAll(state.counterparty);
export const getIsCounterpartyInitialized = (state: RootState) => getCounterparty(state).initialized;
export const getCurrentCounterparty = (state: RootState) =>
    getAllCounterparties(state)?.find(({ id: counterpartyId }) => counterpartyId === getCurrentCounterpartyId(state));
export const getIsCounterpartyLoading = (state: RootState) => getCounterparty(state).loading;
export const getCurrentCounterpartyId = (state: RootState) => getCounterparty(state).selectedId;
export const getCounterpartiesIsPending = (state: RootState) =>
    !getIsCounterpartyInitialized(state) || getIsCounterpartyLoading(state) || !getAllCounterparties(state);

export const getCounterpartyIds = (state: RootState) => getCounterparty(state).ids;

export const getBankDetails = (state: RootState) => getCounterparty(state).bankDetails;

export const getBankDetailById = (id: number) => (state: RootState) =>
    getBankDetails(state)?.find((bank) => bank.id === id);

export const getBankDetailsCurrentCounterparty = (state: RootState) => {
    const bankDetails = getBankDetails(state);
    if (bankDetails) {
        return bankDetails.filter((bankDetail) => bankDetail.counterpartyId === getCurrentCounterpartyId(state));
    }
};
export const getCounterpartyById = (id: string) => (state: RootState) => selectById(state.counterparty, id);

export const getSelectedBankDetailId = (state: RootState) => getCounterparty(state).selectedBankDetailId;
