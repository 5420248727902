import dayjs from 'dayjs';
import { assert } from '../../utils/assert';
import { TTaskPlace } from './types';

export class TaskPlace {
    readonly id: number;
    readonly taskId: number;
    readonly placeCode: string;
    private readonly createdAt: string;
    private readonly updatedAt?: string;

    private constructor(state: TTaskPlace) {
        const { id, taskId, placeCode, updatedAt, createdAt } = state;

        this.id = id;
        this.taskId = taskId;
        this.placeCode = placeCode;
        this.updatedAt = updatedAt;
        this.createdAt = createdAt;
    }

    static fromResponse(response: unknown): TaskPlace {
        assert(typeof response === 'object' && response !== null);

        assert('id' in response);
        const { id } = response;
        assert(typeof id === 'number');
        assert(id >= 0);

        assert('taskId' in response);
        const { taskId } = response;
        assert(typeof taskId === 'number');
        assert(taskId >= 0);

        let placeCode = '';
        if ('placeCode' in response) {
            assert(typeof response.placeCode === 'string');
            placeCode = response.placeCode;
            assert(!!placeCode.length);
        }

        assert('createdAt' in response);
        const { createdAt } = response;
        assert(typeof createdAt === 'string');
        assert(!!createdAt.length);

        let updatedAt;
        if ('updatedAt' in response) {
            assert(typeof response.updatedAt === 'string');
            updatedAt = response.updatedAt;
            assert(!!updatedAt.length);
        }

        const checkedResponse = {
            id,
            taskId,
            placeCode,
            updatedAt,
            createdAt,
        } satisfies TTaskPlace;

        return new TaskPlace(checkedResponse);
    }

    static fromMock(): TaskPlace {
        return new TaskPlace({
            id: 19,
            taskId: 31,
            placeCode: 'created',
            createdAt: '2025-01-17T08:17:35+00:00',
            updatedAt: '2025-01-22T08:17:35+00:00',
        });
    }

    createdDateShowFormatted() {
        return dayjs(this.createdAt).format('D MMMM YYYY');
    }
}
